import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'

/**Actions */

/**Components */

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Paper, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

class HomePreguntasEspecialista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preguntaActive: 1,
        };
    }

    handleTogleActivePregunta = data => e => {
        e.preventDefault();
        if (this.state.preguntaActive === data ) {
            
            this.setState({ preguntaActive : ''})
        }else{
            this.setState({ preguntaActive : data})
        }
    }
    render() {
        const { preguntaActive } = this.state
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-preguntas' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' component='p' className='slogan txt-shadow fz-slogan slogan-movil'>
                            Preguntas Frecuentes de Especialistas
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-preguntas mt-5' elevation={0}>
                    <div className='cont-texto '>
                        <Typography variant='h4' color='secondary' component='p' className='     mb-2 txt-algn-right'>
                            Última actualización: 25 de julio 2020
                        </Typography>
                    </div>
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(1)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 1 ? 'active' : ''}`} fontSize='large'/>
                            ¿Quiénes somos?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 1 ? 'active' : ''}`}>
                            <b className='text-primary'>CARDIOLINE C.A</b> es una empresa de salud con oficina administrativa en Barquisimeto, Venezuela que ofrece una plataforma de telemedicina que logra fusionar conocimiento médico-científico y la tecnología para convertirla en una herramienta útil para contribuir en la prevención, diagnóstico, control y tratamiento de problemas de salud y enfermedades comunes para el bienestar de la sociedad, y así solventar problemas relacionados a la salud de los pacientes.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(2)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 2 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo surgió?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 2 ? 'active' : ''}`}>
                            Esta idea surge en noviembre del año 2017 para dar solución a una problemática surgida por la diáspora Venezolana, ya que muchos pacientes se han quedado sin sus médicos, acarreando descontrol en su problema de salud o enfermedad.
                            <br/><br/>
                            Por eso <b>Isaías Curiel</b> médico Venezolano especialista en Cardiología, junto con un equipo multidisciplinario extraordinario deciden unir el conocimiento médico-científico con las herramientas tecnologías actuales para ponerlas al servicio y bienestar de los pacientes Venezolanos alrededor del mundo y todo aquel que lo necesite.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(3)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 3 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuáles son mis beneficios?
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 3 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Ofrece a los pacientes una atención innovadora, rápida y desde cualquier lugar.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Expande tu rango de atención más allá del área presencial y llega a más pacientes.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Amplía oportunidades para recibir ingresos adicionales a tu actividad presencial.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Obtén tu Consultorio Virtual para proporcionar consultas e interconsultas online a través de respuestas escritas y videochat en vivo.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Programa tu horario de atención online según tu disponibilidad.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Recibe tu espacio web con tu perfil profesional.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  No tienes que preocuparte de la gestión administrativa de la cita y cobro a los pacientes.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Gestiona y almacena las historias médicas electrónicas de tus pacientes.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Expande tu red de contactos conociendo e interactuando con otros profesionales en la plataforma.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Contamos con un equipo que te proporcionará estrategias de marketing y soporte para iniciar tus consultas online.
                            </Typography>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(4)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 4 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuáles son los requisitos para formar parte del equipo?
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 4 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' className=''>
                                Tu registro y mantenimiento en la plataforma es gratuito, una vez aprobada tu solicitud de registro deberás completar los siguientes requerimientos para configurar y mantener tu cuenta:                          
                                <br/><br/>
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Completar tus datos generales y seleccionar tu especialidad.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Agregar tu horario de atención según tu disponibilidad actual y la modalidad para tus consultas online (respuestas escritas en menos de 24 horas y/o videochat en vivo).
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Incluir una foto de perfil profesional de frente (no selfie), con indumentaria de salud (bata o uniforme) y de buena calidad, usando un fondo unicolor preferiblemente blanco.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Añadir a tu perfil profesional tu experiencia laboral, educación y membresías.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Estar disponible según lo que hayas indicado en tu horario de atención y modalidad de consultas online.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Mantener actividad regular en tu cuenta; al menos una publicación mensual (texto, imagen, vídeo o ambas), cumplir con las consultas online agendadas en la fecha y hora pautadas.
                            </Typography>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(5)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 5 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo funciona la consulta médica online a través de videochat en vivo?
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 5 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' className=''>
                                Para responder esta consulta debes ingresar en tu cuenta y hacer clic en el botón “Consultorio virtual” luego para ver los datos del paciente y documentos adjuntos haz clic en el botón “Ver más detalles” ya conocido los detalles de la cita haz clic en el botón “Iniciar consulta”, recuerda hacerlo en la fecha y hora indicada. Una vez consideres resuelto el motivo de consulta debes hacer clic en el botón “Cita atendida”. Para poder escribir las conclusiones, recomendaciones y/o tratamientos según el caso haz clic en el botón “Hacer historia”, recuerda que estos datos estarán disponibles para el paciente.
                                <br /><br />
                                Esta consulta se realiza a través de la tecnología interna de <Link to='/'><b className='text-primary'>ecardioline</b>.com</Link>
                            </Typography>
                            
                        </div>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(6)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 6 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo funciona la consulta médica online a través de respuesta escrita en menos de 24 horas?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 6 ? 'active' : ''}`}>
                            Para responder esta consulta debes ingresar en tu cuenta y hacer clic en la sección de la barra lateral izquierda en “Consulta vía texto”, ahí encontrarás el motivo de consulta de pacientes y podrás responderla. Una vez consideres resuelto el motivo de consulta debes hacer clic en el botón “Cita atendida”.
                            <br /><br />
                            Esta consulta se realiza a través de la tecnología interna de <Link to='/'><b className='text-primary'>ecardioline</b>.com</Link>
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(7)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 7 ? 'active' : ''}`} fontSize='large' />
                            ¿Los pacientes pueden enviar documentos y paraclínicos en el momento de la consulta online?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 7 ? 'active' : ''}`}>
                            No. Los pacientes solo tiene la opción de adjuntar documentos y paraclínicos en el momento de agendar la cita.
                            <br /><br />
                            Estaremos trabajando en el desarrollo de esa funcionalidad.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(8)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 8 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo funciona la Historia Clínica Electrónica?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 8 ? 'active' : ''}`}>
                            Luego de concluida la consulta médica online y hacer clic en el botón “Cita atendida”, aparecer la opción para Historia Clínica donde podrás escribir las conclusiones, recomendaciones y tratamientos, la cual estará disponible para el paciente.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(9)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 9 ? 'active' : ''}`} fontSize='large' />
                            ¿Se pueden emitir récipes a través de la plataforma?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 9 ? 'active' : ''}`}>
                            En la Historia Clínica Electrónica puedes incluir las recomendaciones y tratamientos. Actualmente no contamos con la tecnología para que puedas emitir récipes, por lo tanto no es posible la prescripción de un fármaco que sea de venta obligatoria con récipe, como por ejemplo; antibióticos y ansiolíticos.
                            <br /><br />
                            Estamos trabajando en el desarrollo de estar tecnología, pronto estará disponible y te lo haremos saber.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(10)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 10 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo se hace en el caso de diferencias horarias entre el paciente y yo?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 10 ? 'active' : ''}`}>
                            Cuando el paciente solicita una cita hay una nota informando que tome en cuenta si existe una diferencia horaria con tu lugar de residencia. Además se recuerda en el correo de detalles de la cita y recordatorio.
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(11)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 11 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo saber que tengo una cita agendada?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 11 ? 'active' : ''}`}>
                            Cuando un paciente te haya solicitado una consulta online y sea aprobada por la administración te llegara un correo con los detalles de la cita (nombre del paciente, modalidad de la consulta, fecha y hora). El correo llegará con al menos 8 horas de anticipación para que puedas programarte.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(12)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 12 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo puedo ver los paraclínicos adjuntos para mi consulta online
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 12 ? 'active' : ''}`}>
                            Para ver los detalles de la consulta y documentos adjuntos debe ingresar en su cuenta en la sección "Citas" y en el calendario buscar el día establecido de la cita y hacer clic ver más. Es recomendable revisarlo con anticipación a la consulta online.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(13)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 13 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuál es el costo de consultas online para los pacientes?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 13 ? 'active' : ''}`}>
                            El baremo actual establecido por las consultas a través de videochat en vivo es de 15-20 USD/EUR y vía texto en menos de 24 horas es de 10 – 15 USD/EUR.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(14)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 14 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuánto es mi honorario profesional?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 14 ? 'active' : ''}`}>
                            Recibirás un porcentaje mayoritario del costo de las consultas online que realices, se te hará saber por la administración.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(15)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 15 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo pagan los pacientes y recibo mi honorario profesional?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 15 ? 'active' : ''}`}>
                            La forma de pago de los pacientes es a través de PayPal, Tarjeta de Débito/Crédito internacional y Zelle, por lo tanto tu honorario profesional dependerá de que tengas una cuenta PayPal o Zelle disponible.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(16)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 16 ? 'active' : ''}`} fontSize='large' />
                            ¿En cuánto tiempo recibo mi honorario profesional?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 16 ? 'active' : ''}`}>
                            Recibirás tu honorario profesional en las siguientes 48 horas de culminada la consulta online, generalmente es antes de ese tiempo.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(17)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 17 ? 'active' : ''}`} fontSize='large' />
                            ¿Qué puedo publicar y en qué formatos?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 17 ? 'active' : ''}`}>
                            Puedes publicar algún contenido de salud de interés en tu área, recomendaciones y tips de salud, experiencias y conocimientos sobre un área, fotos en tu lugar de trabajo y todo aquello que consideres pueda ser de utilidad para pacientes y otros especialistas de la plataforma.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(18)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 18 ? 'active' : ''}`} fontSize='large' />
                            ¿En qué consiste la función Seguir y Contactos?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 18 ? 'active' : ''}`}>
                            Los pacientes y otros especialistas pueden <b>Seguirte</b> para ver tus publicaciones, darle me gusta y compartirla en otros redes.
                            La función de Contactos es solo entre especialistas de la plataforma, al aceptar una Solicitud de contacto puedes enviar mensajes directos y grupales entre tus contactos.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(19)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 19 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo unirme al equipo?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 19 ? 'active' : ''}`}>
                            Para hacer tu solicitud de registro debes hacerlo haciendo clic en el siguiente enlace <Link to='/registro-especialista'><b className='text-primary'>Registo de especialista</b></Link>, debes escribir tu nombre, correo y adjuntar tu síntesis curricular en formato pdf, en las siguientes 48 horas recibirás respuesta a tu solicitud.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(20)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 20 ? 'active' : ''}`} fontSize='large' />
                            Contacto
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 20 ? 'active' : ''}`}>
                            Si tienes alguna pregunta adicional o duda puedes escribirnos a <b className='text-primary'>contacto@ecardioline.com</b> y con gusto te responderemos en el menor tiempo posible.
                        </Typography>
                    </div>

                </Paper>

            </section>
        );
    }
}

HomePreguntasEspecialista.propTypes = {
    classes: PropTypes.object.isRequired,
};


const connectedHomePreguntasEspecialista = (withStyles(styles)(HomePreguntasEspecialista));
export { connectedHomePreguntasEspecialista as HomePreguntasEspecialista }; 