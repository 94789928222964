import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
import { BannerBuscador } from "./banerBuscador";
import ListEspecialistas from "./listEspecialistas";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from '@material-ui/core';


class HomeEspecialistas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: this.props.match.path,
        };
        this.refBuscador = React.createRef();
        this.props.dispatch(generalPublicActions.getEspecialistas('team'));
        !this.props.especialidades.length &&
            this.props.dispatch(generalPublicActions.getEspecialidades());

    }

    render() {
        const { path } = this.state
        const { especialistasHome, preloaderEspecialistasHome } = this.props
        return (
            <section className='container-public' >

                <BannerBuscador
                    path={path}
                    refBuscador={this.refBuscador}
                />

                <Paper className='max-width-container mt-2 especialistas' elevation={0}>
                    <Grid item xs={12} align='center'>
                        <Typography variant='h4' color='primary' component='p' className='mb-2'>Conoce algunos de nuestros especialistas</Typography>
                    </Grid>

                    <ListEspecialistas
                        list={especialistasHome}
                        refBuscador={this.refBuscador}
                        loading={preloaderEspecialistasHome}
                    />
                </Paper>

            </section>
        );
    }
}

HomeEspecialistas.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome, preloaderEspecialistasHome, especialidades } = state.generalPublicReducer;
    return {
        especialistasHome, preloaderEspecialistasHome, especialidades
    };
}

const connectedHomeEspecialistas = connect(mapStateToProps)(withStyles(styles)(HomeEspecialistas));
export { connectedHomeEspecialistas as HomeEspecialistas }; 