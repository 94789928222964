import React from 'react'

/* Components */
// import MenuSeguidorContacto from "./menuSeguidorContacto";

/* MAterial UI */
import ImageIcon from '@material-ui/icons/Image';
import PuntoIcon from '@material-ui/icons/FiberManualRecord';
import { Grid, Paper, Divider, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button} from '@material-ui/core';

const ListInfoMed = (props) => (
    <Grid item xs={12}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color='primary' component="span" className="mb-06">{props.title}</Typography>
                <Divider />
            </div>
            <div>
                <List className=''>
                    {props.list
                        ? props.list.map(k => {
                            return(
                                <div key={k.key}>
                                    <ListItem alignItems="flex-start" className={props.classes.p_L_R_0}>
                                        {k.img_instituto &&
                                            <ListItemAvatar className={props.classes.avatarInfoMed}>
                                                <Avatar>
                                                    <ImageIcon />
                                                </Avatar>
                                                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                                            </ListItemAvatar>
                                        }
                                        <ListItemText
                                            className={`${k.img_instituto ? props.classes.listCont : ''}`}
                                            color="primary"
                                            primary={
                                                <React.Fragment >
                                                    <div className="relative">
                                                        <Typography component="p" className={props.classes.tituloItem}>
                                                            {k.cargo && k.cargo}
                                                            {k.universidad && k.universidad}
                                                            {k.sociedad && k.sociedad}
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Typography component="span" className={props.classes.tituloItemDos}>
                                                        {k.instituto && k.instituto}
                                                        {k.titulo && k.titulo}
                                                    </Typography>
                                                    {k.diploma &&
                                                        <Typography component="span" className={props.classes.tituloItemTres}>
                                                            {k.diploma}
                                                        </Typography>
                                                    }
                                                    {k.fech_desde &&
                                                        <Typography component="span" className={props.classes.tituloItemTres}>
                                                            {k.fech_desde} - {k.fech_hasta} <PuntoIcon className={props.classes.icon} /> {k.fech_trascurrido}
                                                        </Typography>
                                                    }
                                                    {k.inicio &&
                                                        <Typography component="span" className={props.classes.tituloItemTres}>
                                                            {k.inicio} - {k.fin}
                                                        </Typography>
                                                    }
                                                    {k.fech &&
                                                        <Typography component="span" className={props.classes.tituloItemTres}>
                                                            {k.fech}
                                                        </Typography>
                                                    }
                                                    {k.fecha_ingreso &&
                                                        <Typography component="span" className={props.classes.tituloItemTres}>
                                                            {k.fecha_ingreso}
                                                        </Typography>
                                                    }

                                                    {k.descripcion && 
                                                        <Typography
                                                            component="span"
                                                            dangerouslySetInnerHTML={{ __html:
                                                                k.descripcion.length > 100 
                                                                    ? props.verMas !== props.cdn + '' + k.key 
                                                                        ? k.descripcion.slice(0, 100)+'...' 
                                                                        : k.descripcion
                                                                    : k.descripcion
                                                            }}
                                                        >
                                                        </Typography>
                                                    }

                                                    {props.verMas === 'NO' ? '' :
                                                        k.descripcion.length > 100 
                                                            ? props.verMas !== props.cdn + '' + k.key
                                                                ? <span className={props.classes.verMas} onClick={props.handleTogleVerMas(props.cdn + '' + k.key)}>Ver más</span>
                                                                : <span className={props.classes.verMas} onClick={props.handleTogleVerMas(props.cdn + '' + k.key)}>Ver menos</span>
                                                            : null
                                                    }

                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </div>
                            )
                        })
                        : 'Sin datos'
                    }
                </List>
            </div>
            {props.listLongitud >= 3 &&
                <Button color="primary" className={props.classes.btnTransform} onClick={props.handleTogleVermasItem(props.cdn)}>
                    {props.condicionItem !== props.cdn
                        ? `Ver más ${props.btnVerMas}`
                        : `Ocultar ${props.btnVerMas}`
                    }
                </Button>
            }
        </Paper>
    </Grid>
)


export default ListInfoMed;