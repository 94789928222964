import { apiUrl } from '../_constants';
import { responseHelper } from "../_helpers";

export const medicoService = {
    getEpage,
};

function getEpage(nickname) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };

    return fetch(`${apiUrl.urlEPage}getEpege?tokenEpege=${nickname}`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

