// import { alertActions } from './';
import { medicoService } from '../_services';
import { medicoConstants } from '../_constants';


export const medicoActions = {
    getEpage,
    togleViewEpage,
};

function getEpage(nickname) {
    return dispatch => {
        dispatch(request({ reducer: 'preloader', status: true }));
        medicoService.getEpage(nickname)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                    } else {
                        dispatch(failure({ reducer: 'preloader', status: false }));
                    }
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure({ reducer: 'preloader', status: false }));
                }
            )
    }
    function request(data) { return { type: medicoConstants.REQUEST_GET_EPAGE, data } }
    function failure(data) { return { type: medicoConstants.REQUEST_GET_EPAGE, data } }
    function success(data) { return { type: medicoConstants.SUCCESS_GET_EPAGE, data } }
}

function togleViewEpage(data) {
    return { type: medicoConstants.TOGLE_UPDATE_VIEW_EPAGE, data }
}