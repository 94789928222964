export const Bread = {
    dashboard: [
        {
            "id": 1,
            "name": "Inicio",
            "ruta": "/",
            "active": true,
        },
    ],
    // PERFIL
    perfil: [
        {
            "id": 1,
            "name": "Perfil",
            "ruta": "/perfil",
            "active": true,
        },
    ],
    
    // ADMINS
    listAdmin: [
        {
            "id": 1,
            "name": "Admins",
            "ruta": "/admins",
            "active": true,
        },
    ],
    NewAdmin: [
        {
            "id": 1,
            "name": "Admins",
            "ruta": "/",
            "active": false,
        },
        {
            "id": 2,
            "name": "Crear admin",
            "ruta": "/crear-admin",
            "active": true,
        },
    ],

    // RESTAURANTES
    listRestaurantes: [
        {
            "id": 1,
            "name": "Restaurantes",
            "ruta": "/restaurantes",
            "active": true,
        },
    ],
    // PLANES
    listPlanes: [
        {
            "id": 1,
            "name": "Planes",
            "ruta": "/planes",
            "active": true,
        },
    ],
    //SOLICITUDES DE PAGO
    listSolicitudes: [
        {
            "id": 1,
            "name": "Solicitudes de pago",
            "ruta": "/solicitudes",
            "active": true,
        },
    ],
}