import { authConstants } from '../_constants';

let paciente = JSON.parse(localStorage.getItem('paciente'));
const initialState = paciente 
    ? { 
        paciente,
        edicion: false, 
        loggedIn: true, 
        progresUpload: 0,
        loadingLogin: false, 
        submittedLogin: false 
    } 
    : { 
        email: '', 
        message: '',
        password: '', 
        repassword:'', 
        edicion: false, 
        loadingLogin: false, 
        submittedLogin: false,
    };

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case authConstants.CLEAR_STATE:
            return Object.assign({}, state, {
                paciente: {},
                email: '', 
                password: '',
                repassword: '',
                loadingLogin: false,
                submittedLogin: false,
                message: '',
            });
        case authConstants.SUBMIT_REQUEST:
            return Object.assign({}, state, {
                submittedLogin: action.data,
            });
        case authConstants.CHANGE_IMPUT_LOGIN:
            return Object.assign({}, state, {
                [action.data.name]: action.data.value,
            });
        case authConstants.LOGIN_REQUEST:
            return Object.assign({}, state, {
                //paciente: action.paciente,
                loadingLogin: !state.loadding,
            });
        case authConstants.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: true,
                paciente: action.paciente,
                loadingLogin: false,
                submittedLogin: false,
                password: '',
                repassword: '', 
            });
        case authConstants.LOGIN_FAILURE:
            return Object.assign({}, state, {
                paciente: '',
                loggedIn: false,
                loadingLogin: false,
                submittedLogin: false,
            });
        case authConstants.SUCCES_SED_RECOVERY_PASS:
            return Object.assign({}, state, {
                message: action.data,
                loadingLogin: false,
                submittedLogin: false,
            });
        case authConstants.SUCCES_UPDATE_PASS:
            return Object.assign({}, state, {
                loadingLogin: false,
                submittedLogin: false,
            });
        //Perfil
        case authConstants.CHANGE_INPUT_PERFIL:
            return Object.assign({}, state, {
                paciente:{
                    ...state.paciente,
                    [action.data.name] : action.data.value
                }
            });
        case authConstants.SUCCESS_CHANGE_AVATAR:
            return Object.assign({}, state, {
                paciente:{
                    ...state.paciente,
                    logo : action.data
                },
                loadingLogin: false,
            });
        case authConstants.SUCCESS_PERFIL_EDIT:
            return Object.assign({}, state, {
                loadingLogin: false,
                submittedLogin: false,
            });
        case authConstants.PROGRESO_DE_SUBIDA_IMG:
            return Object.assign({}, state, {
                progresUpload: action.porcentaje,
            });
        case authConstants.HABILITAR_EDICION_INFO_BASICA:
            return Object.assign({}, state, {
                edicion: action.data,
            });
        
        // case authConstants.LOGOUT:
        //     return {};
        // case authConstants.RECUPERACION_REQUEST:
        //     return Object.assign({}, state, {
        //         submit: !state.submit,
        //         loadding: !state.loadding,
        //     });
        default:
            return state
    }
}