export const UrlIcon = {
    icon_women      : 'https://ecardioline.com/assets_media/iconos/icon_women.png',
    icon_virus      : 'https://ecardioline.com/assets_media/iconos/icon_virus.png',
    icon_medico     : 'https://ecardioline.com/assets_media/iconos/icon_medico.png',
    icon_tiempo     : 'https://ecardioline.com/assets_media/iconos/icon_tiempo.png',
    icon_atencion   : 'https://ecardioline.com/assets_media/iconos/icon_atencion.png',
    icon_videoChat  : 'https://ecardioline.com/assets_media/iconos/videollamada.svg',
    icon_prevencion  : 'https://ecardioline.com/assets_media/iconos/icon_prevencion.png',
    icon_registrate  : 'https://ecardioline.com/assets_media/iconos/icon_registrate.png',
    icon_orientacion  : 'https://ecardioline.com/assets_media/iconos/icon_orientacion.png',
    icon_solicita_cita  : 'https://ecardioline.com/assets_media/iconos/icon_solicita_cita.png',
    icon_motivo_consulta  : 'https://ecardioline.com/assets_media/iconos/icon_motivo_consulta.png',
    icon_segunda_opinion  : 'https://ecardioline.com/assets_media/iconos/icon_segunda_opinion.png',
    icon_confirma_solicitud  : 'https://ecardioline.com/assets_media/iconos/icon_confirma_solicitud.png',
    icon_selecciona_modalidad  : 'https://ecardioline.com/assets_media/iconos/icon_selecciona_modalidad.png',
    icon_selecciona_especialidad  : 'https://ecardioline.com/assets_media/iconos/icon_selecciona_especialidad.png',

    icon_interactuar  : 'https://ecardioline.com/assets_media/iconos/icon_interactuar.png',
    icon_consultorio  : 'https://ecardioline.com/assets_media/iconos/icon_consultorio.png',
    icon_new_perfil  : 'https://ecardioline.com/assets_media/iconos/icon_new_perfil.png',
    icon_compartir  : 'https://ecardioline.com/assets_media/iconos/icon_compartir.png',
    icon_prgramar  : 'https://ecardioline.com/assets_media/iconos/icon_prgramar.png',

};
