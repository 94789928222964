import React from "react";
import { Link } from 'react-router-dom'

/**Helpers */
import { menu } from "../../../../_helpers";

/**Styles And Logo-Icon */
import { List, ListItem, ListItemText } from '@material-ui/core';


const MenuList = (props) => (
    <div className={`list-menu ${props.classes.bgMenu}`}>
        <List>  
        {
            menu.public.map(k => {
                return (
                    <Link to={k.ruta} key={k.id} onClick={props.handlSelectMenu(k.ruta)}>
                        <ListItem button className={`${props.classes.itemList} ${props.menuActivo === k.ruta ? props.classes.menuActive : ''}`}>
                            <ListItemText primary={k.name} />
                        </ListItem>
                    </Link>
                )
            })
        }
        </List>

        {/* <Grid container className='cont-item-menu-desktop' spacing={1} direction="row"  justify="flex-end"  alignItems="flex-start" >
            {menu.public.map(k => {
                return (
                    <Grid item key={k.id} className={`${props.menuActivo === k.ruta ? 'active-item' : ''}`}>
                        <Link to={k.ruta}>
                            <Button color="inherit">{k.name}</Button>
                        </Link>
                    </Grid>
                )
            })}
        </Grid> */}
    </div>
)

export default MenuList