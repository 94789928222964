import React from "react";

/**Constant */
import { UrlIcon } from '../../../../../_constants';

import { Avatar, Grid, Typography } from '@material-ui/core';

const InfoLanding = () => (
    <Grid item xs={12} align='center' className="mt-2 mb-2">
        <Typography variant='h4' color='secondary' component='p' className='mb-2'>Al ser parte de nuestro equipo de especialistas podrás:</Typography>

        <Grid container spacing={2}>
            <Grid item xs={6} sm={4} align='center' >
                <Avatar className='icon-landing' src={UrlIcon.icon_consultorio} alt="Consultorio" />
                <Typography variant='h6' color='secondary' component='p' className=''>
                    Obtener un consultorio virtual para ofrecer una atención innovadora, rápida y desde cualquier lugar.
                </Typography>
            </Grid>
            <Grid item xs={6} sm={4} align='center' >
                <Avatar className='icon-landing' src={UrlIcon.icon_prgramar} alt="Programar horario" />
                <Typography variant='h6' color='secondary' component='p' className=''>
                    Programar el horario de atención al paciente según tu disponibilidad.
                </Typography>
            </Grid>
            <Grid item xs={6} sm={4} align='center' >
                <Avatar className='icon-landing' src={UrlIcon.icon_new_perfil} alt="Crear perfil" />
                <Typography variant='h6' color='secondary' component='p' className=''>
                    Crear un perfil para proveer datos profesionales y relevantes para tus pacientes.
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} align='center' >
                <Avatar className='icon-landing' src={UrlIcon.icon_compartir} alt="Compartir publicaciones" />
                <Typography variant='h6' color='secondary' component='p' className=''>
                    Compartir publicaciones de artículos médicos con los pacientes por medio de tu perfil.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} align='center' >
                <Avatar className='icon-landing' src={UrlIcon.icon_interactuar} alt="Expandir red" />
                <Typography variant='h6' color='secondary' component='p' className=''>
                    Expandir tu red de contactos al conocer e interactuar con otros profesionales de la plataforma.
                </Typography>
            </Grid>
        </Grid>
        
    </Grid>
)

export default InfoLanding