import React from "react";
import { Link } from 'react-router-dom'

/**Constants */
import { apiUrl } from "../../../../_constants";

/**Components */
import ProgressCircular from "../../layout/progress/progressCircular";


import { Grid, Avatar, Typography, Divider } from '@material-ui/core';

const handleUrlify = (texto) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return texto.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_back">' + url + '</a>';
    })
}

const ListPublicaciones = (props) => (
    <Grid className='cont-item-publicacion mt-2 mb-2' container spacing={2}>
        {props.loading
            ? <ProgressCircular />
            : props.list.map(k => {
                return (
                    <Grid key={k.keyd} item xs={12} sm={6} className='card-item'>
                        <div className='card-header'>
                            <div className='portada'>

                                <Avatar alt="Registrate" className='img-portada' src={`${apiUrl.urlMedia}${k.autor.portada}`} />
                            </div>
                            <Avatar alt="Registrate" className='img-autor' src={`${apiUrl.urlMedia}${k.autor.avatar}`} />
                        </div>
                        <div className='card-body'>
                            <Typography component='p' color='primary' className='mb-2'>{k.fecha}</Typography>
                            <Typography component='p' color='secondary' dangerouslySetInnerHTML={{ __html: handleUrlify(k.description) }}></Typography>
                        </div>
                        <Grid container spacing={3} className='card-footer'>
                            <Grid item xs={4}>
                                <Link to={`/post/${k.autor.nick}/${k.post}`} className='leer-mas'>
                                    Leer más
                                </Link>
                            </Grid>
                            <Grid item xs={8} align='right'>
                                <Typography component='p' className='nombre-especialista' color='primary'>{k.autor.name} {k.autor.surname}</Typography>
                            </Grid>
                        </Grid>
                        <Divider className='separador' />
                    </Grid>

                )
            })
        }
    </Grid>
)

export default ListPublicaciones