import React from "react";
import { Link as Ruta } from 'react-router-dom'

/**Styles And Logo-Icon */
import { Grid, Typography, Link } from '@material-ui/core';



const HomeFooterPublic = () => (
    <Grid container className='footer-public'>
        <Grid item xs={12} sm={8} md={9} className='mb-2'>
            <div className='cont-icon-redes'>
                <Link href='https://www.instagram.com/ecardioline/' target='back'><i className='icon-redes icon-instagram' /></Link>
                <Link href='https://www.facebook.com/ecardioline/' target='back'><i className='icon-redes icon-facebook' /> </Link>
                <Link href='https://www.linkedin.com/company/ecardioline/' target='back'><i className='icon-redes icon-linkedin' /> </Link>
                <Link href='https://twitter.com/ecardioline' target='back'><i className='icon-redes icon-twitter' /> </Link>
                <Link href='https://www.youtube.com/channel/UCSfXmVbm0CVMY32WmunT0yg/videos' target='back'><i className='icon-redes icon-youtube' /></Link>
            </div>
            <Typography variant='h4' color='secondary' component='p' className='mb-2'>Síguenos</Typography>
            <Typography color='secondary' component='p'>Publicaciones en los medios:</Typography>
            <Typography color='primary' component='p' className='mb-2'>
                <Link href='https://noticiasfalcon.wordpress.com/2019/06/10/profesionales-venezolanos-lanzan-innovadora-plataforma-de-telemedicina/' target='back'><b>@noticiasfalcon</b></Link>
            </Typography>
            <Typography color='secondary' component='p'>Copyright © 2021 CARDIOLINE C.A. Todos los derechos reservados.</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className='mb-2'>
            <Grid container className='enlaces mb-2' spacing={2}>
                <Grid item xs={12}>
                    <Ruta to='/contactanos'><Typography color='primary'>Contáctanos</Typography></Ruta>
                </Grid>
                <Grid item xs={12}>
                    <Ruta to='/conocenos'><Typography color='primary'>Conócenos</Typography></Ruta>
                </Grid>
                <Grid item xs={12}>
                    <Ruta to='/servicios'><Typography color='primary'>Servicios</Typography></Ruta>
                </Grid>
            </Grid>

            <Typography color='secondary' component='p' className='num-contacto'>
                {/* <Link target='back' href={`https://api.whatsapp.com/send?phone=+584164507232&text=Hola Estoy me gustaría una asesoría`}>
                    <i className='icon-ws icon-whatsapp' /> <b>+58 4164507232</b>
                </Link> */}
                
            </Typography>
            <Typography color='secondary' component='p' className='mt-2'>contacto@ecardioline.com</Typography>
        </Grid>
    </Grid>
)

export default HomeFooterPublic