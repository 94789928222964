import React from 'react'
import { Link } from 'react-router-dom'

/* Components */
// import MenuSeguidorContacto from "./menuSeguidorContacto";

/* MAterial UI */
import { Grid, Paper, Avatar, Typography } from '@material-ui/core';

const PostLayout = (props) => (
    <Grid item xs={12} className="cont-list-post">
        <Paper className="paper post">
            <div className="header-post">
                <Link to={'/epage/' + props.post.autor.nick} className="link-epage">
                    <Avatar alt={props.post.autor.name} src={`${props.post.autor.avatar}`} className='bigAvatar' />
                    <div className="autor-fech">
                        <Typography component="span" color="primary">{props.post.autor.name} {props.post.autor.surname}</Typography>
                        <Typography component="span" color="textSecondary">{props.post.fecha}</Typography>
                    </div>
                </Link>
            </div>
            <div className="body-post">
                {props.post.description &&
                    <div className="cont-text mb-06"><Typography component="span" color="textSecondary" dangerouslySetInnerHTML={{ __html: props.handleUrlify(props.post.description) }}></Typography></div>
                }
                {props.post.files ?
                    props.post.typeFile === 'video'
                        ? <div className="cont-video-post">
                            <video src={`${props.post.files}`} loop controls ></video>
                        </div>
                        : <div className="cont-img-post">
                            <img alt={props.post.fecha} src={`${props.post.files}`} style={{ maxWidth: props.anchoImg }} />
                        </div>
                    : null
                }
            </div>
        </Paper>
    </Grid>
)


export default PostLayout;