import React, { Component } from "react";
// import { Link } from 'react-router-dom'

import { history } from '../../../../_helpers';

import './error404.scss';
import { Button } from "@material-ui/core";

export default class Error404 extends Component {
    handleBack = e => {
        e.preventDefault();
        history.push(history.goBack());
    }
    render() {
        const user = JSON.parse(localStorage.getItem('paciente')) ? true  : false
        return (
            <div className={`bodyError ${user ? 'z-indez-01' : ''}`}>
                <div className={`c ${user && 'c-user'}`}>
                    <div className='_404'>404</div>
                    <hr /><br />
                    <div className='_1'>LA PÁGINA</div>
                    <br />
                    <div className='_2'>NO FUE ENCONTRADA</div>
                    <br />
                    {!user &&
                        <Button className="btn" onClick={this.handleBack}> Volver </Button>
                    }
                        {/* <Link to={history.goBack()} ></Link> */}
                </div>
            </div>
        )
    }
}