export const FormasDePago = [
    {
        id: 1,
        nombre: 'visa',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/visa.jpg',
    },
    {
        id: 2,
        nombre: 'paypal',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/paypal.jpg',
    },
    {
        id: 3,
        nombre: 'zella',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/zelle.jpg',
    },
    {
        id: 4,
        nombre: 'mastercard',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/mastercard.jpg',
    },
    {
        id: 5,
        nombre: 'trasferencias',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/bank.jpg',
    },
    {
        id: 6,
        nombre: 'stripe',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/stripe.jpg',
    },
    {
        id: 7,
        nombre: 'America express',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/america_express.jpg',
    },
    {
        id: 8,
        nombre: 'Bizum',
        ruta_img: 'https://ecardioline.com/assets_media/bancos/bizum.jpg',
    },
];


export const EspecialidadesConstans = [
    {
        id: 1,
        nombre: 'Cardiología Clínica Adulto ',
    },
    {
        id: 2,
        nombre: '- Cardiología Clínica Infantil ',
    },
    {
        id: 3,
        nombre: '- Medicina Interna ',
    },
    {
        id: 4,
        nombre: '- Pediatría ',
    },
    {
        id: 5,
        nombre: '- Dermatología ',
    },
    {
        id: 6,
        nombre: '- Psicología ',
    },
    {
        id: 7,
        nombre: '- Psiquiatría ',
    },
    {
        id: 8,
        nombre: '- Ginecología ',
    },
    {
        id: 9,
        nombre: '- Medicina Familiar ',
    },
    {
        id: 10,
        nombre: '- Nutrición y Dietética ',
    },
    {
        id: 11,
        nombre: '- Alergología ',
    },
    {
        id: 12,
        nombre: '- Gastroenterología y más ',
    },
];