import React from 'react'

/* Components */
// import MenuSeguidorContacto from "./menuSeguidorContacto";

/* MAterial UI */
import { Grid, Typography, Avatar, Button, Link as Enlace } from '@material-ui/core';

const BarActionDesktop = (props) => (
    <div className="max-width-container cont-bar-action">
        <Grid container>
            <Grid item xs={3} className=" br-right">
                <div className="img-and-btn-follow">
                    <div className="cont-img-perfil">
                        <Avatar alt="Especialista" src={`${props.foto_perfil}`} className='foto-perfil' />
                    </div>
                </div>
            </Grid>
            <Grid item xs={9} className="">
                <div className="cont-bloques">
                    <div className='bloque cursor-pointer br-right' onClick={props.handleUpdateViewEpage(1)}>
                        <Typography conponent="span" color="primary">Publicaciones</Typography>
                        <Typography conponent="span" color="primary" className="fw-bold sifras">{props.post}</Typography>
                    </div>
                    <div className='bloque cursor-pointer br-right' onClick={props.handleUpdateViewEpage(2)}>
                        <Typography conponent="span" color="primary">Seguidores</Typography>
                        <Typography conponent="span" color="primary" className="fw-bold sifras">{props.seguidores}</Typography>
                    </div>
                    <div className='bloque cursor-pointer br-right' onClick={props.handleUpdateViewEpage(3)}>
                        <Typography conponent="span" color="primary">Siguiendo</Typography>
                        <Typography conponent="span" color="primary" className="fw-bold sifras">{props.siguiendo}</Typography>
                    </div>
                    <div className='bloque cursor-pointer br-right' onClick={props.handleUpdateViewEpage(4)}>
                        <Typography conponent="span" color="primary">Recomendaciones</Typography>
                        <Typography conponent="span" color="primary" className="fw-bold sifras">{props.recomendaciones}</Typography>
                    </div>
                    <div className='bloque cursor-pointer'>
                        <Enlace href={`https://portalpaciente.ecardioline.com/solicitar-consulta/${props.nick}`}>
                            <Button className='btn-with-br font-sz-09' color='primary'>Solicitar consulta</Button>
                        </Enlace>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
)


export default BarActionDesktop;