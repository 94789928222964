import React, { Component } from 'react';
import PropTypes from "prop-types";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Link as LinkUrl } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

class ConfigurarCuentaEspecialista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preguntaActive: 1,
        };
    }

    handleTogleActivePregunta = data => e => {
        e.preventDefault();
        if (this.state.preguntaActive === data ) {
            
            this.setState({ preguntaActive : ''})
        }else{
            this.setState({ preguntaActive : data})
        }
    }
    render() {
        const { preguntaActive } = this.state
        return (
            <section className='container-public' >
                <Paper className='baners-page baner-blog' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan'>
                            ¿Cómo configurar mi cuenta de Especialista en <b className='text-primary'>ecardioline</b>.com?
                        </Typography>
                        <Typography variant='h4' className='slogan _two'>
                            Fecha de última actualización; 09 de mayo 2020
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-preguntas' elevation={0}>
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(1)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 1 ? 'active' : ''}`} fontSize='large'/>
                            Inicio de sesión
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 1 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Lo primero que debes hacer es ingresar a tu cuenta a través de este enlace <LinkUrl href='https://portalmedico.ecardioline.com/'><b>www.portalmedico.ecardioline.com/login</b></LinkUrl> y luego escribiendo tu dirección de correo electrónico usada en tu registro y la contraseña enviada en el correo de aprobación.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Inicio de sesión' src='https://ecardioline.com/assets_media/configuracion/1.png' />
                            </div>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(2)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 2 ? 'active' : ''}`} fontSize='large' />
                            Configuración
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 2 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Luego de ingresar a tu cuenta encontrarás los siguientes accesos rápidos para configurar tu cuenta.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración 1' src='https://ecardioline.com/assets_media/configuracion/2.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                También puedes configurar tu cuenta usando las opciones disponibles haciendo clic en el círculo con la inicial de tu nombre de la esquina superior derecha
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración 2' src='https://ecardioline.com/assets_media/configuracion/3.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Luego se desplegarán las opciones de Configuración y Perfil.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración 3' src='https://ecardioline.com/assets_media/configuracion/4.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                En Configuración encontrarás 4 opciones; Configuración general, Horario de atención, Honorario profesional y Paraclínicos para citas, debes completar cada una de esas secciones.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración 4' src='https://ecardioline.com/assets_media/configuracion/5.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Si ingresas desde tu Smartphone debes hacer clic en la hamburguesa de la esquina superior izquierda para que se desplieguen las 4 opciones mencionadas.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración 4' src='https://ecardioline.com/assets_media/configuracion/6.png' />
                            </div>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(3)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 3 ? 'active' : ''}`} fontSize='large' />
                            Configuración general
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 3 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                En esta sección haz clic en el icono del lápiz para editar y comenzar a incluir tus datos (fecha de nacimiento, documento de identidad y número celular no estará disponible para los pacientes), solo estará disponible para la administración de la plataforma.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración general 1' src='https://ecardioline.com/assets_media/configuracion/7.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Luego de incluir tus Datos personales haz clic en Actualizar para que se guarden los cambios
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración general 2' src='https://ecardioline.com/assets_media/configuracion/8.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                En esta misma sección modifica tu contraseña provisional por una de tu preferencia haciendo clic en el icono del Lápiz (usa una que se te haga fácil recordar y que además sea segura)
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración general 3' src='https://ecardioline.com/assets_media/configuracion/9.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                En Contraseña actual coloca la enviada en tu correo de aprobación, al terminar de incluir la nueva contraseña haz clic en el botón Actualizar.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración general 4' src='https://ecardioline.com/assets_media/configuracion/10.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                En esta misma sección sigue bajando para Seleccionar tu especialidad o profesión, puedes escribir tu especialidad donde está el icono de la lupa para buscar tu especialidad más rápido o buscarla dentro de las especialidades, una vez encontrada basta con hacer clic sobre la especialidad y veraz como cambiar a color morado, esto quiere decir que ya está seleccionada.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Configuración general 5' src='https://ecardioline.com/assets_media/configuracion/11.png' />
                            </div>
                           
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(4)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 4 ? 'active' : ''}`} fontSize='large' />
                            Horario de atención
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 4 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Incluye el horario para tus consultas online vía videochat en vivo según tu disponibilidad actual, primero haz clic sobre los días para seleccionarlos (cuando sea seleccionado se colocará en color morado), luego incluye el rango de horas de los días en; Hora desde – Hora hasta para guardar el Horario haz clic en Registrar.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Horario de atención 1' src='https://ecardioline.com/assets_media/configuracion/12.png' />
                            </div>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(5)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 5 ? 'active' : ''}`} fontSize='large' />
                            Honorario profesional
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 5 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                En esta sección selecciona la modalidad para tus consultas online para pacientes e interconsultas online para especialistas y cómo quieres recibir tu honorario profesional.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Honorario profesional 1' src='https://ecardioline.com/assets_media/configuracion/13.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                <b>NOTA:</b> Si tu internet no es estable o tienes problemas de conexión es recomendable no habilitar la modalidad de Videochat en vivo. Una manera de saber si tienes una conexión estable es determinar si cuando haces una videollamada por WhatsApp, Skype o Zoom no se entrecorta o interrumpe el audio y video.
                                <br/><br/>
                                Coloca el correo y datos afiliados a tus cuentas de pago y luego haz clic en el botón Registrar.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Honorario profesional 2' src='https://ecardioline.com/assets_media/configuracion/14.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Selecciona la moneda para tus pagos y coloca costo de tus consultas e interconsultas online y luego haz clic en el botón Registrar.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Honorario profesional 3' src='https://ecardioline.com/assets_media/configuracion/15.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Coloca el costo de la consulta vía texto en menos de 24 horas y haz clic en el botón Registrar. Si haces clic en el botón Activar consulta gratuita ofrecerás este servicio de forma gratuita.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Honorario profesional 4' src='https://ecardioline.com/assets_media/configuracion/16.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                <b>NOTA:</b> Es importante no dejar ninguna sección sin completar y luego de incluir los datos hacer clic en el botón Registrar para que los cambios queden guardados.
                            </Typography>
                        </div>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(6)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 6 ? 'active' : ''}`} fontSize='large' />
                            Paraclínicos para citas
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 6 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                En esta sección puedes incluir los paraclínicos (documentos, imágenes y datos) que desees prudente conocer previo a la consulta online (esto será opcional para los pacientes).
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Paraclínicos para citas 1' src='https://ecardioline.com/assets_media/configuracion/17.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Escribe el nombre del requisito y tipo (texto, selección simple, selección múltiple, documento, área de texto, fecha, numero, respuesta sí o no) y luego haz clic en el botón Registrar.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Paraclínicos para citas 2' src='https://ecardioline.com/assets_media/configuracion/18.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                <b>NOTA:</b> Se puede adjuntar un documento o paraclínico por cada requisito. Si deseas que te adjunten 4 documentos debes crear 4 requisitos.
                            </Typography>
                        </div>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(7)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 7 ? 'active' : ''}`} fontSize='large' />
                            Perfil
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 7 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Para ir a tu Perfil haz clic en el círculo de la esquina superior derecha.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Perfil 1' src='https://ecardioline.com/assets_media/configuracion/19.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Para ir a tu Perfil haz clic en el círculo de la esquina superior derecha.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Perfil 2' src='https://ecardioline.com/assets_media/configuracion/20.png' />
                            </div>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(8)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 8 ? 'active' : ''}`} fontSize='large' />
                            Foto de perfil y portada
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 8 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Incluye tu foto profesional haciendo clic en el icono señalado y busca la foto en tu archivo de imágenes. Además puedes incluir una foto de portada de dimensiones 1200x300. Es importante que tu foto de perfil tenga igual tamaño de alto y de ancho para evitar distorsiones de la imagen, si tu foto no tiene estas dimensiones es recomendable recortarla previo a incluirla.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Foto de perfil y portada 1' src='https://ecardioline.com/assets_media/configuracion/21.png' />
                            </div>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(9)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 9 ? 'active' : ''}`} fontSize='large' />
                            Biografía
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 9 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Haz clic en icono señalado y escribe una breve descripción sobre ti (puede ser tu formación, experiencia, en qué casos y cómo puedes ayudar a los pacientes con tus consultas online) y selecciona los idiomas que dominas.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Biografía 1' src='https://ecardioline.com/assets_media/configuracion/22.png' />
                            </div>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(10)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 10 ? 'active' : ''}`} fontSize='large' />
                            Datos profesionales
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 10 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' >
                                Desde tu ordenador o computadora debes hacer clic en los iconos señalados para incluir tu Experiencia laboral, Educación, Membresías, Premios y Reconocimientos.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Datos profesionales 1' src='https://ecardioline.com/assets_media/configuracion/23.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                Para desplegar el menú de la barra lateral izquierda de tu <b>Perfil</b> desde tu Smartphone debes hacer clic en la hamburguesa de la esquina superior izquierda, como se muestra a continuación.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Datos profesionales 2' src='https://ecardioline.com/assets_media/configuracion/24.png' />
                            </div>
                            <Typography color='secondary' component='p' >
                                No olvides hacer clic en el botón Registrar luego de incluir los datos profesionales para que los cambios surjan efecto.
                            </Typography>
                            <div className='cont-img'>
                                <img alt='Datos profesionales 3' src='https://ecardioline.com/assets_media/configuracion/25.png' />
                            </div>
                        </div>
                    </div>
                    
                </Paper>

            </section>
        );
    }
}

ConfigurarCuentaEspecialista.propTypes = {
    classes: PropTypes.object.isRequired,
};


const connectedConfigurarCuentaEspecialista = (withStyles(styles)(ConfigurarCuentaEspecialista));
export { connectedConfigurarCuentaEspecialista as ConfigurarCuentaEspecialista }; 