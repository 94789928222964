import React from "react";

import { Divider, Grid, Paper, Typography, Button, TextField, CircularProgress } from '@material-ui/core';


const Contactanos = (props) => (
    <Paper className='max-width-container mt-2 contactanos' elevation={0}>
        <Divider />
        <Grid container className='mt-2 pt-2' spacing={2}>
            <Grid item xs={12} sm={6}>
                <Typography variant='h4' color='secondary' component='p' className='fz-slogan slogan-movil mb-2'>Contáctanos</Typography>
                <Typography variant='h6' color='secondary' component='p' className=' mb-2'>¿Tienes alguna inquietud? <br /> Déjanos tu mensaje en el siguiente apartado y con gusto te responderemos.</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12}> 
                        <TextField 
                            required
                            fullWidth
                            id="mail_contacto" 
                            variant="outlined" 
                            name="mail_contacto"
                            label="Correo electrónico" 
                            value={props.contactanos.mail_contacto}
                            onChange={props.handleChangeInputCotacto}
                            error={props.contactanos.submit && !props.contactanos.mail_contacto}
                            helperText={props.contactanos.submit && !props.contactanos.mail_contacto && <span className="lbl-error" >Campo Requerido</span> }
                        />
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField 
                            rows={4}
                            required
                            fullWidth
                            multiline
                            id="asunto" 
                            name="asunto"
                            label="Mensaje" 
                            variant="outlined" 
                            value={props.contactanos.asunto}
                            onChange={props.handleChangeInputCotacto}
                            error={props.contactanos.submit && !props.contactanos.asunto}
                            helperText={props.contactanos.submit && !props.contactanos.asunto && <span className="lbl-error" >Campo Requerido</span>}
                        />
                    </Grid>
                    <Grid item xs={12}> 
                        <Button 
                            color='primary'
                            className='btn-with-br mb-2' 
                            disabled={props.contactanos.loading}
                            onClick={props.handleSubmitContacto}
                        >
                            Enviar
                            {props.contactanos.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                            
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Divider />
    </Paper>
)

export default Contactanos