import React from 'react'
/* MAterial UI */
import { Grid, Paper, Button, Link as Enlace} from '@material-ui/core';

const BtnConsulta = (props) => (
    <Grid item xs={12} align='center'>
        <Paper className="paper mb-0">
            <div>
                {props.paciente
                    ?<Enlace href={`https://portalpaciente.ecardioline.com/solicitar-consulta/${props.nickname}`}>
                        <Button className='btn-with-br' color='primary'>Solicitar consulta</Button>
                    </Enlace> 
                    : <Enlace href={`https://portalpaciente.ecardioline.com/`}>
                        <Button className='btn-with-br' color='primary'>Solicitar consulta</Button>
                    </Enlace> 
                }
            </div>
        </Paper>
    </Grid>
)


export default BtnConsulta;