import React, { Component } from 'react'
// import from "date-fns";
import { format, startOfWeek, addDays, compareAsc, startOfMonth, endOfMonth, isSameMonth, addMonths, subMonths, endOfWeek } from 'date-fns'
import { es as esLocale } from 'date-fns/locale'

import './calendarioComponent.css';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';


/* Components */
// import AlertDialog from "./alertDialogo";

/* Actions */
// import { alertActions, horarioActions } from '../../../../_actions';

/* Iconos */
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


class Calendar extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            currentMonth: new Date(),
            selectedDate: new Date(),
            open: false,
            selectedFech: null,
        };

    }

    renderHeader() {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header rowCalendar flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        <ArrowBackIosIcon fontSize='inherit'/>
                    </div>
                </div>
                <div className="col col-center">
                    <Typography color="primary" component="span">
                        {format(this.state.currentMonth, dateFormat, { locale: esLocale })}
                    </Typography>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon">
                        <ArrowForwardIosIcon  fontSize='inherit'/>
                    </div>
                </div>
            </div>
        );
    }

    renderDays() {
        const dateFormat = "E";
        const dateFormatTow = "ddd";
        const days = [];
        let startDate = startOfWeek(this.state.currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    <Hidden smUp>
                        {format(addDays(startDate, i), dateFormatTow, { locale: esLocale })}
                    </Hidden>
                    <Hidden xsDown>
                        {format(addDays(startDate, i), dateFormat, { locale: esLocale })}
                    </Hidden>

                </div>
            );
        }

        return <div className="days rowCalendar">{days}</div>;
    }

    renderCells() {
        const { currentMonth } = this.state;
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        const { list, citasAgendadas, handleSelectFech } = this.props;
        const dateFormatTow = "EEEEEE";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                let diaSelected = false; 
                const diaActual = format(day, 'yyyy-mm-dd');

                //Validacion para el Calendario de citas
                if (citasAgendadas) {
                    citasAgendadas.map(dia => {
                        if (dia.fech_quotes === diaActual) {
                            diaSelected = true
                        }
                        return '';
                    })
                }
                
                // Validacion para Horario medico
                const fil = format(addDays(startDate, i), dateFormatTow, { locale: esLocale });
                if (list){
                    list.map(dia=>{
                        if (dia.codigo.toLowerCase() === fil) {
                            diaSelected = true
                        }
                        return '';
                    })
                }
                // Fin Validacion para Horario medico
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                
                const diaPaso = (compareAsc(day, new Date()) !== 1) ? 'disabled' : '';
                const styleSelectDia = !isSameMonth(day, monthStart) ? "disabled" : diaSelected ? "selectedDia" : 'disabled'
                days.push(
                    <div
                        className={`col cell ${styleSelectDia} ${diaPaso}`}
                        key={day}
                        
                    >
                        <span className="number">{formattedDate}</span>
                        {citasAgendadas &&
                            <span className="bg" onClick={diaSelected ? () => handleSelectFech(format(cloneDay, 'dd/mm/yyyy')) : () => handleSelectFech()} >{formattedDate}</span>
                        }
                        {list &&
                            <span className="bg" onClick={diaSelected ? () => this.onDateClick(cloneDay) : () => this.onDateClick()}>{formattedDate}</span>
                        }
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="rowCalendar" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="bodyCalendar">{rows}</div>;
    }

    onDateClick = day => {
        // const { dispatch } = this.props;
        if (day) {
            const result = compareAsc(
                day,
                new Date()
            )
            //console.log(result)
            if (result !== 1) {
                // dispatch(alertActions.error('La fecha seleccionada ya paso'));
            }else{
                console.log(result)
                // this.setState({ open: true });
                // this.setState({
                //     selectedFech: format(day, 'yyyy-mm-dd', { locale: esLocale })
                // });
            }
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    
    nextMonth = () => {
        this.setState({
            currentMonth: addMonths(this.state.currentMonth, 1)
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: subMonths(this.state.currentMonth, 1)
        });
    };

    render() {
        return (
            <div className="calendar">
               
                {this.renderHeader()}
                {this.renderDays()}
                {this.renderCells()}
                {/* <AlertDialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    message='¿Esta seguro de seleccionar este dia?'
                    procesar={this.handleSubmitDiaInactivo}
                /> */}
            </div>
        );
    }
}

export default Calendar;