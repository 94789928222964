import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

/**Components */

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

class TerminoDeUso extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        // const { blog } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-blog' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan'>
                            Condiciones de uso
                        </Typography>
                        <Typography variant='h4' className='slogan _two'>
                            Fecha de última actualización: 13/04/2019
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-preguntas' elevation={0}>
                    <div className='cont-texto'>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Bienvenido a las condiciones de uso de la plataforma web de CARDIOLINE C.A. 
                            <br/><br/>
                            El uso de este sitio web, a través de cualquier dispositivo electrónico (Smartphone, Tablet o Computadora), está condicionado a su aceptación de las condiciones de uso y políticas de privacidad, al registrase, se compromete a respetar estas condiciones de uso. Si no está de acuerdo con estas condiciones, no está autorizado para acceder o utilizar este sitio web.
                            <br /><br />
                            POR FAVOR LEA ESTAS CONDICIONES DE USO.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Nuestros servicios
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A proporciona una plataforma web de telemedicina para poner al alcance de las personas de una manera interactiva un amplio directorio de especialistas calificados en las diversas áreas de la salud, para que recibir consultas y orientaciones a través de videollamadas y chat en vivo, proporcionando una solución adecuada para resolver problemas médicos y enfermedades comunes desde cualquier lugar y momento.
                            <br /> <br />
                            Los profesionales de la salud pueden hacer publicaciones de texto e imágenes con fines informativos, educativos y de promoción de sus servicios en línea para pacientes y otros profesionales de la salud. Todos los especialistas de la salud han decidido participar de forma independiente para ofrecer y proporcionar sus servicios especializados en la plataforma web <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>.
                            <br /> <br />
                            Nuestra plataforma web está diseñada para que los profesionales de la salud puedan:
                            <Typography color='secondary' component='p' className='mb-1 mt-05'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Promover estilos vida y conductas saludables.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Seguimiento de enfermedades conocidas.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Segundas opiniones.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Aclarar dudas sobre alguna enfermedad, tratamiento o método diagnóstico.
                            </Typography>
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Este sitio - NO ES PARA EMERGENCIAS
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Este sitio y los servicios de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> NO se usan para emergencias médicas o situaciones urgentes. No debe ignorar o retrasar la búsqueda de una atención médica basado en cualquier cosa que aparezca en este sitio web. SI SOSPECHA UNA EMERGENCIA LLAME INMEDIATAMENTE AL 911 O ACUDA A UN CENTRO MÉDICO CERCANO CON SERVICIO DE EMERGENCIA.
                            <br /> <br />
                            Su interacción con los profesionales de la salud a través de los servicios en www.ecardioline.com no pretende reemplazar su relación con su médico de atención primaria actual. Los profesionales de la salud en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. se reservan el derecho de denegar atención por el uso incorrecto real o potencial de nuestros servicios. Los servicios no incluyen la provisión de atención médica por parte de CARDIOLINE C.A. Por el contrario, los servicios permiten el acceso a los profesionales de la salud que aceptaron proporcionar atención a los pacientes a través de los servicios de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>.
                            <br /> <br />
                            Estas condiciones de uso se aplican a uso del sitio, registro, afiliación y uso de nuestros servicios.
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Política de prescripción
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A no respalda ni la ofrece tecnología para la prescripción de fármacos o medicamentos de venta en farmacias con récipe. Los profesionales de la salud no están autorizados para prescribir en nombre de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, la marca comercial eCARDIOLINE, o de CARDIOLINE C.A sustancias controladas por la DEA, medicamentos no terapéuticos y algunas otras drogas que pueden ser dañinas debido a su potencial de abuso.
                            <br /> <br />
                            En el caso de que un profesional de la salud prescriba un medicamento, limitará el suministro basado en las regulaciones y leyes vigentes según el lugar de residencia del usuario y solo prescribirá un medicamento según lo determine apropiado a su exclusivo criterio y juicio profesional, siempre y cuando posea licencia para hacerlo. Las consecuencias o efectos adversos que pueden ser derivados de tratamientos son responsabilidad de cada profesional de la salud. CARDIOLINE C.A no garantiza que se prescriba una receta ni es responsable de malas respuestas de los tratamientos.
                            <br /> <br />
                            Usted acepta que cualquier receta que adquiera de un profesional de la salud será únicamente para su uso personal. Usted acepta leer completa y cuidadosamente toda la información y las etiquetas del producto, y ponerse en contacto con el profesional de la salud si tiene alguna pregunta con respecto a la receta.
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Registro, requisitos y seguridad
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Para poder registrarse y utilizar los servicios, usted declara y garantiza que tiene al menos dieciocho años de edad y posee el derecho y la capacidad legal, en nombre propio o de un menor de edad del que es padre o representante legal, para aceptar estas condiciones de uso. Usted acepta crear su cuenta en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> completa, exacta y verazmente, que incluye, entre otros, su nombre y apellido, foto de perfil, teléfono (opcional), país de residencia y procedencia, correo electrónico y contraseña, que se convierten en su identificación y credenciales para CARDIOLINE C.A. Su identificación y/o credenciales de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> son personales, usted es el único responsable de mantener la confidencialidad de su identificación, credenciales y de todas las actividades que ocurran conforme a dicha identificación.
                            <br /> <br />
                            Usted acepta prohibir que otra persona use su identificación y credenciales en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> y usted acuerda notificar inmediatamente de cualquier uso no autorizado real o presunto de su identificación o credenciales u otras inquietudes de seguridad que note.
                            <br /> <br />
                            A fin de determinar su cumplimiento de estas condiciones de uso, nos reservamos el derecho, pero no la obligación, de controlar su acceso y los servicios. CARDIOLINE C.A puede, a su entera discreción, negarse a proporcionar atención para el uso incorrecto real o potencial de estos servicios, o por el incumplimiento de estas condiciones de uso.
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Prevención y seguridad del fraude
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A puede contactarlo por teléfono o correo electrónico para verificar la información de su cuenta en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. CARDIOLINE C.A puede solicitarle más información y usted aceptar proporcionar dicha información adicional para asegurarnos de que no haya creado fraudulentamente su cuenta. Si no proporciona esta información en la forma solicitada dentro de los 7 días hábiles posteriores a la solicitud, CARDIOLINE C.A se reserva el derecho de suspender, descontinuar o denegar su acceso en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> y la utilización de los servicios, hasta que el usuario proporcione la información requerida.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Modificación a los términos de uso
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Imprima y guarde una copia de estas condiciones de uso. CARDIOLINE C.A se reserva el derecho de modificar las condiciones de uso periódicamente, por cualquier motivo, y sin previo aviso. Se puede acceder a la versión más actual de estas condiciones de uso en cualquier momento haciendo clic en el enlace condiciones de uso en la parte inferior del sitio. Revise las condiciones de uso a menudo para que se informe de los cambios realizados. Cualquier revisión o cambio de este tipo será vinculante y efectivo inmediatamente después de la publicación. A menos que expresemos lo contrario, estos términos incorporan y reemplazan cualquier otro término asociado anteriormente.
                            <br /> <br />
                            CARDIOLINE C.A tiene el derecho de limitar, suspender, descontinuar o denegar su acceso y uso de los servicios en cualquier momento, y sin previo aviso, a cualquier persona que viole estas condiciones de uso, o que CARDIOLINE C.A considere apropiados o necesarios a su entera discreción, que incluye pero no se limita a (1) razones de seguridad (2) presunta o supuesta violación de estas condiciones de uso, o (3) la protección de la propiedad intelectual.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Información proporcionada por usted
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Como parte del proceso de registro, debe proporcionarnos cierta información personal y médica. Además, es su responsabilidad actualizar su perfil en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> tan pronto como sea posible con los cambios en su información personal y/o médica para que todos los registros sean actuales, completos y precisos.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Historia médica electrónica
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            La historia médica electrónica en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. CARDIOLINE C.A puede solicitarle más información y usted aceptar proporcionar dicha información adicional para asegurarnos de que no haya creado fraudulentamente su cuenta. Si no proporciona esta información en la forma solicitada dentro de los 7 días hábiles posteriores a la solicitud, CARDIOLINE C.A se reserva el derecho de suspender, descontinuar o denegar su acceso en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> se crea para que ingrese, almacene y acceda a su historia médica en línea, incluidos sus antecedentes médicos, afecciones médicas actuales, síntomas, alergias y medicamentos que recibe, para que los profesionales de la salud puedan proporcionar una atención personalizada y registren los resultados de sus atenciones en línea.
                            <br /> <br />
                            Cualquier información proporcionada como parte de una consulta, se convierte en parte de su registro médico en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. Usted acepta proporcionar información precisa, actual y completa para su registro en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, revisar periódicamente y actualizar dicha información según sea necesario. Es su responsabilidad confirmar cualquier información de terceros, o información sobre un menor de edad del que sea padre o representante legal, en su historia médica electrónica en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. 
                            <br /> <br />
                            CARDIOLINE C.A puede solicitarle más información y usted aceptar proporcionar dicha información adicional para asegurarnos de que no haya creado fraudulentamente su cuenta. Si no proporciona esta información en la forma solicitada dentro de los 7 días hábiles posteriores a la solicitud, CARDIOLINE C.A se reserva el derecho de suspender, descontinuar o denegar su acceso en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>.
                            <br /> <br />
                            CARDIOLINE C.A no es responsable de mantener los datos que surjan del uso de los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> y se reserva el derecho de mantener, eliminar o destruir todas las comunicaciones y materiales publicados o cargados en los historiales médicos de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> de conformidad con sus políticas internas de retención y/o destrucción de registros.
                            <br /> <br />
                            Al solicitar una cita con algún profesional de la salud en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> su historia médica y registros de previas atenciones estará disponibles para su visualización. Por lo tanto cuando solicite una consulta a través de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> usted acepta divulgar el contenido de su historia médica electrónica completa al profesional de la salud que realizará su consulta.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Correos informativos
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Al registrarse en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> podemos enviarle boletines informativos, consejos para el uso apropiado del sitio, promociones y cambios que consideremos importantes en los servicios, condiciones de uso y políticas de privacidad. Si no desea recibir tales comunicaciones puede hacerlo saber a través del siguiente correo info@ecardioline.com.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Limitaciones de uso
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Usted acepta que no utilizará los servicios como reemplazo de la atención médica presencial de una cita que ya tenga prevista o planificada con su médico tratante, a menos que este sea su médico. No utilizará <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> de ninguna manera ilegal y/o para ningún propósito ilegal. No publicará ni transmitirá un mensaje con un nombre falso, ni utilizará los recursos de la red de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> para hacerse pasar por otra persona o tergiversar la autorización para actuar en nombre de otros o de CARDIOLINE C.A. Todos los mensajes transmitidos a través de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> deben identificar correctamente al remitente. No puede alterar la atribución de origen en mensajes de correo electrónico o publicación. No permitirá que otra persona o entidad use su cuenta, nombre de usuario o contraseña para acceder o usar los servicios, publicar o ver comentarios.
                            <br /> <br />
                            No intentará socavar la seguridad o la integridad de los sistemas informáticos o redes de CARDIOLINE C.A o sus socios, ni a aquellos a los que se accede a través de o con sus productos, y no debe intentar obtener acceso no autorizado. No puede publicar ni transmitir datos, materiales, contenido o información que sea amenazante, falsa, engañosa, abusiva, difamatoria, pornográfica o profana, o que contenga o promueva virus, gusanos, troyanos, bombas de tiempo u otra programación informática o código diseñado o destinado a dañar, destruir, interceptar, descargar, interferir, manipular o interrumpir o expropiar cualquier función o sección en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. No debe alterar, piratear, falsificar, copiar, modificar o de otra manera dañar la administración, seguridad o el correcto funcionamiento de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>.
                            <br /> <br />
                            No usará robots o scripts. No intentará realizar ingeniería inversa, ensamblaje inverso, compilación inversa, descompilar, desensamblar, traducir o alterar, defraudar o crear resultados falsos de ningún código ejecutable o información. Usted acepta que se ejecute un software antivirus y/o antispyware configurado para anular la configuración de cookies del navegador de Internet.
                            <br /> <br />
                            Además, acepta que cualquier información que proporcione o utilice no infringirá ni facilitarán la infracción de ningún derecho de autor, patente, marca comercial, secreto comercial u otros derechos de propiedad, publicidad o privacidad de ningún parte, incluidos los derechos de terceros.
                            <br /> <br />
                            CARDIOLINE C.A se reserva el derecho de eliminar cualquier información proporcionada por usted que considere, a su sola discreción, fraudulenta, abusiva, difamatoria y obscena o en violación de un derecho de autor, marca registrada u otra propiedad intelectual o derecho de propiedad de cualquier otra persona.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Funcionalidad de los servicios
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A se reserva completa y exclusiva discreción con respecto al funcionamiento de los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. CARDIOLINE C.A puede, entre otras cosas, retirar, suspender o descontinuar cualquier funcionalidad o característica de los servicios de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. CARDIOLINE C.A no se responsabiliza por errores de transmisión, corrupción o compromiso de información transportada por operadores de telecomunicaciones locales o de intercambio.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Propiedad intelectual
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Con la excepción de su historia médica, CARDIOLINE C.A retiene todos los derechos, títulos e intereses en www.ecardioline.com, los servicios y cualquier información, productos, documentación, software u otros materiales, y cualquier patente, copyright, secreto comercial, marca registrada, marca de servicio u otra propiedad intelectual o derecho de propiedad.
                            <br /> <br />
                            Usted acepta no almacenar, copiar, modificar, alquilar, arrendar, prestar, vender, distribuir, transferir, transmitir, mostrar, realizar ingeniería inversa, ensamblar inversamente, o intentar descubrir cualquier código de programación o cualquier código fuente utilizado. Usted acepta que las violaciones suyas, cualquier otra persona o entidad, de estos derechos de autor, secretos comerciales, patentes, otras protecciones de propiedad intelectual o estas condiciones serán demandados de acuerdos a las leyes vigentes. La decisión final de si un usuario o empresa infringe cualquiera de estas políticas queda a exclusivo criterio de CARDIOLINE C.A. Cualquier uso de las marcas comerciales, marcas de servicio o logotipos exhibidos debe ser autorizado por escrito por CARDIOLINE C.A.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Uso internacional
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Los servicios de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> están disponibles en cualquier parte del mundo, sin embargo los usuarios no pueden recibir atención médica para el diagnóstico o tratamiento de ninguna enfermedad por profesionales de la salud que no posean licencias y permisos legales de acuerdo a la leyes vigentes para el ejercicio de la medicina o profesión en el sitio de residencia del usuario. CARDIOLINE C.A no se hace responsable del incumplimiento de estas condiciones de uso por parte de los profesionales de salud.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Exoneraciones de responsabilidades
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Exoneración de responsabilidad médica: CARDIOLINE C.A no afirma ni garantiza el contenido y respuesta de tratamiento de ningún profesional de la salud. Los profesionales de la salud aceptan ofrecer sus servicios de manera independiente y no son empleados de CARDIOLINE C.A. Usted y su médico son los únicos responsables de toda la información y/o comunicación enviada. CARDIOLINE C.A no garantiza que una videollamada o chat sea el tratamiento adecuado para su problema o enfermedad. Además, los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> no son un sustituto de su atención primaria. Usted acepta contactar a su profesional de la salud inmediatamente de alguna eventualidad o si sus síntomas empeoran. Si necesita atención urgente, debe comunicarse o asistir a los servicios de emergencia locales de inmediato
                            <br /> <br />
                            Exoneración de responsabilidad del contenido: nada de lo contenido en este sitio debe interpretarse, directa o indirectamente, como la práctica de la medicina o la prestación de servicios médicos por parte de CARDIOLINE C.A. La información y artículos proporcionados en este sitio o a través de este están destinados únicamente como material educativo general y proporcionan un mecanismo para encontrar y conectarse con un profesional de la salud que, sujeto a sus responsabilidades profesionales, puede o no brindarle atención médica. Siempre busque el consejo de un profesional de la salud calificado sobre las preguntas que tenga con respecto a su afección médica, y antes de comenzar, suspender o modificar cualquier tratamiento o medicamento.
                            <br /> <br />
                            Nunca demore la obtención de asesoramiento médico o desatienda el consejo médico debido a algo que haya leído o no este sitio. CARDIOLINE C.A no ofrece garantías ni representaciones con respecto a la exactitud de la información provista en este sitio, y no asume ninguna responsabilidad por las consecuencias relacionadas directa o indirectamente con cualquier acción u omisión que tome en base a la información y el material de este sitio. Usted asume todo el riesgo de pérdida al utilizar este sitio y la información contenida.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Exoneración de responsabilidad general
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Usted reconoce que su uso de los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, es bajo su propio riesgo, y que usted asume la responsabilidad total por todos los riesgos asociados. Toda la información, productos o servicios contenidos o proporcionados a través de este sitio web se proporcionan "tal cual" como están disponibles sin ninguna garantía de ningún tipo, expresa o implícita. Hasta el grado máximo permitido bajo la ley aplicable, CARDIOLINE C.A y sus accionistas, afiliados, directores, administradores, trabajadores u otros representantes (colectivamente, "afiliados") no otorgan ninguna declaración ni garantía, expresa o implícita, estatutaria o de otro tipo, incluidas, entre otras, las garantías de comerciabilidad, idoneidad para un propósito en particular, título, no infracción y libertad de las formas de virus informático.
                            <br /> <br />
                            CARDIOLINE C.A no respalda las promociones, productos o servicios de terceros. CARDIOLINE C.A no garantiza ni valida la información de anuncios, promociones, comunicaciones u otros materiales de terceros. CARDIOLINE C.A no asume ninguna responsabilidad u obligación por la exactitud de la información contenida en este sitio o en los sitios web de terceros.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Confiabilidad y garantías de servicio
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A no garantiza que los servicios cumplirán con sus requisitos, o que los servicios serán ininterrumpidos, oportunos, 100% seguros o libres de errores, o que los defectos, de haberlos, serán corregidos. Esto incluye la pérdida de datos como resultado de retrasos y cualquier interrupción del servicio causada por terceros. CARDIOLINE C.A no es responsable de los errores de transmisión, interrupción o compromiso de los datos transportados por los operadores de telecomunicaciones locales o de intercambio.
                            <br /> <br />
                            CARDIOLINE C.A tomará todas las precauciones necesarias para proteger contra fallas de nuestro equipo y software. El usuario reconoce y acepta que pueden producirse interrupciones temporarias en el servicio, y que CARDIOLINE C.A no tendrá responsabilidad por ningún reclamo, costo, cargo, pérdida o gasto que surja de o relacionado con el uso de los servicios. El usuario reconoce y acepta que los datos pueden perderse o corromperse en relación con el uso de los servicios. CARDIOLINE C.A puede realizar copias de seguridad periódicas de todos los datos almacenados, pero no tendrá ninguna responsabilidad para el usuario en el caso de que se pierdan o destruyan todos los datos. 
                            <br /> <br />
                             El usuario reconoce y acepta que, en caso de que sea necesaria la restauración de los datos de la copia de seguridad, puede llevar varios días completar dicha restauración de los datos y reanudar el funcionamiento de los servicios.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Indemnización
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Usted acepta liberar, indemnizar, defender y eximir de responsabilidad a CARDIOLINE C.A, nuestros contratistas, aliados, accionistas, trabajadores, directores y afiliados de todas las responsabilidades, reclamos, derechos, pérdidas, causas de acción, acciones y demandas. (no importa si en ley o en equidad), gastos, incluidos honorarios de abogados, de terceros relacionados o que surjan, directa o indirectamente, fuera de o en conexión con (i) su uso o mal uso del sitio o los servicios o cualquier otra información publicada en el sitio, (ii) su registro, (iii) su incumplimiento de las condiciones de uso o la política de privacidad, (iv) su relación con cualquier profesional de la salud, (v) el contenido o cualquier información que proporcione en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, a cualquier profesional de la salud, asesor o ejecutivo de servicio al cliente, y/o (vi) cualquier acción u omisión negligente por su parte en el uso o mal uso de este sitio o los servicios o cualquier información publicada en el sitio, incluyendo infracción de derechos de propiedad intelectual de terceros, derechos de privacidad o conducta negligente o ilícita.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Limitación de responsabilidad
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Excepto por lo prohibido por la ley, y sin limitación:
                            <br /> <br />
                            CARDIOLINE C.A no será responsable de los actos u omisiones de ninguno de los profesionales de la salud independientes en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>.
                            <br /> <br />
                            Usted será el único y completamente responsable de cualquier daño a <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> y sus servicios o cualquier sistema informático, cualquier pérdida de datos, o cualquier uso o divulgación indebida de información sobre los servicios causados por usted o cualquier persona utilizando su nombre de usuario o contraseña. CARDIOLINE C.A no asume ninguna responsabilidad derivada del incumplimiento de cualquier infraestructura de telecomunicaciones o de internet o por el uso indebido de cualquiera de los consejos, informaciones, instrucciones de los servicios.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Protección de terceros
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Las disposiciones de indemnización y exención de responsabilidad establecidas anteriormente son para el beneficio de CARDIOLINE C.A, sus contratados, accionistas, trabajadores y afiliados. Cada una de estas personas o entidades tendrá el derecho de hacer valer y hacer cumplir esas disposiciones directamente en su contra en su nombre.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Culminación de los servicios
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A puede rescindir los servicios en cualquier momento, sin penalización y sin previo aviso, si no cumple con alguna de estas condiciones de uso, o las protecciones de propiedad intelectual. CARDIOLINE C.A también puede solicitar el enjuiciamiento legal de cualquier violación de la ley o estas condiciones de uso según las leyes vigentes en territorio Venezolano. En el caso que su cuenta sea suspendida voluntariamente o por acciones de incumplimiento de estas condiciones de uso, CARDIOLINE C.A tiene el derecho de eliminar todos los datos, archivos u otra información que esté almacenada en la cuenta del usuario.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Costos
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Usted acepta las tarifas de los planes de afiliación y las tarifas de consultas en línea, de conformidad con el baremo de planes y el honorario establecido por los profesionales de la salud en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. Si su empresa o institución ha acordado con CARDIOLINE C.A pagar la tarifa o cualquier porción de la tarifa de los servicios, o si la tarifa es en virtud de algún otro acuerdo con CARDIOLINE C.A, dicho ajuste de la tarifa se reflejará en la tarifa que finalmente se cobrará, puedes consultar tu plan de afiliación para determinar si algún servicio está cubierto.
                            <br /> <br />
                            Como consideración adicional de los servicios, acepta proporcionar información verdadera, actual, completa y precisa, incluida la información de la forma y método de pago, según se requiera. Al completar y enviar el formulario de pago usted declara que los datos en su solicitud son verdaderos y precisos. CARDIOLINE C.A y los profesionales de la salud de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> se reservan el derecho de modificar su estructura de precios y honorarios por los servicios en cualquier momento e implementar la nueva estructura de precios en cualquier momento antes de facturarle cualquier pago de los servicios. Usted comprende que es posible que no se brinden los servicios o que se cancelen si la información de la forma y método de pago es incorrecta, incompleta o no es válida.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Horario de los servicios
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            En <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> cuando solicita una cita se proporcionara la fecha, hora y zona horaria del sitio de residencia del profesional de la salud, podemos enviar notificaciones para recordarle la fecha y hora de su cita. Sin embargo es importante que tome en cuenta la diferencia horaria que puede existir entre el país de residencia del profesional de la salud y su país de residencia antes de agendar su cita. CARDIOLINE C.A no reembolsará ni reprogramará las citas basándose en un error o pérdida de una cita en base a las diferencias horarias.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Causa de fuerza mayor
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A no será responsable de las pérdidas derivadas de la demora o la interrupción de su cumplimiento de las citas y compromisos en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, por motivos religiosos, acto de autoridad gubernamental, acto de enemigo público, guerra, fallas en los servicios de energía eléctrica e internet, condiciones climáticas severas o cualquier otra causa fuera de su control.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Ley aplicable, jurisdicción y lugar
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Estas condiciones de uso y la política de privacidad se regirán e interpretarán de conformidad con las leyes Venezolanas, sin dar efecto a ninguna elección de normas o principios legales.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Comentarios, sugerencias y presentaciones
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A recibe comentarios, sugerencias y presentaciones de sus usuarios. Cualquier comentario, sugerencia y envío realizado por los usuarios, incluidos, entre otros, términos, notas, comentarios, obras de arte, comunicaciones, códigos informáticos o materiales creativos proporcionados en www.ecardioline.com podrán ser usados por CARDIOLINE C.A a su conveniencia y el usuario acepta que puede hacerlo sin necesitar autorización o cualquier información adicional, si representar problemas legales. Esta sección no incluirá ninguna información personal o historial médico enviado por el usuario.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            Los principios éticos profesionales de CARDIOLINE C.A incluyen:
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            <Typography color='secondary' component='p' className='mb-1 mt-05'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Respeto de la relación médico – paciente.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Uso de profesionales de la salud certificados.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Respeto de los derechos, normas y leyes vigentes.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Seguridad y privacidad de los datos personales y médicos.
                            </Typography>
                        </Typography>
                    </div>

                </Paper>

            </section>
        );
    }
}

TerminoDeUso.propTypes = {
    classes: PropTypes.object.isRequired,
};



const connectedTerminoDeUso = (withStyles(styles)(TerminoDeUso));
export { connectedTerminoDeUso as TerminoDeUso }; 