import React from 'react'

/* Components */
import Chips from "../../chips/chips";

/* MAterial UI */
import { Grid, Typography, Avatar, Button, Link as Enlace } from '@material-ui/core';

const BarActionMovil = (props) => (
    <div className="max-width-container cont-bar-action p-r-l-0">
        <Grid container>
            <Grid item xs={12} className="br-top">
                <div className="img-and-btn-follow">
                    <div className="cont-img-perfil">
                        <Avatar alt="Especialista" src={`${props.foto_perfil}`} className='foto-perfil' />
                    </div>
                    <Enlace href={`https://portalpaciente.ecardioline.com/solicitar-consulta/${props.nick}`}>
                        <Button className='btn-with-br' color='primary'>Solicitar consulta</Button>
                    </Enlace>
                </div>
            </Grid>
            <Grid item xs={12} className='p-r-l-24'>
                <Typography conponent="span" className="name-user" color="primary">{props.nameEspecialista}</Typography>
                <Typography conponent="span" className="time-regis" color="primary">En eCARDIOLINE desde {props.fech_regis}</Typography>
                <Typography conponent="span" color="textSecondary" dangerouslySetInnerHTML={{
                    __html: props.extracto
                        ? !props.viewMasExtracto
                            ? props.extracto.length > 100 ? `${props.extracto.slice(0, 100)}...` : props.extracto.slice(0, 100)
                            : props.extracto
                        : '...'
                }} />

                {props.extracto &&
                    props.extracto.length > 100
                    ? <Typography conponent="span" className='pointer' color="primary" onClick={props.handleVerMasExtracto(props.viewMasExtracto)}>
                        {props.viewMasExtracto ? 'Leer menos' : 'Leer más'}
                    </Typography>
                    : ''
                }
            </Grid>
            <Grid item xs={12} className='br-top p-r-l-24'>
                <Chips
                    list={props.especialidades}
                />
            </Grid>
            <Grid item xs={6} className="cursor-pointer br-top br-right" align="center"  onClick={props.handleUpdateViewEpage(1)}>
                <Typography conponent="span" color="primary">Publicaciones</Typography>
                <Typography conponent="span" color="primary" className="fw-bold sifras">{props.post}</Typography>
            </Grid>
            <Grid item xs={6} className="cursor-pointer br-top br-top br-right" align="center" onClick={props.handleUpdateViewEpage(2)}>
                <Typography conponent="span" color="primary">Seguidores</Typography>
                <Typography conponent="span" color="primary" className="fw-bold sifras">{props.seguidores}</Typography>
            </Grid>
            <Grid item xs={6} className="cursor-pointer br-top br-right" align="center" onClick={props.handleUpdateViewEpage(3)}>
                <Typography conponent="span" color="primary">Siguiendo</Typography>
                <Typography conponent="span" color="primary" className="fw-bold sifras">{props.siguiendo}</Typography>
            </Grid>
            <Grid item xs={6} className="cursor-pointer br-top br-right" align="center" onClick={props.handleUpdateViewEpage(4)}>
                <Typography conponent="span" color="primary">Recomendaciones</Typography>
                <Typography conponent="span" color="primary" className="fw-bold sifras">{props.recomendaciones}</Typography>
            </Grid>
        </Grid>
    </div>
)


export default BarActionMovil;