import { alertActions } from './';
import { generalPublicConstants } from '../_constants';
import { generalPublicService, postEpageService } from '../_services';

export const generalPublicActions = {
    changeInputContactanos,
    getEspecialistas,
    getAnalitica,
    getEspecialidades,
    submitContactanos,
    buscarEspecialistas,
    getPublicaciones,
    getBlog,
    getPostPublicLink,
    registroEspecialista,
    getchangeInputRegisMedico,
};
function changeInputContactanos(data) {
    return { type: generalPublicConstants.CHANGE_IMPUT_CONTACTO, data }
}

function getEspecialistas(condicion) {
    return dispatch => {
        dispatch(request({ reducer: 'preloaderEspecialistasHome', status: true }));
        generalPublicService.getEspecialistas(condicion)
            .then(
                data => {
                    if (data.success) {
                        const list = condicion === 'team' ? data.data.splice(0, 6) : data.data.splice(0, 3)
                        dispatch(success(list));
                    } else {
                        dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                    }
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                }
            )
    }
    function request(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    function failure(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_ESPECIALISTAS, data } }
}

function getAnalitica() {
    return dispatch => {
        // dispatch(request({ reducer: 'preloaderEspecialistasHome', status: true }));
        generalPublicService.getAnalitica()
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                    } else {
                        // dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                    }
                },
                error => {
                    console.log(error.toString())
                    // dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                }
            )
    }
    // function request(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    // function failure(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_ANALITICA, data } }
}

function getEspecialidades() {
    return dispatch => {
        // dispatch(request({ reducer: 'preloaderEspecialistasHome', status: true }));
        generalPublicService.getEspecialidades()
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                    } else {
                        // dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                    }
                },
                error => {
                    console.log(error.toString())
                    // dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                }
            )
    }
    // function request(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    // function failure(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_ESPECIALIDADES, data } }
}

function submitContactanos(contactanos) {
    return dispatch => {
        if (contactanos.asunto === '' || contactanos.mail_contacto === '') {
            dispatch(submit({ name: 'submit', value: true }));
        }else{
            dispatch(request({ name: 'loading', value: true }));
            generalPublicService.submitContactanos(contactanos).then(
                data => {
                    if (data.success) {
                        dispatch(success());
                        dispatch(alertActions.success(data.message));
                    } else {
                        dispatch(failure({ name: 'loading', value: false }));
                        dispatch(alertActions.info('Disculpe no se pudo enviar su correo, inténtelo nuevamente'));
                    }
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure({ name: 'loading', value: false }));
                    dispatch(alertActions.error('Error de conexión'));
                }
            )
        }
    }
    function request(data) { return { type: generalPublicConstants.CHANGE_IMPUT_CONTACTO, data } }
    function submit(data) { return { type: generalPublicConstants.CHANGE_IMPUT_CONTACTO, data } }
    function failure(data) { return { type: generalPublicConstants.CHANGE_IMPUT_CONTACTO, data } }
    function success() { return { type: generalPublicConstants.SUCCESS_SUBMIT_CONTACTANOS } }
}

function buscarEspecialistas(especialidad, especialista, moverScroll) {
    return dispatch => {
        if (!especialidad) {
            dispatch(alertActions.info('Debe seleccionar una especialidad'));
        }else{
            dispatch(request({ reducer: 'preloaderEspecialistasHome', status: true }));
            window.scrollTo(0, moverScroll)
            generalPublicService.buscarEspecialistas(especialidad, especialista).then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                    } else {
                        dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                    }
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure({ reducer: 'preloaderEspecialistasHome', status: false }));
                }
            )
        }
    }
    function request(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    function failure(data) { return { type: generalPublicConstants.REQUEST_GENERAL_PUBLIC, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_ESPECIALISTAS, data } }
}

function getPublicaciones() {
    return dispatch => {
        dispatch(request({ reducer: 'preloader', status: true }));
        generalPublicService.getPublicaciones()
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                    } else {
                        dispatch(failure({ reducer: 'preloader', status: false }));
                    }
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure({ reducer: 'preloader', status: false }));
                }
            )
    }
    function request(data) { return { type: generalPublicConstants.REQUEST_PUBLICACIONES, data } }
    function failure(data) { return { type: generalPublicConstants.REQUEST_PUBLICACIONES, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_PUBLICACIONES, data } }
}

function getBlog(offset, reducer) {
    return dispatch => {
        dispatch(request({ reducer , status: true }));
        generalPublicService.getBlog(offset).then(
            data => {
                if (data.success) {
                    dispatch(success(data.data));
                } else {
                    dispatch(failure({ reducer , status: false }));
                }
            },
            error => {
                console.log(error.toString())
                dispatch(failure({ reducer , status: false }));
            }
        )
    }
    function request(data) { return { type: generalPublicConstants.REQUEST_BLOG, data } }
    function failure(data) { return { type: generalPublicConstants.REQUEST_BLOG, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_BLOG, data } }
}

function getPostPublicLink(nickname, postkey) {
    return dispatch => {
        dispatch(request({ reducer: 'preloader', status: true }));
        postEpageService.getPostPublicLink(nickname, postkey).then(
            data => {
                if (data.success) {
                    dispatch(success(data.data));
                } else {
                    dispatch(failure({ reducer: 'preloader', status: false }));
                }
            },
            error => {
                console.log(error.toString())
                dispatch(failure({ reducer: 'preloader', status: false }));
            }
        )
    }
    function request(data) { return { type: generalPublicConstants.REQUEST_POST_PUBLIC, data } }
    function failure(data) { return { type: generalPublicConstants.REQUEST_POST_PUBLIC, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_GET_POST_PUBLIC, data } }
}

function registroEspecialista(medico, captcha) {
    return dispatch => {
        if (captcha === '') {
            dispatch(alertActions.info('Debe resolver el Captcha para registrarte'));
        }else{
            if (medico.nombre === '' || medico.correo === '' || !medico.file.size) {
                dispatch(submit({name: 'submit', value : true}))
                dispatch(alertActions.info('Los campos con (*) son obligatorios'));
            }else{
                if (medico.file.size > 8388608) {
                    dispatch(alertActions.info('El tamaño del archivo no debe exceder los 8MB'));
                }else{
                    dispatch(request({name: 'loading', value : true}))
                    generalPublicService.registroEspecialista(medico, captcha).then(
                        data => {
                            console.log(data)
                            if (data.success) {
                                dispatch(success(data.data));
                                dispatch(alertActions.success(data.message));
                            } else {
                                dispatch(failure({ name: 'loading', value: true }))
                                dispatch(alertActions.info(data.message));
                            }
                        },
                        error => {
                            console.log(error.toString())
                            dispatch(failure({ name: 'loading', value: true }))
                            dispatch(alertActions.error('Error de conexión, contacte a soporte.'));
                        }
                    )
                }
                
            }
        }
    }

    function submit(data) { return { type: generalPublicConstants.CHANGE_IMPUT_NEW_MEDICO, data } }
    function request(data) { return { type: generalPublicConstants.CHANGE_IMPUT_NEW_MEDICO, data } }
    function failure(data) { return { type: generalPublicConstants.CHANGE_IMPUT_NEW_MEDICO, data } }
    function success(data) { return { type: generalPublicConstants.SUCCESS_SUBMIT_NEW_MEDICO, data } }
}

function getchangeInputRegisMedico(data) {
    return { type: generalPublicConstants.CHANGE_IMPUT_NEW_MEDICO, data }
}