import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

/**Components */

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

class Politicas extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        // const { blog } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-blog' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan'>
                            Políticas de privacidad
                        </Typography>
                        <Typography variant='h4' className='slogan _two'>
                            Fecha de última actualización: 13/04/2019
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-preguntas' elevation={0}>
                    <div className='cont-texto'>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Bienvenido a las políticas de privacidad de la plataforma web de CARDIOLINE C.A. 
                            <br/><br/>
                            POR FAVOR LEA CUIDADOSAMENTE
                            <br /><br />
                            CARDIOLINE C.A respeta la privacidad de todas y cada una de las personas y se compromete a proteger su información personal y médica. Esta política de privacidad se aplica a <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, propiedad y operada por CARDIOLINE C.A.
                            <br /><br />
                            Este aviso describe cómo se puede usar su información personal y médica, cómo se puede divulgar y cómo puede obtener acceso a esta información. Esta sección servirá como un resumen de sus derechos de privacidad. Debemos darle esta información sobre nuestras políticas de privacidad y cumplirlas mientras esté en vigencia. Su uso de los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> indica su aceptación de nuestras políticas de privacidad.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            I. INTRODUCCIÓN
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A proporciona una plataforma web de telemedicina para poner al alcance de las personas de una manera interactiva un amplio directorio de especialistas calificados en las diversas áreas de la salud, para que recibir consultas y orientaciones a través de videollamadas y chat en vivo, proporcionando una solución adecuada para resolver problemas médicos y enfermedades comunes desde cualquier lugar y momento. Los profesionales de la salud pueden hacer publicaciones de texto e imágenes con fines informativos, educativos y de promoción de sus servicios en línea para pacientes y otros profesionales de la salud.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            II. INFORMACION QUE PODEMOS SOLICITAR
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Para utilizar los servicios, se le solicita que se registre con una dirección de correo electrónico y una contraseña, la cual será su identificación en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. Después de crear su cuenta, le llegará un correo para validar su dirección de correo, luego podrá usar esos mismos datos para iniciar sesión en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>.
                            <br /><br />
                            Para poder utilizar los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, se le solicitará que complete su perfil. Para ello debe proporcionar información personal como el nombre y apellido, foto, país de residencia, número de teléfono (opcional), fecha de nacimiento y otros datos médicos pertinentes que estarán disponibles para que los comparta con su profesional de la salud.
                            <br /><br />
                            En <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> creamos un registro de la atención y los servicios que recibe. Algunos ejemplos de la información recopilada o creada a través de este proceso son registros médicos electrónicos que pueden ser cargados o creados como resultado de la atención en línea recibida.
                            <br /><br />
                            Para usar los servicios, deberá proporcionar su información de salud personal en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> o a los profesionales de la salud. Estos son ejemplos de los tipos de información de salud personal que recopilamos:
                            <br />
                            <Typography color='secondary' component='p' className='mb-1 mt-05'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Información como el peso, la presión arterial, niveles de glucosa, resultados de laboratorio, medicamentos o tratamientos que recibe o recibió, antecedentes personales, familiares y otra información médica que se considere pertinente para atenderlo adecuadamente.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Información médica que recibió de otros profesionales de la salud, así como información de identificación personal y otra información relacionada con los miembros de la familia que pueden estar utilizando los servicios con su cuenta
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Información demográfica, como edad, educación, género.
                            </Typography>
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            III. CÓMO PODEMOS USAR SU INFORMACIÓN
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A puede recopilar su historia médica principalmente para compartirla con los profesionales de la salud con fines de diagnóstico, tratamiento y operaciones de atención médica. Sin embargo, en circunstancias limitadas, CARDIOLINE C.A puede usar la información para el análisis estadístico y la mejora de los servicios.
                            <br /><br />
                            Usos y divulgaciones de historia médica
                            <br /><br />
                            CARDIOLINE C.A tiene permitido usar y divulgar su información para los fines de (i) Tratamiento, (ii) Pago y (iii) Operaciones de atención médica de la siguiente manera:
                            <br /><br />
                            Para tratamiento. CARDIOLINE C.A puede usar o divulgar su historia médica para facilitar el tratamiento o la prestación de servicios de atención médica a un profesional de salud con fines de consulta para proporcionar tratamiento o seguimiento. CARDIOLINE C.A puede compartir su historia médica con profesionales de la salud, técnicos u otros trabajadores de CARDIOLINE C.A. Por ejemplo, los departamentos pueden compartir su historia médica para planificar su atención. Esto puede incluir tratamientos, exámenes de laboratorio y estudios de imagen. CARDIOLINE C.A podría en algunas circunstancias compartir su información con personas que no pertenezcan a CARDIOLINE C.A, incluidos, entre otros, médicos de cabecera y enfermeras de atención médica a domicilio que lo están tratando o brindan atención de seguimiento.
                            <br /><br />
                            Para pago. CARDIOLINE C.A puede usar y divulgar su información personal a otras personas o empresas que intervienen o ayudan en el proceso de cobro y pago.
                            <br /><br />
                            Para operaciones de atención médica. CARDIOLINE C.A puede usar y divulgar su información para sus operaciones de atención médica. Estos usos y divulgaciones nos ayudan a ejecutar nuestros programas y a garantizar que los pacientes en www.ecardioline.com reciban atención de calidad. Por ejemplo, CARDIOLINE C.A puede usar los registros médicos electrónicos para revisar cómo está siendo tratado para medir el desempeño de su personal y profesionales de la salud.
                            <br /><br />
                            Socios de negocio. CARDIOLINE C.A puede contratar a empresas externas para proporcionar algunos servicios. Cada parte contratada debe celebrar un acuerdo de socio comercial con CARDIOLINE C.A, que requiere que dichos negocios de terceros protejan la información que se comparte con ellos de acuerdo con las restricciones descritas en esta política de privacidad. Además, la información solo se proporcionará a empresas de terceros para el alcance limitado de la prestación de los servicios requeridos para ayudar a facilitar el tratamiento, el pago y las operaciones de atención médica.
                            <br /><br />
                            Para recordatorios de citas. CARDIOLINE C.A puede comunicarse con usted para recordarle su cita para recibir atención médica.
                            <br /><br />
                            Alternativas de tratamiento. CARDIOLINE C.A puede usar y divulgar su información para informarle sobre los diferentes tipos de tratamiento disponibles para usted. CARDIOLINE C.A puede usar y compartir su información para informarle sobre otros beneficios y servicios relacionados con su salud.
                            <br /><br />
                            Personas involucradas en su cuidado. Con su permiso, CARDIOLINE C.A puede compartir su información con un familiar o amigo que lo ayude con su atención médica. Podemos compartir su información con un grupo que ayuda con los esfuerzos en casos de desastre. Hacemos esto para que su familia pueda recibir información sobre su ubicación y condición. Si no está presente o no puede decir que no, podemos usar nuestro juicio para decidir si compartir su información si creemos que es lo mejor para usted.
                            <br /><br />
                            Investigación. En apoyo de las iniciativas de telemedicina y/o telesalud, CARDIOLINE C.A puede usar y divulgar su información para investigación. Solo se usara y divulgará información para investigación si recibimos su consentimiento por escrito.
                            <br /><br />
                            Actividades para recaudar fondos. CARDIOLINE C.A no divulgará su información para actividades de recaudación de fondos sin su autorización por escrito.
                            <br /><br />
                            Según lo exija la ley. CARDIOLINE C.A puede usar y divulgar su información cuando así lo exijan las leyes.
                            <br /><br />
                            Para prevenir una amenaza grave a la salud o seguridad. CARDIOLINE C.A puede usar y divulgar su información para prevenir una amenaza grave para su salud y seguridad y la de otros. CARDIOLINE C.A solo divulgará su información a personas que puedan ayudar a prevenir la amenaza.
                            <br /><br />
                            Testimonios. Mostramos testimonios personales de usuarios satisfechos con los servicios en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> además de otros avales. Con su consentimiento, podemos publicar su testimonio junto con su nombre e imagen de perfil.
                            <br /><br />
                            Militar. Si se encuentra en los servicios militares, CARDIOLINE C.A puede compartir su información según lo exijan las autoridades militares correspondientes.
                            <br /><br />
                            Militar. Si se encuentra en los servicios militares, CARDIOLINE C.A puede compartir su información según lo exijan las autoridades militares correspondientes.
                            <br /><br />
                            Por ejemplo, podemos compartir su información:
                            <br /><br />
                            Para prevenir o controlar enfermedades, lesiones o discapacidades.
                            <br /><br />
                            Reportar nacimientos y defunciones.
                            <br /><br />
                            Denunciar el maltrato o negligencia infantil.
                            <br /><br />
                            Reportar reacciones a medicamentos o problemas con productos.
                            <br /><br />
                            Para informarle sobre el retiro de productos.
                            <br /><br />
                            Para informarle sobre el retiro de productos.
                            <br /><br />
                            Para informar al departamento de gobierno apropiado si CARDIOLINE C.A cree que un paciente ha sido víctima de abuso, negligencia o violencia doméstica. Solo se compartirá esta información cuando sea ordenada o requerida por la ley.
                            <br /><br />
                            Juicios y disputas. Si está en una demanda o disputa, CARDIOLINE C.A puede compartir su información en respuesta a una orden judicial, demanda legal u otro proceso legal.
                            <br /><br />
                            Cumplimiento de la ley. CARDIOLINE puede compartir información si así lo solicita un oficial de la ley en circunstancias limitadas de la siguiente manera:
                            <br /><br />
                            Reportar ciertos tipos de heridas.
                            <br /><br />
                            Para responder a una orden judicial, citación o proceso similar.
                            <br /><br />
                            Para responder a una orden judicial, citación o proceso similar.
                            <br /><br />
                            Los fallecidos. CARDIOLINE C.A puede, en circunstancias limitadas, divulgar su información a médicos forenses, examinadores médicos, directores de funerarias con fines de identificación, determinación de la causa de muerte y cumplimiento de obligaciones relacionadas con fallecidos
                            <br /><br />
                            Seguridad nacional. CARDIOLINE C.A puede compartir, si es necesario, su información con los funcionarios correspondientes por razones de seguridad nacional.
                            <br /><br />
                            
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            IV. CÓMO MANEJAMOS SU SEGURIDAD EN <Link to='/' className='text-primary ml-05' color='primary'>WWW.ECARDIOLINE.COM</Link>
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            La importancia de la seguridad de toda la información personal, que incluye, entre otras, la información personal y médica asociada con usted, es lo que más nos preocupa. En CARDIOLINE C.A nos esforzamos por proporcionar la transmisión segura de su información desde su computadora o dispositivo móvil a nuestros servidores. La información recopilada por nuestro sitio web se almacena en entornos operativos seguros que no están disponibles o no son accesibles al público. Solo aquellos trabajadores que necesitan acceso a su información para realizar su trabajo pueden acceder, cada uno con acuerdos de confidencialidad. Cualquier empleado que viole nuestras políticas de privacidad o seguridad está sujeto a medidas disciplinarias, incluida la posible terminación y el enjuiciamiento civil y/o penal.
                            <br /><br />
                            En CARDIOLINE C.A estamos trabajando para incorporar en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> tecnología HIPPA.Sin usamos varias capas de seguridad de firewall y diferentes grados de encriptación para la información sensible de cada usuario para garantizar el más alto nivel de seguridad.
                            <br /><br />
                            CARDIOLINE C.A es el único propietario de la información recopilada en su sitio y no venderá, compartirá ni cederá esta información a otros. No vendemos listas de clientes, direcciones de correo electrónico, cookies u otros datos sin su autorización por escrito.
                            <br /><br />
                            Cookies y tecnologías de seguimiento
                            <br /><br />
                            Las cookies ayudan a proporcionar información no personal de usted como visitante en línea. Puede utilizarse en la personalización de sus preferencias cuando visite nuestro sitio web. Cuando visita La <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> acepta las cookies automáticamente.
                            <br /><br />
                            Seguridad en nuestro sitio web
                            <br /><br />
                            Interacción segura. Cuando interactúa en nuestro sitio web, toda su información personal y médica, incluida, entre otras, su número de tarjeta de crédito, se transmite a través de Internet mediante la tecnología Secure Socket Layers (SSL). La tecnología SSL hace que su navegador encripte la información ingresada antes de transmitirla a nuestro servidor seguro. La tecnología SSL, un estándar de la industria, está diseñada para evitar que otra persona que no sea un operador de nuestro sitio web capture y vea su información personal. CARDIOLINE C.A también toma las siguientes medidas para proteger su información en línea:
                            <br /><br />
                            Contraseñas. Para brindarle un mayor nivel de seguridad, el acceso en línea a su información está protegido con una contraseña que usted seleccione. Le recomendamos que no revele su contraseña a nadie. CARDIOLINE C.A nunca le pedirá su contraseña en ninguna comunicación (incluida cartas, llamadas telefónicas o mensajes de correo electrónico).
                            <br /><br />
                            No se puede garantizar que la transmisión de datos a través de internet sea 100% segura. Mientras nos esforzamos por proteger su información personal y médica, uso o divulgación no autorizados, CARDIOLINE C.A no puede garantizar la seguridad de ninguna información que nos transmita en nuestro sitio web.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            V. DERECHOS DE PRIVACIDAD
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            La ley nos exige que nos aseguremos de que la información que lo identifica se mantenga privada, le entreguemos esta política de privacidad por nuestros deberes legales y políticas de privacidad con respecto a su información, seguimos y cumplimos estas políticas de privacidad.
                            <br /><br />
                            Sus derechos con respecto a su información
                            <br /><br />
                            Usted tiene los siguientes derechos con respecto a información que CARDIOLINE C.A mantiene sobre usted:
                            <br /><br />
                            Derecho a inspeccionar y recibir copias. Tiene derecho a ver y recibir copias de la información utilizada para tomar decisiones sobre su atención, siempre que envíe su solicitud por escrito. Por lo general, esto incluye registros médicos y de facturación. CARDIOLINE C.A puede denegar su solicitud para ver y/o copiar su información en circunstancias limitadas. Si se rechaza su solicitud, CARDIOLINE C.A le informará el motivo de la denegación y usted tiene derecho a solicitar una revisión de la denegación.
                            <br /><br />
                            Derecho a enmendar. Si cree que la información personal que CARDIOLINE C.A tiene sobre usted es incorrecta o está incompleta, tiene derecho a solicitar una enmienda a su registro. Para solicitar la eliminación de cualquier información personal o solicitar un cambio en su registro, debe hacer su solicitud al siguiente correo direción@ecardioline.com o en la sección “reporte de problemas” desde su cuenta. Si no podemos cumplir con su solicitud, responderemos con una explicación. CARDIOLINE C.A puede, bajo las siguientes circunstancias limitadas:
                            <br /><br />
                            Retendremos su información mientras su cuenta esté activa o según sea necesario para brindarle servicios. Retendremos y utilizaremos su información según sea necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos.
                            <br /><br />
                            Si hace clic en un enlace a un sitio de un tercero, dejará el sitio de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> y se dirigirá al sitio que seleccionó. Debido a que no podemos controlar las actividades de terceros, no podemos aceptar la responsabilidad del uso de su información por parte de dichos terceros, y no podemos garantizar que cumplan con las mismas políticas de privacidad que <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. Le recomendamos que revise las políticas de privacidad de cualquier otro proveedor de servicios al que solicite los servicios. Si visita un sitio web de un tercero que está vinculado a nuestro sitio, debe leer la política de privacidad de ese sitio antes de proporcionar cualquier información personal.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            VI. REVISIONES A ESTE AVISO
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            CARDIOLINE C.A está constantemente innovando e implementando nuevas características y funcionalidades. Como resultado, nuestras prácticas de privacidad pueden cambiar. Podemos revisar esta política de privacidad para reflejar cualquier cambio en nuestras prácticas de privacidad. Publicaremos la actualización de estas políticas en este sitio antes de que el cambio entre en vigencia. La fecha de entrada en vigencia de estas políticas se encuentra en la primera página, en la esquina superior derecha. Si realizamos cambios sustanciales, se lo notificaremos por correo electrónico (enviado a la dirección de correo electrónico especificada en su cuenta) o mediante un aviso en este sitio antes de que el cambio entre en vigencia.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            VII. WIDGETS Y REDES SOCIALES
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Nuestro sitio web incluye funciones de redes sociales. Estas características pueden recopilar su dirección IP, y pueden configurar una cookie para permitir que la característica funcione correctamente. Las funciones y los widgets de las redes sociales son alojados por un tercero o directamente en nuestro sitio. Sus interacciones con estas características se rigen por la política de privacidad de la compañía que las proporciona.
                            <br /><br />
                            Podemos pero no estamos en la obligación de usar los datos profesionales y foto de perfil de los profesionales de la salud en <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link> para promocionar sus servicios en línea.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            VIII. Quejas
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Si cree que se han violado sus derechos de privacidad, puede presentar una queja a dirección@ecardioline.com. Usted no será penalizado por presentar una queja. También puede comunicarse con nosotros para obtener más información sobre sus derechos de privacidad enviándonos un correo electrónico a contacto@ecardioline.com.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2'>
                            {/* <KeyboardArrowDownIcon color='primary' className={`arrow`} fontSize='large' /> */}
                            ACEPTACIÓN
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta active`}>
                            Al utilizar este sitio y los servicios de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>, usted reconoce su aceptación de la política de privacidad de CARDIOLINE C.A y acepta los términos descritos en este documento. Si no está de acuerdo con esta política, no debe usar los servicios de <Link to='/' className='text-primary' color='primary'>www.ecardioline.com</Link>. Se recomienda que lea esta política de privacidad antes de utilizar los servicios para asegurarse de que no se haya perdido ningún cambio en la política de privacidad. Su uso continuado de los servicios después de cualquier cambio en la política de privacidad significa que acepta dichos cambios.
                        </Typography>
                    </div>

                </Paper>

            </section>
        );
    }
}

Politicas.propTypes = {
    classes: PropTypes.object.isRequired,
};



const connectedPoliticas = (withStyles(styles)(Politicas));
export { connectedPoliticas as Politicas }; 