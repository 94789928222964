import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
// import { generalPublicActions } from '../../../../_actions';

/**Components */
import PostLayout from "./postLayout";

/**Styles and Icon */
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from '@material-ui/core';


class ListPost extends Component {
    constructor(props) {
        super(props);

        this.handleUrlify = this.handleUrlify.bind(this)
    }

    handleUrlify(texto) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return texto.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_back">' + url + '</a>';
        })
    }

    render() {
        const { posts } = this.props
        return (
            <Grid container direction="column">
                {posts.length
                    ? posts.map(k=>{
                        let anchoImg = '100%';
                        if (k.files) {
                            if (k.anchoImg < 750) { anchoImg = k.anchoImg }
                        }
                        return <PostLayout
                            post={k}
                            key={k.keyd}
                            anchoImg={anchoImg}
                            handleUrlify={this.handleUrlify}
                        />
                    })
                    : <Grid item xs={12} align='center' className="cont-list-post">
                        <Paper className="paper post">
                            <Typography color='primary'>No se encontraron publicaciones realizadas</Typography>
                            
                        </Paper>
                    </Grid>
                }
            </Grid>
        );
    }
}

ListPost.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { paciente } = state.authReducer;
    return {
        paciente,
    };
}

const connectedListPost = connect(mapStateToProps)(withStyles(styles)(ListPost));
export { connectedListPost as ListPost }; 