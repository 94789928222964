import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Typography, Avatar } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

class ModalidadRespuestaEscrita extends Component {
    render() {
        return (
            <section className='container-public' >
                <Paper className='baners-page baner-modalidad' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan'>
                            Consulta vía respuesta escrita en menos de 24 horas
                        </Typography>
                    </div>
                </Paper>
                <Paper className='max-width-container container-modalidades' elevation={0}>
                    <div className='cont-img-modalidad'>
                        <img alt='respuestaImg' src='https://ecardioline.com/assets_media/bg/bg2.jpg' />
                    </div>
                    <div className='cont-text'>
                        <Typography variant='h4' color='secondary' component='p' className='txt-algn-center mb-2'>Consulta vía texto</Typography>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon/></Avatar>
                            <Typography color='secondary' component='p'>
                                Excelente alternativa para quiénes prefieren escribir todo detalladamente y asegurarse de no dejar nada por fuera.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon/></Avatar>
                            <Typography color='secondary' component='p'>
                                Mejor opción para consultar con especialistas médicos que presentan diferencias horarias con tu lugar de residencia.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon/></Avatar>
                            <Typography color='secondary' component='p'>
                                Respuesta a tu solicitud escrita dentro de las siguientes 24 horas.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon/></Avatar>
                            <Typography color='secondary' component='p'>
                                Servicio con precios que oscilan entre 10 y 15 USD/EUR o su equivalente en <b>bolívares</b> según la tasa de cambio del día.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon/></Avatar>
                            <Typography color='secondary' component='p'>
                                Puedes pagar con PayPal, Zelle, Bizum, Tarjeta de débito/crédito o transferencia bancaria en <b>bolívares</b>.
                            </Typography>
                        </div>
                        <div className="txt-algn-center">
                            <Link to='/registro-paciente'>
                                <Button className='btn-with-br mt-2 mb-1' color='primary'>Consulta ahora</Button>
                            </Link>
                        </div>
                    </div>
                </Paper>
            </section>
        );
    }
}

ModalidadRespuestaEscrita.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome } = state.generalPublicReducer;
    return {
        especialistasHome
    };
}

const connectedModalidadRespuestaEscrita = connect(mapStateToProps)(withStyles(styles)(ModalidadRespuestaEscrita));
export { connectedModalidadRespuestaEscrita as ModalidadRespuestaEscrita }; 