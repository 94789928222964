export const EspecialistasLanding = [
    {
        nick: 1,
        name: 'Isaías',
        surname: 'Curiel Jordán',
        paistrabajo: 'Venezuela',
        name_province: 'Barquisimeto',
        especialidad: '"He logrado expandir mi red de contactos profesionales de una manera increíble. Es una maravilla".',
        ruta_img: 'https://ecardioline.com/assets_media/medicos/6dcd38d77b85e4263e91ce3fb74edf92.jpg',
    },
    {
        nick: 2,
        name: 'Juan Antonio',
        surname: 'Chassaigne Ricciulli',
        paistrabajo: 'España',
        name_province: '',
        especialidad: '"Excelente experiencia en teledermatología utilizando eCARDIOLINE, cómodo y práctico para el médico y el paciente".',
        ruta_img: 'https://ecardioline.com/assets_media/medicos/560f9b8bc6e26b275d3fa0a2a706e8b9.jpg',
    },
    {
        nick: 3,
        name: 'Francisco Guzmán',
        surname: 'Táriba',
        paistrabajo: 'Chile',
        name_province: 'XII Región De Magallanes Y Antarctica Chilena',
        especialidad: '"Gracias a eCardioline por esta oportunidad espectacular. Excelente plataforma!".',
        ruta_img: 'https://ecardioline.com/assets_media/medicos/cf3ca7a1fd3e8308f2a8bd923abb705d.jpg',
    },

];
