import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
import Contactanos from "./contactanos";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography } from '@material-ui/core';


class HomeContacto extends Component {
    // constructor(props) {
    //     super(props);

    // }

    handleChangeInputCotacto= e => {
        const { name, value } = e.target
        this.props.dispatch(generalPublicActions.changeInputContactanos({name, value}))
    }
    handleSubmitContacto = e => {
        e.preventDefault()
        const { contactanos, dispatch } = this.props
        dispatch(generalPublicActions.submitContactanos(contactanos))
    }
    render() {
        const { contactanos  } = this.props
        return (
            <section className='container-public' >
                <Paper className='container-contacto' elevation={0}>
                    <Contactanos
                        contactanos={contactanos}
                        handleSubmitContacto={this.handleSubmitContacto}
                        handleChangeInputCotacto={this.handleChangeInputCotacto}
                    />
                </Paper>
                <Paper className='baners-page baner-contacto' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan slogan-movil txt-shadow'>
                            Salud a tu alcance
                        </Typography>
                    </div>
                </Paper>
            </section>
        );
    }
}

HomeContacto.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { contactanos } = state.generalPublicReducer;
    return {
        contactanos,
    };
}

const connectedHomeContacto = connect(mapStateToProps)(withStyles(styles)(HomeContacto));
export { connectedHomeContacto as HomeContacto }; 