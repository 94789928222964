import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Constants */
// import { EspecialidadesConstans } from "../../../../_constants";

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';


class BannerBuscador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            especialista: '',
        };
    }

    handleChangeInput = e => {
        if (e) {
            e.preventDefault();
            const { name, value } = e.target;
            this.setState({
                [name]: value,
            })
        }
    }

    handleSelectEspecialidad = (e, value) => {
        e.preventDefault();
        const { especialidades } = this.props
        const select = especialidades.filter(k => (k.name === value))
        if (select.length) {
            this.setState({
                especialidad: select[0],
            })
        }
    }

    handleSeachEspecialistas = e => {
        e.preventDefault();
        const { especialidad, especialista } = this.state
        let coords = this.props.refBuscador.current.getBoundingClientRect();
        const moverScroll = (coords.top / 2) + window.pageYOffset;
        this.props.dispatch(generalPublicActions.buscarEspecialistas(especialidad, especialista, moverScroll));
    }

    render() {
        const { especialista } = this.state
        const { preloaderEspecialistasHome, especialidades, path } = this.props
        return (
            <Paper className={`baners-page baner-especialistas ${path === '/' && 'baner-especialistas-home'}`} elevation={0}>
                <div className='contenido-baner'>
                    <Typography variant='h4' className='slogan txt-shadow'>
                        {path === '/nuestros-especialistas' && 'Selecciona una especialidad'}
                        {path === '/' && 'Especialidades Médicas'}
                        
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                id="buscar-especialidad"
                                disableClearable
                                onChange={this.handleSelectEspecialidad}
                                options={especialidades && especialidades.map((option) => option.name)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='especialidad'
                                        required
                                        variant="outlined"
                                        label="Seleccione una aquí"
                                        className='input-especialista'
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',

                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="especialista"
                                variant="outlined"
                                name="especialista"
                                value={especialista}
                                className='input-especialista'
                                onChange={this.handleChangeInput}
                                label="Escribe un nombre, país, o déjalo en blanco "
                            // error={props.contactanos.submit && !props.contactanos.mail_contacto}
                            // helperText={props.contactanos.submit && !props.contactanos.mail_contacto && <span className="lbl-error" >Campo Requerido</span>}
                            />
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Button className='btn-with-br bg-primary' color='primary' disabled={preloaderEspecialistasHome} onClick={this.handleSeachEspecialistas}>Buscar</Button>
                        </Grid>
                        {path === '/' && 
                            <Grid item xs={12} align='center' className='cont-especialidades'>
                                <Typography component='span'>Más de 25 especialidades médicas disponibles.</Typography>
                                {/* {EspecialidadesConstans.map(k => {
                                    return (
                                        <Typography component='span' key={k.id}>{k.nombre}</Typography>
                                    )
                                })} */}
                            </Grid>
                        }
                    </Grid>
                </div>
            </Paper>
        );
    }
}

BannerBuscador.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome, preloaderEspecialistasHome, especialidades } = state.generalPublicReducer;
    return {
        especialistasHome, preloaderEspecialistasHome, especialidades
    };
}

const connectedBannerBuscador = connect(mapStateToProps)(withStyles(styles)(BannerBuscador));
export { connectedBannerBuscador as BannerBuscador }; 