import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
import ListPublicaciones from "./lisPublicaciones";
import ProgressCircular from "../../layout/progress/progressCircular";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Button } from '@material-ui/core';


class HomeBlog extends Component {
    constructor(props) {
        super(props);
        if (props.blog.list.length < 4) {
            
            this.props.dispatch(generalPublicActions.getBlog(0, 'preloader'));
        }
    }

    handleMasPost = e => {
        e.preventDefault();
        const { blog, dispatch } = this.props
        dispatch(generalPublicActions.getBlog(blog.list.length, 'loading'));
    }

    render() {
        const { blog } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-blog' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan fz-slogan slogan-movil'>
                            Blog
                        </Typography>
                        <Typography variant='h4' className='slogan _two'>
                            Artículos en temas de salud redactados por especialistas médicos
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-publicaciones mt-5' elevation={0}>
                    <ListPublicaciones
                        list={blog.list}
                        loading={blog.preloader}
                    />
                    
                    <div className='txt-algn-center'>

                        {blog.list.length  
                            ? blog.loading
                                ? <ProgressCircular />
                                : <Button className='btn-with-br mb-1' color='primary' onClick={this.handleMasPost}>Ver más</Button>
                            
                            : ''
                        }
                    </div>
                </Paper>

            </section>
        );
    }
}

HomeBlog.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { blog } = state.generalPublicReducer;
    return {
        blog,
    };
}

const connectedHomeBlog = connect(mapStateToProps)(withStyles(styles)(HomeBlog));
export { connectedHomeBlog as HomeBlog }; 