import React from 'react'

/* Components */
import Calendar from "../../../calendario/calendarioComponent";

/* MAterial UI */
import { Grid, Paper, Divider, Typography } from '@material-ui/core';

const Calendario = (props) => (
    <Grid item xs={12}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color='primary' component="span" className="mb-06">Horario de atención</Typography>
                <Divider />
            </div>
            <div>
                <Calendar
                    list={props.horario}
                />
            </div>
        </Paper>
    </Grid>
)


export default Calendario;