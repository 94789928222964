import React from "react";
import { Link } from 'react-router-dom'

/**Constants */
import { apiUrl } from "../../../../_constants";

/**Components */
import ProgressCircular from "../../layout/progress/progressCircular";


import { Grid, Avatar, Typography, Button, Link as Enlace } from '@material-ui/core';

const ListEspecialistas = (props) => (
    <Grid className='cont-item-especialistas mt-2 mb-2' container spacing={2} ref={props.refBuscador}>
        {props.loading
            ? <ProgressCircular/>
            : props.list.map(k => {
                return(
                    <Grid key={k.nick} item xs={12} sm={6} md={4} className='card-item'>
                        <div className='body-card'>

                            {k.ruta_img 
                                ? <Avatar alt="Foto especialista" className='img-especialista' src={`${k.ruta_img}`} />
                                : <Avatar alt="Foto especialista" className='img-especialista' src={`${apiUrl.urlMedia}${k.foto}`} />
                            }
                            <div className='card-text'>

                                <Typography variant='h5' color='secondary' component='p' className='fw-bold truncate-text'>{k.name} {k.surname}</Typography>
                                <Typography variant='h6' color='secondary' component='p' className='mb-2 fw-400'>
                                    {k.name_province}{k.name_province !== '' && ','} {k.paistrabajo}
                                </Typography>
                                <Typography color='secondary' component='p' className='mb-2'>{k.especialidad}</Typography>
                            </div>

                            {!k.ruta_img &&
                                <Grid container spacing={2} justify="center" alignItems='center' className='mb-1'>
                                    <Grid item >
                                        <Enlace href={`https://portalpaciente.ecardioline.com/solicitar-consulta/${k.nick}`}>
                                            <Button fullWidth className='btn-with-br' color='primary'>Solicitar consulta</Button>
                                        </Enlace>
                                    </Grid>
                                    <Grid item >
                                        <Link to={`/epage/${k.nick}`}>
                                            <Button fullWidth className='btn-with-br' color='primary'>Ver perfil</Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    </Grid>

                )
            }) 
        }
    </Grid>
)

export default ListEspecialistas