import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
// import { generalPublicActions } from '../../../../_actions';

/**Constants */
import { UrlIcon } from "../../../../_constants";

/**Components */
// import InputForm from "./inputForm";
// import InfoLanding from "./infoLanding";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from "@material-ui/core/styles";
import { Divider, Grid, Paper, Typography, Avatar, Button, Link } from '@material-ui/core';


class HomeServicios extends Component {
    // constructor(props) {
    //     super(props);

    // }
    render() {
        // const { classes } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-servicios' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan fz-slogan slogan-movil txt-shadow'>
                            Servicios
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-servicios mt-5' elevation={0}>
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='txt-algn-center mb-1'>Consulta médica online</Typography>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className='cont-texto'>
                                <Typography variant='h4' color='secondary' component='p' className='txt-algn-center mb-1'>Modalidad vía texto</Typography>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Excelente alternativa para quienes prefieren escribir todo detalladamente y asegurarse de no dejar nada por fuera.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Mejor opción para consultar con especialistas médicos que presentan diferencias horarias con tu lugar de residencia.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Respuesta a tu solicitud escrita dentro de las siguientes 24 horas.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Servicio con precios que oscilan entre 10 y 15 USD/EUR o su equivalente en <b>bolívares</b> según la tasa de cambio del día.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Puedes pagar con PayPal, Zelle, Bizum, Tarjeta de débito/crédito o transferencia bancaria en <b>bolívares</b>.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Link href='https://portalpaciente.ecardioline.com/' className='m-a'>
                                        <Button className='btn-with-br bg-primary mb-2' color='primary'>Consulta</Button>
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='cont-texto'>
                                <Typography variant='h4' color='secondary' component='p' className='txt-algn-center mb-1'>Modalidad videollamada</Typography>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Excelente alternativa para quienes prefieren que el contacto con el especialista sea lo más cercano al contacto presencial.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Opción para agendar una cita de acuerdo al horario del especialista que se ajuste a tu disponibilidad.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Videollamada efectuada desde nuestra plataforma sin necesidad de otras aplicaciones.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Servicio con precios que oscilan entre 15 y 20 USD/EUR o su equivalente en <b>bolívares</b> según la tasa de cambio del día.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                                    <Typography color='secondary' component='p'>
                                        Puedes pagar con PayPal, Zelle, Bizum, Tarjeta de débito/crédito o transferencia bancaria en <b>bolívares</b>.
                                    </Typography>
                                </div>
                                <div className='texto-item'>
                                    <Link href='https://portalpaciente.ecardioline.com/' className='m-a'>
                                        <Button className='btn-with-br bg-primary mb-2' color='primary'>Consulta</Button>
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} align='center' className='cont-texto mb-2 mt-2'>
                        <Grid item xs={12} className='mb-2'>
                            <Typography variant='h4' color='secondary' component='p'>Beneficios</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_medico} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Recibes atención de especialistas médicos venezolanos calificados.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_virus} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Evitas exponerte al SARS-CoV2 al no trasladarte a centros de salud.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_tiempo} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Cuentas con acceso desde cualquier lugar y momento.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_atencion} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Ahorras tiempo de traslado y espera en centros médicos.
                            </Typography>
                        </Grid>
                    </Grid>

                    <div className='cont-img-and-text mt-2 txt-algn-center'>
                        <Link href='https://portalpaciente.ecardioline.com/' className='m-a'>
                            <Button className='btn-with-br bg-primary mb-2' color='primary'>Consulta ahora</Button>
                        </Link>
                        <Divider/>
                        <div className='img-cont'>
                            <img alt='Recibe tu consulta' src='https://ecardioline.com/assets_media/bg/bg7.jpg' />
                        </div>
                        <Divider/>
                    </div>
                </Paper>

            </section>
        );
    }
}

HomeServicios.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome, preloaderEspecialistasHome } = state.generalPublicReducer;
    return {
        especialistasHome, preloaderEspecialistasHome,
    };
}

const connectedHomeServicios = connect(mapStateToProps)(withStyles(styles)(HomeServicios));
export { connectedHomeServicios as HomeServicios }; 