import React from "react";
import { Link } from 'react-router-dom'

import { Grid, TextField, FormControlLabel, Checkbox, Divider, Button, Typography } from '@material-ui/core';

const InputForm = (props) => (
    <Grid container spacing={4}>
        <Grid item xs={12}>
            <TextField
                required
                fullWidth
                id="nuevo_correo"
                name="nuevo_correo"
                variant="outlined"
                label="Correo electrónico"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                fullWidth
                id="nueva_clave"
                name="nueva_clave"
                variant="outlined"
                label="Contraseña"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                fullWidth
                id="reclave"
                name="reclave"
                variant="outlined"
                label="Repita su contraseña"
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                control={
                    <Checkbox
                        // checked={state.checkedB}
                        // onChange={handleChange}
                        name="aceptar_notificaciones"
                        color="primary"
                    />
                }
                label="Deseo recibir información de salud y noticias de los servicios."
            />
            <Divider/>
        </Grid>
        <Grid item xs={12} align='center'>
            <Button className='btn-with-br mb-1' color='primary'>Confirmar</Button>
            <Typography color='secondary' component='p' className='mt-1'>Si lo deseas regístrate con</Typography>
        </Grid>
        <Grid item xs={6} align='center'>
            <Button className='btn-with-br' color='primary'>Google</Button>
        </Grid>
        <Grid item xs={6} align='center'>
            <Button className='btn-with-br' color='primary'>Facebook</Button>
        </Grid>
        <Grid item xs={12} align='center'>
            <Typography color='secondary' component='p' className='mb-2'>
                Al registrarte, aceptas nuestras 
                <Link to='/politicas'><b className={props.classes.bgColorText}> Políticas de privacidad</b></Link> y 
                <Link to='/condiciones'><b className={props.classes.bgColorText}> Condiciones de Uso.</b></Link>
            </Typography>
            <Divider />
        </Grid>
    </Grid>
)

export default InputForm