import React from "react";

import { TextField } from '@material-ui/core';

const FormLogin = ({ email, password, submittedLogin, handleChangeInputs, handleFocusPassword}) => (
    <div className='body-paper-login'>
        <div className='cont-imput-body-paper-login'>
            <TextField 
                required 
                fullWidth
                id="email" 
                name='email' 
                type="email"
                size="small" 
                label="Email" 
                value={email}
                variant="outlined" 
                onChange={handleChangeInputs}
                error={submittedLogin && !email}
                helperText={submittedLogin && !email &&
                    <span className="lbl-error" >Campo Requerido</span>
                }
            />
        </div>
        <div className='cont-imput-body-paper-login'>
            <TextField
                required
                fullWidth
                size="small"
                id="password"
                name='password'
                type="password"
                value={password}
                label="Contraseña"
                variant="outlined" 
                onBlur={handleFocusPassword}
                onFocus={handleFocusPassword}
                onChange={handleChangeInputs}
                error={submittedLogin && !password}
                helperText={submittedLogin && !password &&
                    <span className="lbl-error" >Campo Requerido</span>
                }
            />
        </div>

    </div>
)

export default FormLogin