import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Typography, Avatar } from '@material-ui/core';


class ModalidadVideoChat extends Component {
    render() {
        return (
            <section className='container-public' >
                <Paper className='baners-page baner-modalidad' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4'  className='slogan'>
                            Consulta vía videochat en vivo
                        </Typography>
                    </div>
                </Paper>
                <Paper className='max-width-container container-modalidades' elevation={0}>
                    <div className='cont-img-modalidad'>
                        <img alt='videoChatImg' src='https://ecardioline.com/assets_media/bg/bg4.jpg' />
                    </div>
                    <div className='cont-text'>
                        <Typography variant='h4' color='secondary' className='txt-algn-center mb-2'>Consulta por Videollamada</Typography>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                            <Typography color='secondary' component='p'>
                                Excelente alternativa para quienes prefieren que el contacto con el especialista sea lo más cercano al contacto presencial.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                            <Typography color='secondary' component='p'>
                                Opción para agendar una cita de acuerdo al horario del especialista que se ajuste a tu disponibilidad.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                            <Typography color='secondary' component='p'>
                                Videollamada efectuada desde nuestra plataforma sin necesidad de otras aplicaciones.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                            <Typography color='secondary' component='p'>
                                Servicio con precios que oscilan entre 15 y 20 USD/EUR o su equivalente en <b>bolívares</b> según la tasa de cambio del día.
                            </Typography>
                        </div>
                        <div className='texto-item'>
                            <Avatar alt="Registrate" className='icon-check'><CheckIcon /></Avatar>
                            <Typography color='secondary' component='p'>
                                Puedes pagar con PayPal, Zelle, Bizum, Tarjeta de débito/crédito o transferencia bancaria en <b>bolívares</b>.
                            </Typography>
                        </div>
                        <div className="txt-algn-center">
                            <Link to='/registro-paciente'>
                                <Button className='btn-with-br mt-2 mb-1' color='primary'>Consulta ahora</Button>
                            </Link>
                        </div>
                    </div>
                </Paper>
            </section>
        );
    }
}

ModalidadVideoChat.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome } = state.generalPublicReducer;
    return {
        especialistasHome
    };
}

const connectedModalidadVideoChat = connect(mapStateToProps)(withStyles(styles)(ModalidadVideoChat));
export { connectedModalidadVideoChat as ModalidadVideoChat }; 