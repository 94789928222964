import { apiUrl } from '../_constants';
import { responseHelper } from "../_helpers";

export const postEpageService = {
    getPostPublicLink,
};

function getPostPublicLink(nickname, postkey) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${apiUrl.urlEPage}getPostPublicLink?nickname=${nickname}&post=${postkey}`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}