import React from "react";

import { TextField } from '@material-ui/core';

const FormCambioPass = ({ password, repassword, submittedLogin, handleChangeInputs }) => (
    <div className='body-paper-login'>
        <div className='cont-imput-body-paper-login'>
            <TextField
                required
                fullWidth
                size="small"
                name='password'
                type="password"
                value={password}
                id="cambio-pass1"
                label="Contraseña"
                variant="outlined"
                onChange={handleChangeInputs}
                error={submittedLogin && !password}
                helperText={submittedLogin && !password &&
                    <span className="lbl-error" >Campo Requerido</span>
                }
            />
        </div>
        <div className='cont-imput-body-paper-login'>
            <TextField
                required
                fullWidth
                size="small"
                type="password"
                name='repassword'
                id="cambio-pass2"
                value={repassword}
                variant="outlined"
                label="Repita su contraseña"
                onChange={handleChangeInputs}
                error={submittedLogin && !password}
                helperText={submittedLogin && !password &&
                    <span className="lbl-error" >Campo Requerido</span>
                }
            />
        </div>
    </div>
)

export default FormCambioPass