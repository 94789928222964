import React from "react";
import { Link } from 'react-router-dom'

/**Helpers */
import { menu } from "../../../../_helpers";

/**Styles And Logo-Icon */
import { List, ListItem, ListItemText,} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';


const ListMenu = ({ classes, user, menuActivo, handlSelectMenu}) => (
    <div className={`list-menu ${classes.bgMenu}`}>
        {user &&
            <List>  
            {
                menu.restaurant.map(k => {
                    return (
                        <Link to={k.ruta} key={k.id} onClick={handlSelectMenu(k.ruta)}>
                            <ListItem button className={`${classes.itemList} ${menuActivo === k.ruta ? classes.menuActive : ''}`}>
                                {/* <Avatar scr={}/> */}
                                {k.icon === 'inicio' && <HomeIcon color='primary' className={`fz-1`} fontSize="small" />}

                                <i className={`${classes.bgIcon} icon-${k.icon}`}></i>
                                <ListItemText primary={k.name} />
                            </ListItem>
                        </Link>
                    )
                })
            }
            </List>
        }
    </div>
)

export default ListMenu