export const authConstants = {
    CLEAR_STATE: 'CLEAR_STATE',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    CHANGE_IMPUT_LOGIN: 'CHANGE_IMPUT_LOGIN',
    SUCCES_UPDATE_PASS: 'SUCCES_UPDATE_PASS',
    SUCCES_SED_RECOVERY_PASS: 'SUCCES_SED_RECOVERY_PASS',


    /**Editar Perfil */
    CHANGE_INPUT_PERFIL: 'CHANGE_INPUT_PERFIL',
    PROGRESO_DE_SUBIDA_IMG: 'PROGRESO_DE_SUBIDA_IMG',
    HABILITAR_EDICION_INFO_BASICA: 'HABILITAR_EDICION_INFO_BASICA',

    SUCCESS_PERFIL_EDIT: 'SUCCESS_PERFIL_EDIT',
    SUCCESS_CHANGE_AVATAR: 'SUCCESS_CHANGE_AVATAR',

};
