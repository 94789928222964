import React from 'react'

/* MAterial UI */
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import DoneIcon from '@material-ui/icons/Done';

const styles = theme => ({
    chip: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(0.8),
    },
    listMonedas: {
        maxHeight: 200,
        width: '100%',
        overflowY: 'auto',
    },
});

const Chips = (props) => (
    <div className={`${props.classes.listMonedas} cont-info`}>
        {props.list &&
            props.list.map(data => {
                let colorchip = "default";
                let icon = null;
                if (data.selected === true) {
                    colorchip = "primary";
                    icon = <DoneIcon />;
                }

                return (
                    <Chip
                        icon={icon}
                        color={colorchip}
                        label={data.name}
                        key={data.id ? data.id : data.keyd}
                        clickable={data.selected ? true : false}
                        onClick={props.handleSelect && props.handleSelect(data)}
                        className={`${props.classes.chip} ${!data.selected ? 'text-primary' : ''} `}
                    />
                );
            }
        )}
    </div>
)


export default withStyles(styles)(Chips);