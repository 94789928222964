import React from "react";
import { Link } from 'react-router-dom'

/**Styles And Logo-Icon */
import AccountCircle from '@material-ui/icons/AccountCircle';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

const AppMenu = (props) => (
    <div>
        <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={props.handleProfileMenuOpen}
            color="inherit"
        >
            <AccountCircle />
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.isMenuOpen}
            onClose={props.handleMenuClose}
        >
            <Link to='/perfil' onClick={props.handlSelectMenu("")}>
                <MenuItem onClick={props.handleMenuClose}>
                    Ajustes
                </MenuItem>
            </Link>
            <MenuItem onClick={props.handleLogout}>Cerrar sesión</MenuItem>
        </Menu>
    </div>
)

export default AppMenu