import React from 'react'

/* Components */
// import MenuSeguidorContacto from "./menuSeguidorContacto";
import Chips from "../../../chips/chips";

/* MAterial UI */
import { Grid, Paper, Divider, Typography} from '@material-ui/core';

const Idiomas = (props) => (
    <Grid item xs={12}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color='primary' component="span" className="mb-06">Idiomas que domino</Typography>
                <Divider />
            </div>
            <div>
                <Chips
                    list={props.idiomas}
                />
            </div>
        </Paper>
    </Grid>
)


export default Idiomas;