import { medicoConstants } from '../_constants';

const initialState = {
    epage: {
        preloader: true,
        viewOnPage: 1,
    },
};

export function medicoReducer(state = initialState, action) {
    switch (action.type) {
        //REQUEST
        case medicoConstants.REQUEST_GET_EPAGE:
            return Object.assign({}, state, {
                epage: {
                    ...state.epage,
                    [action.data.reducer]: action.data.status,
                },
            });
        //SUCCES
        case medicoConstants.SUCCESS_GET_EPAGE:
            return Object.assign({}, state, {
                epage: {
                    ...state.epage,
                    ...action.data,
                    preloader: false,
                },
            });
        //OTROS
        case medicoConstants.TOGLE_UPDATE_VIEW_EPAGE:
            return Object.assign({}, state, {
                epage: {
                    ...state.epage,
                    viewOnPage: action.data,
                },
            });
        default:
            return state
    }
}