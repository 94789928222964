import React from "react";

/*Componet */
import ListMenu from "./listMenu";

/**Styles And Logo-Icon */
import { CssBaseline, Hidden, Drawer } from '@material-ui/core';

const Menu = ({ classes, user, isOpen, menuActivo, handleDrawerToggle, handlSelectMenu, }) => (
    <div className='main-desktop'>
        <CssBaseline />
        <nav>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={isOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: 'drawerPaper'
                    }}
                >
                    <ListMenu 
                        user={user}
                        classes={classes}
                        menuActivo={menuActivo}
                        handlSelectMenu={handlSelectMenu}
                    />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{
                        paper: 'drawerPaper'
                    }}
                    variant="permanent"
                    open
                >
                    <ListMenu 
                        user={user}
                        classes={classes}
                        menuActivo={menuActivo}
                        handlSelectMenu={handlSelectMenu}
                    />
                </Drawer>
            </Hidden>
        </nav>
    </div>
)

export default Menu