import React from "react";

/**Constants */
import { UrlIcon } from "../../../../_constants";

import { Avatar, Grid, Paper, Typography, Button, Link } from '@material-ui/core';

const ComoTeAyudamos = () => (
    <Paper className='max-width-container mt-2 como-funciona' elevation={0}>
        <Grid item xs={12} align='center'>
            <Typography variant='h4' color='secondary' component='p' className='mb-2'>¿En qué situaciones te podemos ayudar?</Typography>
        </Grid>
        <Grid className='cont-item-como-funciona mt-2 mb-2' container spacing={2} align='center'>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-funciona' src={UrlIcon.icon_orientacion} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Orientación</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Aclara todas tus dudas relacionadas al proceso de una situación de salud o al cuidado de un paciente.</Typography>
                </div>
                <Link href='https://portalpaciente.ecardioline.com/'>
                    <Button className='btn-with-br bg-primary mb-2' color='primary'>Consulta</Button>
                </Link>
            </Grid>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Seleccion" className='icon-como-funciona' src={UrlIcon.icon_prevencion} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Prevención y control</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Adquiere hábitos saludables nutricionales, psicológicos y chequea tu bienestar actual.</Typography>
                </div>
                <Link href='https://portalpaciente.ecardioline.com/'>
                    <Button className='btn-with-br bg-primary mb-2' color='primary'>Consulta</Button>
                </Link>
            </Grid>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Video llamada" className='icon-como-funciona' src={UrlIcon.icon_segunda_opinion} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Segunda opinión</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Avanza con certeza en el diagnóstico y/o plan de acción médica indicado. Contrasta aquí con otro profesional. </Typography>
                </div>
                <Link href='https://portalpaciente.ecardioline.com/'>
                    <Button className='btn-with-br bg-primary mb-2' color='primary'>Consulta</Button>
                </Link>
            </Grid>
        </Grid>
    </Paper>
)

export default ComoTeAyudamos