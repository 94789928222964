import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

/**Actions */
import { authActions, menuActions } from '../../../../_actions';

/**Component */
import Menu from "./menu";
import AppMenu from "./appMenu";
// import {HomeQR} from "../qr/homeQR";

/**Styles And Logo-Icon */
import './nav.scss';
import { styles } from "../../../AppStyle";
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Typography} from '@material-ui/core';


class NavLayout extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleLogout = e => {
        e.preventDefault()
        this.props.dispatch(authActions.logout());
    }

    handlSelectMenu = data => () => {
        const { dispatch } = this.props;
        dispatch(menuActions.selectItemMenu(data));
    }

    handleDrawerToggle = e => {
        e.preventDefault()
        const { isOpen } = this.props;
        const { dispatch } = this.props;
        dispatch(menuActions.isOpen(!isOpen));
    };

    /**Menu desplegable */
    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };
    /**Menu desplegable */

    render() {
        const { anchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const { classes, admin, isOpen, menuActivo } = this.props
        return (
            <div className='cont-header'>
            hola
                <AppBar className="appbar">
                    <Toolbar className='toolbar'>
                        <IconButton edge="start" color="inherit" aria-label="menu" className='icon-menu' onClick={this.handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        <div className='info-nav'>
                            <Typography variant='h6' ><b>{admin.nombre} {admin.apellido}</b></Typography>
                        </div>
                        <div>
                            <AppMenu
                                anchorEl={anchorEl}
                                isMenuOpen={isMenuOpen}
                                handleLogout={this.handleLogout}
                                handleMenuClose={this.handleMenuClose}
                                handlSelectMenu={this.handlSelectMenu}
                                handleProfileMenuOpen={this.handleProfileMenuOpen}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <Menu
                    user={admin}
                    isOpen={isOpen}
                    classes={classes}
                    menuActivo={menuActivo}
                    handlSelectMenu={this.handlSelectMenu}
                    handleDrawerToggle={this.handleDrawerToggle}
                />
                
            </div>
        )
    }

}

NavLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { paciente } = state.authReducer;
    const { isOpen, menuActivo } = state.menuReducer;
    return {
        paciente,
        isOpen, menuActivo
    };
}

const connectedNavLayout = connect(mapStateToProps)(withStyles(styles)(NavLayout));
export { connectedNavLayout as NavLayout }; 