import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Helpers */
import { history } from '../../../../../_helpers';

/**Actions */
import { medicoActions } from '../../../../../_actions';

/**Components */
import BarActionMovil from "./barActionMovil";
import BarActionDesktop from "./barActionDesktop";

/**Styles and Icon */
import { styles } from "../../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Hidden } from '@material-ui/core';


class HomePortada extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMasExtracto: false,
        }
    }
    
    handleVerMasExtracto = data => e => {
        e.preventDefault();
        this.setState({
            viewMasExtracto: !data
        })
    }

    handleUpdateViewEpage = data => e => {
        e.preventDefault();
        const { paciente, dispatch } = this.props
        if (paciente) {
            dispatch(medicoActions.togleViewEpage(data));
            
        }else{
            history.push('/login')
        }
    }

    render() {
        const { viewMasExtracto } = this.state
        const { infoMed } = this.props
        return (
            <Paper className='portada' elevation={0}>
                <div className="cont-foto-portada" style={{ backgroundImage: `url(${infoMed.img_portada})` }} />

                <Hidden mdUp implementation="css">
                    <BarActionMovil
                        nick={infoMed.username}
                        foto_perfil={infoMed.foto}
                        post={infoMed.cantidadPost}
                        extracto={infoMed.extracto}
                        siguiendo={infoMed.siguiendo}
                        fech_regis={infoMed.fech_reg}
                        seguidores={infoMed.seguidores}
                        viewMasExtracto={viewMasExtracto}
                        especialidades={infoMed.especialidades}
                        recomendaciones={infoMed.recomendaciones}
                        handleVerMasExtracto={this.handleVerMasExtracto}
                        handleUpdateViewEpage={this.handleUpdateViewEpage}
                        nameEspecialista={`${infoMed.name} ${infoMed.surname}`}
                    />
                </Hidden>

                <Hidden smDown implementation="css">
                    <BarActionDesktop
                        nick={infoMed.username}
                        foto_perfil={infoMed.foto}
                        post={infoMed.cantidadPost}
                        siguiendo={infoMed.siguiendo}
                        seguidores={infoMed.seguidores}
                        recomendaciones={infoMed.recomendaciones}
                        handleUpdateViewEpage={this.handleUpdateViewEpage}
                    />
                </Hidden>
            </Paper>
        );
    }
}

HomePortada.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { paciente } = state.authReducer;
    return {
        paciente,
    };
}

const connectedHomePortada = connect(mapStateToProps)(withStyles(styles)(HomePortada));
export { connectedHomePortada as HomePortada }; 

// export default withStyles(styles)(HomePortada);