import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
// import { generalPublicActions } from '../../../../_actions';

/**Components */
import InputForm from "./inputForm";
import InfoLanding from "./infoLanding";

/**Styles and Icon */
import '../../public.scss';
import { styles } from "../../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from '@material-ui/core';


class HomeLandingPaciente extends Component {
    // constructor(props) {
    //     super(props);

    // }
    render() {
        const { classes } = this.props
        return (
            <section className='container-public' >

                <Paper className='max-width-container mt-2 como-funciona' elevation={0}>
                    <Grid className='landing-page mt-2 mb-2' container spacing={2}>
                        <InfoLanding/>
                        
                        <Grid item xs={12} sm={5}>
                            <Typography variant='h4' align='center' color='secondary' component='p' className='mb-2'>Regístrate gratis.</Typography>
                            <InputForm
                                classes={classes}
                            />
                        </Grid>
                    </Grid>
                </Paper>

            </section>
        );
    }
}

HomeLandingPaciente.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome, preloaderEspecialistasHome } = state.generalPublicReducer;
    return {
        especialistasHome, preloaderEspecialistasHome,
    };
}

const connectedHomeLandingPaciente = connect(mapStateToProps)(withStyles(styles)(HomeLandingPaciente));
export { connectedHomeLandingPaciente as HomeLandingPaciente }; 