import { generalPublicConstants } from '../_constants';

const initialState = {
    // Home
    especialistasHome: [],
    preloaderEspecialistasHome: false,
    //Analitica
    analitica:{
        medicos: 0,
    },
    //Especialidades
    especialidades:[],
    //Contactanos
    contactanos:{
        asunto:'',
        submit: false,
        loading: false,
        mail_contacto:'',
    },
    //Publicaciones
    publicaciones:{
        list: [],
        preloader: true,
    },
    blog:{
        list: [],
        loading: false,
        preloader: false,
    },
    postPublic:{
        postInfo: {},
        infoMedico: {},
        preloader: false,
    },
    //Registro Especialista
    newMedico: {
        nombre: '',
        correo: '',
        file: {},
        submit: false,
        loading: false,
    }
};

export function generalPublicReducer(state = initialState, action) {
    switch (action.type) {
        //REQUEST
        case generalPublicConstants.REQUEST_GENERAL_PUBLIC:
            return Object.assign({}, state, {
                [action.data.reducer]: action.data.status,
            });
        case generalPublicConstants.REQUEST_PUBLICACIONES:
            return Object.assign({}, state, {
                publicaciones: {
                    ...state.publicaciones,
                    [action.data.reducer] : action.data.status
                },
            });
        case generalPublicConstants.REQUEST_BLOG:
            return Object.assign({}, state, {
                blog: {
                    ...state.blog,
                    [action.data.reducer] : action.data.status
                },
            });
        case generalPublicConstants.REQUEST_POST_PUBLIC:
            return Object.assign({}, state, {
                postPublic: {
                    ...state.postPublic,
                    [action.data.reducer] : action.data.status
                },
            });
        //SUCCESS
        case generalPublicConstants.SUCCESS_GET_ESPECIALISTAS:
            return Object.assign({}, state, {
                especialistasHome: action.data,
                preloaderEspecialistasHome: false,
            });
        case generalPublicConstants.SUCCESS_GET_ANALITICA:
            return Object.assign({}, state, {
                analitica: action.data,
            });
        case generalPublicConstants.SUCCESS_GET_ESPECIALIDADES:
            return Object.assign({}, state, {
                especialidades: action.data,
            });
        case generalPublicConstants.SUCCESS_SUBMIT_CONTACTANOS:
            return Object.assign({}, state, {
                contactanos: {
                    asunto: '',
                    submit: false,
                    loading: false,
                    mail_contacto: '',
                }
            });
        case generalPublicConstants.SUCCESS_GET_PUBLICACIONES:
            return Object.assign({}, state, {
                publicaciones: {
                    list: action.data,
                    preloader: false,
                }
            });
        case generalPublicConstants.SUCCESS_GET_BLOG:
            return Object.assign({}, state, {
                blog: {
                    loading: false,
                    preloader: false,
                    list: state.blog.list.length ? state.blog.list.concat(action.data) : action.data,
                }
            });
        case generalPublicConstants.SUCCESS_GET_POST_PUBLIC:
            return Object.assign({}, state, {
                postPublic: {
                    preloader: false,
                    postInfo: action.data.postInfo,
                    infoMedico: action.data.infoMedico,
                }
            });
        case generalPublicConstants.SUCCESS_SUBMIT_NEW_MEDICO:
            return Object.assign({}, state, {
                newMedico: {
                    file: {},
                    nombre: '',
                    correo: '',
                    submit: false,
                    loading: false,
                }
            });
        //OTROS
        case generalPublicConstants.CHANGE_IMPUT_CONTACTO:
            return Object.assign({}, state, {
                contactanos:{
                    ...state.contactanos,
                    [action.data.name] : action.data.value
                }
            });
        case generalPublicConstants.CHANGE_IMPUT_NEW_MEDICO:
            return Object.assign({}, state, {
                newMedico:{
                    ...state.newMedico,
                    [action.data.name] : action.data.value
                }
            });
        default:
            return state
    }
}