import React from "react";

import { Divider, Grid, Paper, Typography } from '@material-ui/core';

const testimoniosText = [
    {
        text: '"Me pareció fácil y sencillo, la pueden usar incluso personas que no  tengan ningún tipo de conocimientos sobre el internet"',
        autor: '- A.A (España)',
    },
    {
        text: '"El servicio es de alta calidad. Respuestas rápidas. A pesar de ser online se siente la cercanía con el médico. Trato humano y personalizado."',
        autor: '- E.A (Suecia) ',
    },
    {
        text: '"Me encanta. Pude consultar con un médico sin tener que trasladarme ni perder tiempo esperando y lo mejor es que tuve una excelente atención."',
        autor: '- E.I (Aruba) ',
    },
]

const datosRandon = testimoniosText[Math.floor(Math.random() * testimoniosText.length)];

const Testimonios = () => (
    <Paper className='max-width-container mt-2 testimonios' elevation={0}>
        <Grid container className='pt-2'>
            <Grid item xs={12} align='center'>
                <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>Testimonios</Typography>
            </Grid>
            <Grid item xs={12} align='center'>
                <div className='cont-text-testimonio'>
                    <Divider className='bg-blanco' />
                    <Typography variant='h6' color='primary' className='mt-2 mb-1'>
                        {datosRandon.text}
                    </Typography>
                    <Typography variant='h6' color='secondary' className='text-gris fw-300 mb-2'>
                        {datosRandon.autor}
                    </Typography>
                    <Divider className='bg-blanco mb-2' />
                </div>
           </Grid>
        </Grid>
    </Paper>
)

export default Testimonios