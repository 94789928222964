import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
import {HomePortada}  from "../../layout/epage/portada/homePortada";
import HomeBarleft  from "../../layout/epage/barLeft/homeBarleft";
import PostLayout from "../../layout/post/postLayout";
import ProgressCircular from "../../layout/progress/progressCircular";


/**Styles and Icon */
import '../../layout/epage/epage.scss';
import '../../layout/post/post.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';


class HomePostUnico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keydPost: this.props.match.params.keydPost,
            nickname: this.props.match.params.nickname
        }
        this.handleUrlify = this.handleUrlify.bind(this)
        this.props.dispatch(generalPublicActions.getPostPublicLink(this.props.match.params.nickname, this.props.match.params.keydPost));
    }

    handleMasPost = e => {
        e.preventDefault();
        // const { blog, dispatch } = this.props
        // dispatch(generalPublicActions.getBlog(blog.list.length, 'loading'));
    }

    handleUrlify(texto) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return texto.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_back">' + url + '</a>';
        })
    }

    render() {
        const { postPublic } = this.props
        let anchoImg = '100%';
        if (postPublic.postInfo.files) {
            if (postPublic.postInfo.anchoImg < 750) { anchoImg = postPublic.postInfo.anchoImg }
        }
        return (
            <section className='container-epage' >
                <HomePortada
                    infoMed={postPublic.infoMedico}
                />

                <div className='body-epage'>

                    <HomeBarleft
                        infoMed={postPublic.infoMedico}
                    />

                    <div className='cont-page'>
                        <Grid container direction="column">
                            {postPublic.preloader
                                ? <ProgressCircular/>
                                : postPublic.postInfo.autor 
                                    ? <PostLayout
                                        anchoImg={anchoImg}
                                        post={postPublic.postInfo}
                                        handleUrlify={this.handleUrlify}
                                    />
                                    : '' 
                                
                            }
                            
                        </Grid>
                    </div>
                </div>
            </section>
        );
    }
}

HomePostUnico.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { postPublic } = state.generalPublicReducer;
    return {
        postPublic,
    };
}

const connectedHomePostUnico = connect(mapStateToProps)(withStyles(styles)(HomePostUnico));
export { connectedHomePostUnico as HomePostUnico }; 