import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';

/**Constant */
import { UrlIcon } from '../../../../_constants';

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
// import InputForm from "./inputForm";
// import InfoLanding from "./infoLanding";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
// import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Grid, Avatar, Button, Paper, Typography, Link as Enlace, Divider } from '@material-ui/core';


class HomeConocenos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeCount: false,
        };
        this.props.dispatch(generalPublicActions.getAnalitica());

        window.addEventListener('scroll', this.handleScrollView);
    }
    handleScrollView = (e) => {
        if (Math.round(document.documentElement.scrollTop) > 100) {
            this.setState({ activeCount: true })
        }
    }
    render() {
        const { activeCount } = this.state
        const { analitica } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-conocenos' elevation={0}>
                    <div className='contenido-baner mb-2'>
                        <Typography variant='h4' className='slogan fz-slogan slogan-movil'>
                            ¿Quiénes somos?
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-conoenos' elevation={0}>
                    <div className='cont-img-conocenos'>
                        <img alt='Conocenos' src='https://ecardioline.com/assets_media/bg/bg17.jpg' />
                    </div>

                    <div className='text-conocenos mt-1'>
                        <Typography variant='h4' color='secondary' component='p' className='mb-1'>Nosotros</Typography>
                        <Typography color='secondary' component='p' className='mb-2'>
                            Somos un equipo de profesionales venezolanos que cada día unimos esfuerzos por conectar a médicos y pacientes, también venezolanos, sin importar en qué lugar del mundo se encuentren.
                        </Typography>
                    </div>
                    
                    <Divider className='mb-2' />
                </Paper>

                <Paper className='max-width-container container-conoenos' elevation={0}>
                    <Grid container spacing={4}>
                        {/* <Grid item xs={12} sm={6}>
                            <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>Nosotros</Typography>
                            <Typography color='secondary' component='p' className='mb-2'>
                                Somos un equipo de profesionales venezolanos que cada día unimos esfuerzos por conectar a médicos y pacientes, también venezolanos, sin importar en qué lugar del mundo se encuentren.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='cont-img-conocenos'>
                                <img alt='Conocenos' src='https://ecardioline.com/assets_media/bg/bg8.jpg' />
                            </div>
                        </Grid> */}

                        <Grid item xs={12} sm={6}>
                            <div className='testimonio'>
                                <Avatar className='icon-woman' src={UrlIcon.icon_women}/>
                                <Typography color='secondary' component='p' className='mb-1 fs-italic'>
                                    “El servicio es de alta calidad. Las respuestas son rápidas y a pesar de ser online se siente la cercanía con el médico. Se siente un trato humano y personalizado”.
                                </Typography>
                                <Typography color='secondary' component='p' className=''>
                                    - E.A (Venezolana en Suecia)
                                </Typography>
                            </div>
      
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>Nuestros pacientes</Typography>
                            <Typography color='secondary' component='p' className='mb-2'>
                                Son el pilar fundamental de la plataforma a quienes les proveemos la tecnología y el <Link to='/servicios' className='text-primary underline'>servicio</Link> que merecen para que sigan solucionando su situación de salud con la misma atención y calor humano que caracteriza al especialista médico venezolano.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={16}>
                            <Typography variant='h4' color='secondary' component='p' className=''>Nuestros especialistas</Typography>
                            <Typography color='secondary' component='p' className=''>
                                Son <Link to='/nuestros-especialistas' className='text-primary underline'>profesionales</Link> del sector salud de origen venezolano y con altos estándares de formación continua y experiencia en diferentes universidades del país y del mundo. Un equipo conformado por más de 25 especialidades.
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <div className='cont-img-conocenos'>
                                <img alt='Conocenos' src='https://ecardioline.com/assets_media/bg/bg8.jpg' />
                            </div>
                        </Grid> */}
                    </Grid>

                </Paper>

                <Paper className='baners-page baner-conocenos' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan fz-slogan slogan-movil mb-2'>
                            Más que una plataforma, somos humanos:
                        </Typography>
                    </div>
                    <div className='txt-fondo-gris'>
                        <div>
                            <Typography color='secondary' component='p' className=''>
                                Comprendemos lo vulnerable que podemos ser en ciertas situaciones de salud, por lo que en eCARDIOLINE trabajamos con profunda entrega y compromiso por brindarles la atención que como pacientes merecen sin importar donde estén.
                            </Typography>
                        </div>
                    </div>
                </Paper>

                <Paper className='max-width-container container-servicios mt-2' elevation={0}>
                    <Grid container spacing={2} align='center' className='cont-texto mb-2'>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_medico} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Atendemos con cercanía y respeto.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_virus} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Tratamos con empatía y cordialidad.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_tiempo} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Resguardamos la confidencialidad de información.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Avatar alt="Registrate" className='icon-beneficios' src={UrlIcon.icon_atencion} />
                            <Typography color='secondary' component='p' className='mt-1'>
                                Cumplimos con los tiempos de respuestas.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                

                <Paper className={`baners-page baner-conocenos baner-con-texto mt-4`} elevation={0}>
                    <Typography className='txt-shadow'  component='p'>
                        Desde eCARDIOLINE solucionamos situaciones de salud mediante la prevención, control, diagnóstico y tratamiento oportuno de nuestros pacientes.
                    </Typography>
                </Paper>

                <Paper className='baners-page baner-conocenos mt-0 pt-0' elevation={0}>
                    <div className='txt-fondo-gris'>
                        <div className='txt-algn-center'>
                            <Typography variant='h4' color='secondary' component='p' className='mb-2 txt-algn-center'>¿También quieres unirte como paciente?</Typography>
                            <Enlace href='https://portalpaciente.ecardioline.com/registro'>
                                <Button className='btn-with-br' color='primary'>Sí, quiero registrarme como paciente</Button>
                            </Enlace>
                        </div>
                    </div>
                </Paper>

                <Paper className='max-width-container mt-5 como-funciona' elevation={0}>
                    <Grid item xs={12} align='center'>
                        <Typography variant='h4' color='secondary' component='p' className='mb-2'>Cada día son más los pacientes y especialistas que confían en nosotros.</Typography>
                    </Grid>
                    <Grid className='cont-item-como-funciona mt-2 mb-2' container spacing={2} align='center'>
                        <Grid item xs={12} sm={4} className='item-funciona'>
                            <Avatar alt="Registrate" className='icon-como-funciona' src='https://ecardioline.com/assets_media/iconos/pacientes.svg' />
                            <Typography variant='h4' color='secondary' component='p' className='mb-2'>
                                {activeCount &&
                                    <CountUp start={0} end={parseInt(analitica.personas)} duration={10.75} />
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className='item-funciona'>
                            <Avatar alt="Seleccion" className='icon-como-funciona' src='https://ecardioline.com/assets_media/iconos/especialistas.svg' />
                            <Typography variant='h4' color='secondary' component='p' className='mb-2'>
                                {activeCount &&
                                    <CountUp start={0} end={parseInt(analitica.medicos)} duration={10.75} />
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className='item-funciona'>
                            <Avatar alt="Video llamada" className='icon-como-funciona' src='https://ecardioline.com/assets_media/iconos/recomendaciones.svg' />
                            <Typography variant='h4' color='secondary' component='p' className='mb-2'>
                                {activeCount &&
                                    <CountUp start={0} end={parseInt(analitica.recomendacion)} duration={10.75} />
                                }
                                %
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </section>
        );
    }
}

HomeConocenos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { analitica } = state.generalPublicReducer;
    return {
        analitica,
    };
}

const connectedHomeConocenos = connect(mapStateToProps)(withStyles(styles)(HomeConocenos));
export { connectedHomeConocenos as HomeConocenos }; 