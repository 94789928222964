import React from "react";

/**Constants */
import { FormasDePago } from "../../../../_constants";

import { Divider, Grid, Paper, Typography, } from '@material-ui/core';


const FormaDePagos = () => (
    <Paper className='max-width-container mt-2 formaDePagos' elevation={0}>
        <Divider />
        <Grid container className='mt-2 pt-2'>
            <Grid item xs={12} align='center'>
                <Typography variant='h4' color='secondary' component='p' className=' mb-2'>Formas de pago</Typography>
                <Typography variant='h6' color='secondary' component='p' className=' mb-2'>Garantizamos la seguridad de sus datos y pagos a través de cualquiera de las siguientes opciones:</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4} align='center'>
                    {FormasDePago.map(k =>{
                        return (
                            <Grid item xs={6} sm={3} key={k.id}>
                                <img src={k.ruta_img} alt={k.nombre}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    </Paper>
)

export default FormaDePagos