import React from "react";

import { TextField, Typography } from '@material-ui/core';

const FormRecuPass = ({ email, message, submittedLogin, handleChangeInputs}) => (
    <div className='body-paper-login'>
        <div className='cont-imput-body-paper-login'>
            <TextField 
                required 
                fullWidth 
                name='email' 
                size="small" 
                type="email"
                label="Email" 
                value={email}
                id="recu-pass" 
                variant="outlined" 
                onChange={handleChangeInputs}
                error={submittedLogin && !email}
                helperText={submittedLogin && !email &&
                    <span className="lbl-error" >Campo Requerido</span>
                }
            />
        </div>
        {message && 
            <div className='cont-imput-body-paper-login message'>
                <Typography component='span' color='secondary'>{message}</Typography>
            </div>
        }
    </div>
)

export default FormRecuPass