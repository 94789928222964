export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    INFO: 'ALERT_INFO',
    CLEAR: 'ALERT_CLEAR',

    SUMITED_SUCCES: 'SUMITED_SUCCES',
    SUMITED_REQUEST: 'SUMITED_REQUEST',
    OPEN_CLOSE_CONFIRM: 'OPEN_CLOSE_CONFIRM',
};
