const bgColorUser = '#6857B2';
export const styles = theme => ({
    buttonBaseImage: {
        top: '2rem',
    },
    uploadAvatar: {
        zIndex: '2',
        opacity: '0',
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'absolute',
        width: 150,
        height: 150,
    },
    avatarPlusIcon: {
        right: -12,
        bottom: -10,
        zIndex: '1',
        position: 'absolute',
        border: '2px solid',
        // backgroundColor: theme.palette.primary.main,
    },
    porcentaje: {
        fontSize: 10,
        fontWeight: 800,
        color: theme.palette.primary.main,
    },

    bgColor: {
        backgroundColor: bgColorUser,
    },
    bgColorText: {
        color: bgColorUser,
    },
    bgColorWhatsapp: {
        backgroundColor: '#1BD741',
    },
    bgColorLighten: {
        border: '2px dashed ' + bgColorUser,
    },
    borderColor: { 
        border: `2px solid ${bgColorUser}`,
    },
    bgIcon:{
        "&:before":{
            color: bgColorUser,
        }
    },
    itemList:{
        '&:hover': {
            color: bgColorUser,
            borderRight: `3px solid ${bgColorUser}`,
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '& span':{
                color: bgColorUser,
            }
        }
    },
    menuActive:{
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRight: `3px solid ${bgColorUser}`,
        '& span': {
            color: bgColorUser,
        }
    },
    tab:{
        border: `2px solid ${bgColorUser}`,
        '&.Mui-selected':{
            backgroundColor: bgColorUser,
        }
    },
    inputFile:{
        display: 'none',
    },


    /*Epage*/
    p_L_R_0: {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: '1px solid #e0e0e0',
    },
    verMas: {
        margin: 0,
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    avatarInfoMed: {
        whiteSpace: 'normal',
    },
    icon: {
        fontSize: 7
    },
    tituloItem: {
        color: '#595955',
        fontSize: '.9rem',
        fontWeight: 'bold',
        [theme.breakpoints.down("xs")]: {
            width: '84%',
        },
        [theme.breakpoints.up("sm")]: {
            width: '90%',
        },
    },
    tituloItemDos: {
        color: '#595955',
        display: 'block',
        fontSize: '.9rem',
    },
    tituloItemTres: {
        color: '#7a7a77',
        display: 'block',
        fontSize: '.75rem',
    },
    btnTransform: {
        padding: '6px 0px',
        textTransform: 'initial !important'
    },
    chip: {
        margin: theme.spacing(.5),
    },
})
