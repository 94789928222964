import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'

import { history } from '../../../../_helpers';

/**Actions */
import { menuActions } from '../../../../_actions';

/**Component */
import MenuList from "./menuDesktop";
import RegistroYSesion from "./registroYSesion";

/**Styles And Logo-Icon */
import './navPublic.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../../assets/media/logo.png';


class MenuPublic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            tipoMenu: '',
            scrollLimit: false,
            urlLogo: this.handleCaseUrl(history.location.pathname)
        };
        // this.handleCaseUrl(history.location.pathname)
        window.addEventListener('scroll', this.handleScrollView);
        history.listen((location, action) => {
            
            this.setState({ urlLogo: this.handleCaseUrl(location.pathname) })
        });
    }
    
    handleScrollView = (e) => {
        if (Math.round(document.documentElement.scrollTop) > 25) {
            this.setState({ scrollLimit: true })
        } else {
            this.setState({ scrollLimit: false })
        }
    }

    handleCaseUrl(pathname){
        if (pathname.slice(0, 6) === '/epage') {
            return '/nuestros-especialistas'
        }
        if (pathname.slice(0, 17) === '/post/ecardioline') {
            return '/blog'
        }
        if (pathname.slice(0, 6) === '/post/') {
            return '/publicaciones'
        }
        return '/'
    }


    handlSelectMenu = data => () => {
        const { dispatch } = this.props;
        dispatch(menuActions.selectItemMenu(data));
    }

    handleDrawerToggle = e => {
        e.preventDefault()
        const { isOpen } = this.props;
        const { dispatch } = this.props;
        dispatch(menuActions.isOpen(!isOpen));
    };

    // /**Menu desplegable */
    handleMenuOpen = data => event => {
        this.setState({ 
            anchorEl: event.currentTarget,
            tipoMenu: data
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            tipoMenu: ''
        });
    };
    /**Menu desplegable */

    render() {
        const { classes, isOpen, menuActivo} = this.props
        const { scrollLimit, anchorEl, tipoMenu, urlLogo } = this.state;
        return (
            <div className={`cont-header-public ${scrollLimit && 'menu-public-fixed'}`}>
                <Hidden smUp>
                    <RegistroYSesion
                        tipoMenu={tipoMenu}
                        anchorEl={anchorEl}
                        handlSelectMenu={this.handlSelectMenu}
                        handleMenuOpen={this.handleMenuOpen}
                        handleMenuClose={this.handleMenuClose}
                     />
                </Hidden>
                <AppBar position="static" elevation={0}>
                    <Toolbar>
                        <article className="logo">
                            <Link to={urlLogo}>
                                <img src={logo} alt="logo" />
                            </Link>
                        </article>

                        {/* <MenuDesktop/> */}
                        <Hidden xsDown>
                            <RegistroYSesion
                                anchorEl={anchorEl}
                                tipoMenu={tipoMenu}
                                handlSelectMenu={this.handlSelectMenu}
                                handleMenuOpen={this.handleMenuOpen}
                                handleMenuClose={this.handleMenuClose}
                            />
                        </Hidden>


                        <div className='icon-menu-movil'>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.handleDrawerToggle}>
                                <MenuIcon color='primary' fontSize="large" />
                            </IconButton>

                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    classes={{
                        paper: 'drawerPaper'
                    }}
                    variant="temporary"
                    open={isOpen}
                    onClose={this.handleDrawerToggle}
                    anchor='right'
                >
                    <MenuList
                        classes={classes}
                        menuActivo={menuActivo}
                        handlSelectMenu={this.handlSelectMenu}
                    />
                </Drawer>
            </div>
        )
    }

}

MenuPublic.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { paciente } = state.authReducer;
    const { isOpen, menuActivo } = state.menuReducer;
    return {
        paciente,
        isOpen, menuActivo
    };
}

const connectedMenuPublic = connect(mapStateToProps)(withStyles(styles)(MenuPublic));
export { connectedMenuPublic as MenuPublic }; 