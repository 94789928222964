import React from "react";

import { Divider, Grid, Typography } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const InfoLanding = () => (
    <Grid item xs={12} sm={7}>
        <Typography variant='h4' color='secondary' component='p' className='mb-2'>Disfruta de la calidad y calidez de nuestros médicos y psicólogos Venezolanos para ayudarte en cualquier lugar y momento.</Typography>
        <Typography variant='h6' color='secondary' component='p' className=' mb-2'>En 3 sencillos pasos:</Typography>

        <Typography variant='h4' color='secondary' component='p' className='mb-2'>
            <Brightness1Icon className='mr-05' /> Crea tu cuenta
        </Typography>
        <Typography color='secondary' component='p' className='mt-1'>
            Con tu dirección de Correo Electrónico, cuenta Google y/o Facebook.
        </Typography>
        <Divider className='mt-2'/>

        <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>
            <Brightness1Icon className='mr-05' /> Selecciona una especialidad
        </Typography>
        <Typography color='secondary' component='p' className='mt-1'>
            Observa el perfil profesional, publicaciones, horario de atención y recomendaciones del especialista de tu preferencia y haz clic en solicitar consulta.
        </Typography>
        <Divider className='mt-2'/>

        <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>
            <Brightness1Icon className='mr-05' /> Consulta con el especialista
        </Typography>
        <Typography color='secondary' component='p' className='mt-1'>
            Para resolver tu problema de salud o enfermedad de una manera fácil, rápida y confiable.
        </Typography>
        <Divider className='mt-2'/>
    </Grid>
)

export default InfoLanding