import React from "react";
import { Link } from 'react-router-dom'

import { Divider, Grid, Paper, Typography, Button } from '@material-ui/core';

const Modalidades = () => (
    <Paper className='max-width-container modalidades' elevation={0}>
        <Grid container>
            <Grid item xs={12} align='center'>
                <Divider className='bg-blanco'/>
                <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>Modalidades de consulta</Typography>
                <Divider className='bg-blanco mb-2'/>
            </Grid>
            <Grid container item xs={12} alignItems='center' spacing={2} className='item-modalidad'>
                <Grid item xs={12} sm={6} className='cont-img'>
                    <img alt='Vía Texto' src='https://ecardioline.com/assets_media/bg/bg2.jpg'/>
                </Grid>
                <Grid item xs={12} sm={6} className='cont-text-left txt-algn-center'>
                    <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>Vía texto</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>El especialista médico responderá a tu caso de forma escrita y en menos de 24 horas.</Typography>
                    <Link to='/respuestaescrita'>
                        <Button className='btn-with-br mb-2' color='primary'>Más información</Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid container item xs={12} alignItems='center' spacing={2} className='item-modalidad'>
                <Grid item xs={12} sm={6} className='cont-img'>
                    <img alt='Vía Texto' src='https://ecardioline.com/assets_media/bg/bg4.jpg'/>
                </Grid>
                <Grid item xs={12} sm={6} className='cont-text-left txt-algn-center'>
                    <Typography variant='h4' color='secondary' component='p' className='mt-2 mb-2'>Videollamada</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>La interacción con el especialista médico será en vivo, permitiéndote una consulta más cercana.</Typography>
                    <Link to='/videochatenvivo'>
                        <Button className='btn-with-br mb-2' color='primary'>Más información</Button>
                    </Link>
                </Grid>
            </Grid>            
        </Grid>
    </Paper>
)

export default Modalidades