import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
import ListPublicaciones from "./lisPublicaciones";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography } from '@material-ui/core';


class HomePublicaciones extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(generalPublicActions.getPublicaciones());
    }
    render() {
        const { publicaciones } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-publicaciones' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan txt-shadow fz-slogan slogan-movil'>
                            Publicaciones
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-publicaciones mt-5' elevation={0}>
                    <ListPublicaciones
                        list={publicaciones.list}
                        loading={publicaciones.preloader}
                    />
                    {/* <Grid className='cont-item-publicacion mt-2 mb-2' container spacing={2}>

                    </Grid> */}
                </Paper>

            </section>
        );
    }
}

HomePublicaciones.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { publicaciones } = state.generalPublicReducer;
    return {
        publicaciones,
    };
}

const connectedHomePublicaciones = connect(mapStateToProps)(withStyles(styles)(HomePublicaciones));
export { connectedHomePublicaciones as HomePublicaciones }; 