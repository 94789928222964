import { apiUrl } from '../_constants';
import { responseHelper } from "../_helpers";

export const generalPublicService = {
    getEspecialistas,
    getAnalitica,
    getEspecialidades,
    getBlog,
    getPublicaciones,
    submitContactanos,
    buscarEspecialistas,
    registroEspecialista,
};

function getEspecialistas(condicion) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };

    return fetch(`${apiUrl.urlMedico}getforThree?cdn=${condicion}`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function getAnalitica() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };

    return fetch(`${apiUrl.urlAnalitica}getAnaliticaHome`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function getEspecialidades() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };

    return fetch(`${apiUrl.urlMedico}getEspecialidades`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function getBlog(offset) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };

    return fetch(`${apiUrl.urlPost}getPostBlog?offset=${offset}`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function getPublicaciones() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${apiUrl.urlPost}getPostFavoritosComercial?offset=0`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function submitContactanos(contactanos) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contactanos)
    };

    return fetch(`${apiUrl.urlGeneral}contactanos`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function buscarEspecialistas(especialidad, especialista) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            especialidad: especialidad.id,
            text: especialista
        })
    };

    return fetch(`${apiUrl.urlMedico}searchForSpecialty`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}

function registroEspecialista(medico, capchat) {
    const formData = new FormData();
    formData.append('document', medico.file);
    formData.append('g-recaptcha-response', capchat);
    formData.append('nombre_especialisa', medico.nombre);
    formData.append('correo_especialista', medico.correo);

    const requestOptions = {
        method: 'POST',
        // headers: { 'token': JSON.parse(localStorage.getItem('user')).token },
        body: formData,
    };
    return fetch(`${apiUrl.urlMedico}saveSpecialist`, requestOptions)
        .then(responseHelper.handleResponse)
        .then(data => {
            return data;
        });
}