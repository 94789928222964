import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Constants */
import { Bread } from "../../../../_constants/";

/**Actions */
// import { menuActions, generalActions, pedidosActions } from '../../../../_actions';

/**Components */
// import Contador from "./contador"
import Migas from "../../layout/migas/migas";
// import ProgressCircular from "../layout/progress/progressCircular";
// import { ListPedidos } from "../pedidos/listPedidos";

/**Styles and Icon */
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography } from '@material-ui/core';

class HomeDashBoard extends Component {
    // constructor(props) {
    //     super(props);

    // }
    render() {
        // const { classes} = this.props
        return (
            <section className='container-private'>
                <Migas
                    bread={Bread.dashboard}
                />
                <Paper className='paper'>
                    <Typography>Home Dashboard</Typography>
                </Paper>


            </section>
        );
    }
}

HomeDashBoard.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { admin } = state.authReducer;
    return {
        admin,
    };
}

const connectedHomeDashBoard = connect(mapStateToProps)(withStyles(styles)(HomeDashBoard));
export { connectedHomeDashBoard as HomeDashBoard }; 