import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/**Actions */
import { generalPublicActions  } from '../../../../_actions';

/**Components */
import Modalidades from "./modalidades";
import Testimonios from "./testimonios";
import FormaDePagos from "./formaDePago";
import ComoTeAyudamos from "./comoTeAyudamos";
import Contactanos from "../contacto/contactanos";
import ComoSolicitarConsulta from "./comoSolicitarConsulta";
import { BannerBuscador } from "../especialistas/banerBuscador";
import ListEspecialistas from "../especialistas/listEspecialistas";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Button, Divider, Grid, Paper, Typography, Link as Href } from '@material-ui/core';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: this.props.match.path,
        };
        this.refBuscador = React.createRef();
        this.props.dispatch(generalPublicActions.getEspecialistas('home'));
        !this.props.especialidades.length &&
            this.props.dispatch(generalPublicActions.getEspecialidades());
    }
    //Contactanos 
    handleChangeInputCotacto = e => {
        const { name, value } = e.target
        this.props.dispatch(generalPublicActions.changeInputContactanos({ name, value }))
    }
    handleSubmitContacto = e => {
        e.preventDefault()
        const { contactanos, dispatch } = this.props
        dispatch(generalPublicActions.submitContactanos(contactanos))
    }
    //Fin Contactanos 

    render() {
        const { path } = this.state
        const { especialistasHome, preloaderEspecialistasHome, contactanos } = this.props
        return (
            <section className='container-public'>
                <Paper className='baner-home' elevation={0}>
                    <div className='contenido-baner'>
                        <div className='cont-slogan-one mb-1'>
                            <Typography variant='h4' className='slogan txt-shadow fz-slogan slogan-movil'>
                                Resuelve tu situación de salud <br/> ¡sin salir de casa!
                                <br />
                                <Href href='https://portalpaciente.ecardioline.com/registro'>
                                    <Button className='btn-consultar-ahora white' color='primary'>Consultar ahora</Button>
                                </Href>
                            </Typography>
                        </div>
                        {/* <div className='clear-float-right mt-1'>
                            <Typography variant='h4' className='slogan right'>
                                Consultas médicas y psicológicas online desde 10 USD/EUR
                            </Typography>
                        </div> */}
                    </div>
                </Paper>

                <Paper className='max-width-container mt-5' elevation={0}>
                    <Divider/>
                    <Grid className='mt-2 mb-2' container alignItems='center' spacing={2}>
                        <Grid item xs={12} sm={6}> 
                            <Typography variant='h4' color='secondary' component='p' className='txt-algn-center mb-2'>¡Siente la seguridad y confianza que tu salud necesita! </Typography>
                            <Typography color='secondary' component='p' className='mt-1'>
                                Nuestras consultas médicas online te
                                brindan la tranquilidad que tu salud merece
                                al recibir atención oportuna de una manera
                                fácil, rápida y confiable.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="cont-video">
                                <iframe 
                                    width="560" 
                                    height="315" 
                                    title="Video eCardioline"
                                    src="https://www.youtube.com/embed/DMXXQ4800-A"
                                    frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider/>
                </Paper>

                <ComoTeAyudamos/>

                <div className='bg-terciario'>
                    <Modalidades/>
                </div>

                <ComoSolicitarConsulta/>

                <BannerBuscador
                    path={path}
                    refBuscador={this.refBuscador}
                />
                
                <Paper className='max-width-container mt-2 especialistas' elevation={0}>
                    <Grid item xs={12} align='center'>
                        <Typography variant='h4' color='secondary' component='p' className='mb-2'>Especialistas</Typography>
                        <Typography color='secondary' component='p' className='mt-1'>
                            Profesionales venezolanos de amplia trayectoria que cuentan con los avales correspondientes de cada especialidad y país.
                        </Typography>
                    </Grid>

                    <ListEspecialistas
                        list={especialistasHome}
                        refBuscador={this.refBuscador}
                        loading={preloaderEspecialistasHome}
                    />
                </Paper>

                <div className='bg-terciario'>
                    <Testimonios />
                </div>

                <div className='cont-img-publicaciones'>
                    <Typography component='p'>
                        Disfruta de artículos médicos redactados por nuestros especialistas
                    </Typography>
                    <Link to='/publicaciones'>
                        <Button className='btn-with-br bg-primary mt-2 mb-1 ' color='primary'>Ver publicaciones</Button>
                    </Link>
                </div>

                <FormaDePagos/>
                
                <Contactanos
                    contactanos={contactanos}
                    handleSubmitContacto={this.handleSubmitContacto}
                    handleChangeInputCotacto={this.handleChangeInputCotacto}
                />
            </section>
        );
    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialistasHome, preloaderEspecialistasHome, contactanos, especialidades } = state.generalPublicReducer;
    return {
        especialistasHome, preloaderEspecialistasHome, contactanos, especialidades
    };
}

const connectedHomePage = connect(mapStateToProps)(withStyles(styles)(HomePage));
export { connectedHomePage as HomePage }; 