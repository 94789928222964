import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/**Actions */
import { generalPublicActions } from '../../../../_actions';

/**Components */
// import InputForm from "./inputForm";
// import InfoLanding from "./infoLanding";

/**Styles and Icon */
import '../public.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Grid, Paper, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';

class HomePreguntas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preguntaActive: 1,
        };
        !this.props.especialidades.length &&
            this.props.dispatch(generalPublicActions.getEspecialidades());
    }

    handleTogleActivePregunta = data => e => {
        e.preventDefault();
        if (this.state.preguntaActive === data ) {
            
            this.setState({ preguntaActive : ''})
        }else{
            this.setState({ preguntaActive : data})
        }
    }
    render() {
        const { preguntaActive } = this.state
        const { especialidades } = this.props
        return (
            <section className='container-public' >

                <Paper className='baners-page baner-preguntas' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' component='p' className='slogan txt-shadow fz-slogan slogan-movil'>
                            Preguntas frecuentes
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container container-preguntas mt-5' elevation={0}>
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(1)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 1 ? 'active' : ''}`} fontSize='large'/>
                            ¿Qué es la telemedicina?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 1 ? 'active' : ''}`}>
                            Es el uso de las tecnologías de la información y comunicación para ofrecer y proporcionar atención médica fuera de las instalaciones médicas tradicionales. Una consulta online desde tu casa con un especialista ubicado en un sitio distante es un ejemplo.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(2)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 2 ? 'active' : ''}`} fontSize='large' />
                            ¿Cómo puede un médico diagnosticarme sin tocarme?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 2 ? 'active' : ''}`}>
                            Mediante la historia clínica y un cuidadoso interrogatorio, ya que estos son la base fundamental e insustituible del diagnóstico. Un interrogatorio minucioso representa la mitad del diagnóstico.
                            <br/><br/>
                            Adicional a esto, los datos de los signos vitales (como presión arterial y frecuencia cardiaca), los análisis de exámenes complementarios (como estudios de laboratorio, pruebas funcionales y exploraciones imagenológicas) y la video inspección pueden ayudar a solucionar efectivamente un alto porcentaje de las condiciones y enfermedades comunes a través de la consulta médica online.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(3)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 3 ? 'active' : ''}`} fontSize='large' />
                            ¿En qué situaciones es mejor solicitar una consulta médica online?
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 3 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <CheckIcon fontSize='inherit' className='mr-05' />  Si se te dificulta el acceso a la atención médica especializada (como la ausencia de especialistas en tu localidad).
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <CheckIcon fontSize='inherit' className='mr-05' />  Si presentas limitaciones físicas para trasladarte.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <CheckIcon fontSize='inherit' className='mr-05' />  Si deseas evitar traslados y largas esperas en un centro clínico para ser atendido.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <CheckIcon fontSize='inherit' className='mr-05' />  Si dispones de poco tiempo por situaciones laborales y cotidianas.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <CheckIcon fontSize='inherit' className='mr-05' />  Si quieres ser atendido desde la comodidad de tu casa u oficina.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <CheckIcon fontSize='inherit' className='mr-05' />  Si no cuentas con un seguro médico.
                            </Typography>
                            <Typography color='secondary' component='p' >
                                La telemedicina no es un sustituto de la atención médica presencial, pero sí una forma de complementarla y asistirla, mejorando la rapidez de la atención, la precocidad de los diagnósticos y el seguimiento efectivo de los tratamientos.
                            </Typography>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(4)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 4 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuáles son mis beneficios?
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 4 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' className=''>
                                Evitar el riesgo de contagio por COVID-19 al asistir a un centro de salud.
                                <br/><br/>
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Acceso al cuidado de tu salud en cualquier lugar y momento.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Atención médica de una manera fácil, rápida y confiable.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Ahorro de tiempo al no trasladarte y esperar en centros médicos.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Acceso a los perfiles de los profesionales y así seleccionar el especialista de tu preferencia.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' /> Orientaciones, segundas opiniones y consultas online por parte de especialistas calificados.
                            </Typography>
                            <Typography color='secondary' component='p' className='mb-1'>
                                <Brightness1Icon fontSize='inherit' className='mr-05' />  Informe electrónico con las conclusiones, recomendaciones y tratamientos de tu caso, luego de tu consulta online.
                            </Typography>

                            <Typography color='secondary' component='p' className='mb-1'>
                                <b>Recibe tu consulta ahora haciendo clic <Link to='/registro-paciente' className='aqui'>aquí</Link></b>
                            </Typography>
                        </div>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(5)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 5 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuáles son las especialidades disponibles?
                        </Typography>
                        <div className={`respuesta ${preguntaActive === 5 ? 'active' : ''}`}>
                            <Typography color='secondary' component='p' className=''>
                                Contamos con más de 25 especialidades:
                                <br /><br />
                            </Typography>
                            <Grid container spacing={2}>
                                {especialidades.length 
                                    ? especialidades.map(k => {
                                        return(
                                            <Grid item xs={12} sm={6} key={k.id}>
                                                <Typography  color='secondary' component='p' className='mb-1'>
                                                    <Brightness1Icon fontSize='inherit' className='mr-05' />  {k.name}
                                                </Typography>
                                            </Grid>
                                        )
                                    })
                                    : <Typography color='secondary' component='p' className='mb-2'>
                                        Cargando especialidades...
                                    </Typography>
                                }
                            </Grid>
                        </div>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(6)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 6 ? 'active' : ''}`} fontSize='large' />
                            ¿En cuánto tiempo puedo ser atendido?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 6 ? 'active' : ''}`}>
                            Si es una consulta vía texto, la respuesta será dentro de las próximas 24 horas y si es mediante videollamada, la atención se podrá efectuar de acuerdo a la disponibilidad del especialista.
                            <br/><br/>
                            Para ver el horario disponible del especialista solo debe ingresar a la opción “ver perfil” del especialista y luego, en la columna izquierda del perfil, podrás observar el calendario de “horario de atención” para seleccionar la fecha y hora que más te convenga.
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(7)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 7 ? 'active' : ''}`} fontSize='large' />
                            ¿Puedo enviarle documentos al especialista?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 7 ? 'active' : ''}`}>
                            Sí. Esto lo podrá hacer al momento de realizar la solicitud de servicio, allí contará con la opción de anexar “documentos” tales como estudios de laboratorio, exploraciones de imagen, pruebas funcionales e informes.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(8)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 8 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuánto tiempo dura la consulta médica online?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 8 ? 'active' : ''}`}>
                            Depende de la complejidad del motivo de consulta. El promedio de atención es de 15 minutos, pero en los casos que sea necesario puede extenderse a 30 minutos.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(9)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 9 ? 'active' : ''}`} fontSize='large' />
                            ¿Necesito tener una cámara para la consulta online?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 9 ? 'active' : ''}`}>
                            Solo es necesaria para la modalidad de servicio por videollamada ya que también puedes solicitar consulta vía texto con respuesta en menos de 24 horas, la cual no requiere contacto en vivo.
                            <br/> <br/>
                            La consulta médica online a través de videollamada permite la video inspección por parte del especialista así como un mejor conocimiento de tu caso.
                            <br/> <br/>
                            Antes de establecer la videoconsulta, es recomendable verificar el adecuado funcionamiento de tu cámara, micrófono, altavoces y la conexión a Internet.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(10)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 10 ? 'active' : ''}`} fontSize='large' />
                            Si estoy de viaje, ¿puedo usar los servicios de atención online?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 10 ? 'active' : ''}`}>
                            Sí, puedes ser atendido por nuestros especialistas cuando quieras y desde donde estés.
                        </Typography>
                    </div>
                    
                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(11)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 11 ? 'active' : ''}`} fontSize='large' />
                            ¿Cuánto cuesta la consulta médica online y cómo pago?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 11 ? 'active' : ''}`}>
                            El precio de cada consulta online varía según la especialidad.
                            <br/><br/>
                            La consulta vía texto oscila entre 10 y 15 USD/EUR, y a través de videollamada entre 15 y 20 USD/EUR o su equivalente en <b>bolívares</b> según la tasa de cambio del día.
                            <br/><br/>
                            Puedes ver los precios de los servicios de cada especialista visitando su perfil.
                            <br/><br/>
                            Los pagos los puedes realizar a través de PayPal, Zelle, Bizum y con Tarjeta de débito/crédito o transferencia bancaria en <b>bolívares</b>.
                            <br/><br/>
                            Todos tus pagos realizados mediante nuestra plataforma están seguros y garantizados.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(13)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 13 ? 'active' : ''}`} fontSize='large' />
                            ¿Mi información personal está segura?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 13 ? 'active' : ''}`}>
                            Sí, ya que utilizamos protocolo SSL que proporciona privacidad e integridad de todos los datos en la comunicación de una consulta online.
                            <br /><br />
                            Nos esforzamos por mantener todos los niveles de seguridad para que tu información personal y médica esté segura. Así también te recomendamos establecer una adecuada estrategia de contraseña y no utilizar redes wifi públicas o poco confiables.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(12)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 12 ? 'active' : ''}`} fontSize='large' />
                            ¿Puedo tener una historia digital?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 12 ? 'active' : ''}`}>
                            Sí. Esta la encontrarás en tu perfil en la sección llamada “historia”, donde podrás incluir todos tus datos médicos. También podrás observar el registro médico electrónico realizado por el especialista con las conclusiones, recomendaciones y tratamientos de tu consulta online.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(14)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 14 ? 'active' : ''}`} fontSize='large' />
                            ¿Pueden atender emergencias?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 14 ? 'active' : ''}`}>
                            No atendemos emergencias médicas.
                            <br/><br/>
                            Ante una sospecha de emergencia la recomendación es comunicarse inmediatamente al 911 o acudir al centro médico más cercano con servicio de emergencia.
                        </Typography>
                    </div>

                    <div className='cont-texto'>
                        <Typography variant='h4' color='secondary' component='p' className='title mb-2' onClick={this.handleTogleActivePregunta(15)}>
                            <KeyboardArrowDownIcon color='primary' className={`arrow ${preguntaActive === 15 ? 'active' : ''}`} fontSize='large' />
                            ¿Puedo registrarme como paciente para pedir cita médica más adelante?
                        </Typography>
                        <Typography color='secondary' component='p' className={`respuesta ${preguntaActive === 15 ? 'active' : ''}`}>
                            Sí. Puedes registrarte como paciente totalmente gratis y acceder a tu cuenta cuando lo necesites sin importar donde estés.
                            <br/><br/>
                            También tendrás acceso a ser seguidor de los especialistas médicos de tu preferencia y ver todos los artículos médicos que ellos compartan en sus perfiles.
                        </Typography>
                    </div>

                </Paper>

            </section>
        );
    }
}

HomePreguntas.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { especialidades } = state.generalPublicReducer;
    return {
        especialidades
    };
}

const connectedHomePreguntas = connect(mapStateToProps)(withStyles(styles)(HomePreguntas));
export { connectedHomePreguntas as HomePreguntas }; 