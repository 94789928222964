import { menuConstants } from '../_constants';

const initialState = {
    isOpen: false,
    menuActivo: 1,
    classOpenBar: false,
};

export function menuReducer(state = initialState, action) {
    switch (action.type) {
        case menuConstants.MOBILEOPEN:
            return Object.assign({}, state, {
                isOpen: action.actiomenu,
            });
        case menuConstants.SELECT_ITEM_MENU:
            return Object.assign({}, state, {
                isOpen: false,
                menuActivo: action.data
            });
        default:
            return state
    }
}