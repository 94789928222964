export const generalPublicConstants = {
    CHANGE_IMPUT_CONTACTO: 'CHANGE_IMPUT_CONTACTO',
    CHANGE_IMPUT_NEW_MEDICO: 'CHANGE_IMPUT_NEW_MEDICO',

    REQUEST_BLOG: 'REQUEST_BLOG',
    REQUEST_POST_PUBLIC: 'REQUEST_POST_PUBLIC',
    REQUEST_PUBLICACIONES: 'REQUEST_PUBLICACIONES',
    REQUEST_GENERAL_PUBLIC: 'REQUEST_GENERAL_PUBLIC',

    SUCCESS_GET_BLOG: 'SUCCESS_GET_BLOG',
    SUCCESS_GET_ANALITICA: 'SUCCESS_GET_ANALITICA',
    SUCCESS_GET_POST_PUBLIC: 'SUCCESS_GET_POST_PUBLIC',
    SUCCESS_GET_ESPECIALISTAS: 'SUCCESS_GET_ESPECIALISTAS',
    SUCCESS_GET_PUBLICACIONES: 'SUCCESS_GET_PUBLICACIONES',
    SUCCESS_SUBMIT_NEW_MEDICO: 'SUCCESS_SUBMIT_NEW_MEDICO',
    SUCCESS_GET_ESPECIALIDADES: 'SUCCESS_GET_ESPECIALIDADES',
    SUCCESS_SUBMIT_CONTACTANOS: 'SUCCESS_SUBMIT_CONTACTANOS',
};
