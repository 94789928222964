import React from "react";
import { Link as Ruta } from 'react-router-dom'

/**Helpers */
import { menu } from "../../../../_helpers";

/**Styles And Logo-Icon */
import { Grid, Button, Typography, Menu, MenuItem, Link } from '@material-ui/core';


const RegistroYSesion = (props) => (
    <Grid container className='cont-item-registro-inicio cont-item-registro-inicio-movil' spacing={1} direction="row" justify="flex-end" alignItems="center" >
        <Grid item className={``}>
            <Button color="primary" onClick={props.handleMenuOpen('registro')}>Regístrate</Button>
        </Grid>
        <Grid item className={``}>
            <Typography component='span' color='primary'>|</Typography>
        </Grid>
        <Grid item className={``}>
            <Button color="primary" onClick={props.handleMenuOpen('ingreso')}>Ingresa</Button>
        </Grid>

        <Menu
            id="menu-appbar"
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(props.anchorEl)}
            onClose={props.handleMenuClose}
        >
            {props.tipoMenu !== '' &&
                menu[props.tipoMenu].map(k => {
                    if(k.target){
                        return (
                            <Link key={k.id} href={k.ruta} onClick={props.handlSelectMenu("")}>
                                <MenuItem onClick={props.handleMenuClose}>
                                    {k.name}
                                </MenuItem>
                            </Link>
                        )
                    }else{
                        return (
                            <Ruta key={k.id} to={k.ruta} onClick={props.handlSelectMenu("")}>
                                <MenuItem onClick={props.handleMenuClose}>
                                    {k.name}
                                </MenuItem>
                            </Ruta>
                        )
                    }
                   
                })
            }
        </Menu>
    </Grid>
)

export default RegistroYSesion