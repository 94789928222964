import { menuConstants } from '../_constants';

export const menuActions = {
    isOpen,
    selectItemMenu,
};

function isOpen(actiomenu) {
    return { type: menuConstants.MOBILEOPEN, actiomenu };
}

function selectItemMenu(data) {
    return { type: menuConstants.SELECT_ITEM_MENU, data };
}