import React, { Component } from 'react';
import PropTypes from "prop-types";
// import { connect } from 'react-redux';

/**Actions */
// import { generalPublicActions } from '../../../../_actions';

/**Components */
import Idiomas from "./datosMedico/idiomas";
import Calendario from "./datosMedico/calendario";
import BtnConsulta from "./datosMedico/btnConsulta";
import ListInfoMed from "./datosMedico/listInfoMed";
import FormaPago from "./datosMedico/formaPagoEpage";
import ModalidadAsesoria from "./datosMedico/modalidadAsesoria";

/**Styles and Icon */
import { Grid } from '@material-ui/core';
import { styles } from "../../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";


class InfoMedico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verMas: '',
            condicionItem: '',
        }
    }

    handleTogleVerMas = data => e => {
        e.preventDefault();
        const { verMas } = this.state
        if (data !== verMas) {
            this.setState({ verMas : data})
        }else{
            this.setState({ verMas: '' })
        }
    }
    handleTogleVermasItem = data => e => {
        e.preventDefault();
        const { condicionItem } = this.state
        if (data !== condicionItem) {
            this.setState({ condicionItem : data})
        }else{
            this.setState({ condicionItem: '' })
        }
    }

    render() {
        const { verMas, condicionItem } = this.state
        const { infoMed, classes, paciente } = this.props

        const videoConferencia = infoMed.modalidad_cita ? infoMed.modalidad_cita.filter(k => (k.name_attetion === 'Videochat en vivo')) : []
        const escrita24Horas = infoMed.modalidad_cita ? infoMed.modalidad_cita.filter(k => (k.name_attetion === 'Texto en menos de 24 horas')) : []
        return (
            <Grid container className='p-movil' direction="column">
                {infoMed.educacion.length 
                    ? <ListInfoMed
                        cdn='Ed'
                        verMas={verMas}
                        title='Educación'
                        classes={classes}
                        btnVerMas='educación'
                        condicionItem={condicionItem}
                        handleTogleVerMas={this.handleTogleVerMas}
                        handleTogleVermasItem={this.handleTogleVermasItem}
                        listLongitud={infoMed ? infoMed.educacion.length : 0}
                        list={infoMed ? condicionItem === 'Ed' ? infoMed.educacion : infoMed.educacion.slice(0, 2) : []}
                    />
                    : ''
                }
                
                {infoMed.experiencia.length 
                    ? <ListInfoMed
                        cdn='Ex'
                        verMas={verMas}
                        classes={classes}
                        btnVerMas='experiencia'
                        title='Experiencia laboral'
                        condicionItem={condicionItem}
                        handleTogleVerMas={this.handleTogleVerMas}
                        handleTogleVermasItem={this.handleTogleVermasItem}
                        listLongitud={infoMed ? infoMed.experiencia.length : 0 }
                        list={infoMed ? condicionItem === 'Ex' ? infoMed.experiencia : infoMed.experiencia.slice(0, 2) : []}
                    />
                    : ''
                }

                {infoMed.membrecia.length 
                    ? <ListInfoMed
                        cdn='Me'
                        verMas='NO'
                        classes={classes}
                        btnVerMas='membresias'
                        condicionItem={condicionItem}
                        handleTogleVerMas={this.handleTogleVerMas}
                        handleTogleVermasItem={this.handleTogleVermasItem}
                        listLongitud={infoMed ? infoMed.membrecia.length : 0}
                        title='Miembro de sociedad científica o colegio de médico'
                        list={infoMed ? condicionItem === 'Me' ? infoMed.membrecia : infoMed.membrecia.slice(0, 2) : []}
                    />
                    : ''
                }
                
                {infoMed.logros.length 
                    ? <ListInfoMed
                        cdn='Re'
                        verMas={verMas}
                        classes={classes}
                        condicionItem={condicionItem}
                        title='Premios y reconocimientos'
                        btnVerMas='premios y reconocimientos'
                        handleTogleVerMas={this.handleTogleVerMas}
                        handleTogleVermasItem={this.handleTogleVermasItem}
                        listLongitud={infoMed ? infoMed.logros.length : 0}
                        list={infoMed ? condicionItem === 'Re' ? infoMed.logros : infoMed.logros.slice(0, 2) : []}
                    />
                    : ''
                }
                
                {infoMed.idiomas.length 
                    ? <Idiomas
                        idiomas={infoMed ? infoMed.idiomas : []}
                    />
                    : ''
                }
                
                {infoMed.horario.length
                    ? <Calendario
                        horario={infoMed ? infoMed.horario : []}
                    />
                    : ''
                }
                
                {infoMed.modalidad_cita.length
                    ? <ModalidadAsesoria
                        list={infoMed.modalidad_cita}
                    />
                    : '' 
                }
                
                {infoMed.formaPago.length
                    ? <FormaPago
                        classes={classes}
                        list={infoMed.formaPago}
                    />
                    : ''
                }

                { infoMed.especialidades.length && infoMed.horario.length && infoMed.formaPago.filter(k => (k.selected)).length && 
                    ((videoConferencia.length && videoConferencia[0].montoHonorario.filter(k => ((k.monto !== '0' && k.free === '0') || k.free === '1')).length) || 
                    (escrita24Horas.length && escrita24Horas[0].montoHonorario.filter(k => ((k.monto !== '0' && k.free === '0') || k.free === '1')).length))
                    ? <BtnConsulta
                        paciente={paciente}
                        nickname={infoMed.username}
                    />
                    : ''
                }
                
            </Grid>
        );
    }
}

InfoMedico.propTypes = {
    classes: PropTypes.object.isRequired,
};

// function mapStateToProps(state) {
//     const { blog } = state.generalPublicReducer;
//     return {
//         blog,
//     };
// }

// const connectedInfoMedico = connect(mapStateToProps)(withStyles(styles)(InfoMedico));
// export { connectedInfoMedico as InfoMedico }; 

export default withStyles(styles)(InfoMedico);