import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { menuReducer } from './menu.reducer';
import { authReducer } from './auth.reducer';
import { medicoReducer } from './medico.reducer';
import { generalPublicReducer } from './generalPublic.reducer';

const rootReducer = combineReducers({
    alert,
    authReducer,
    menuReducer,
    medicoReducer,
    generalPublicReducer,
});

export default rootReducer;