import React from "react";
import { Link } from 'react-router-dom'

/*Componet */

/**Styles And Logo-Icon */
import { Paper, Grid, Button, Breadcrumbs, Typography, CircularProgress, Divider} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const Migas = (props) => (
    <Paper className='bx-shadow-n p05'>
        <Grid container
            direction="row"
            alignItems="center" >
            <Grid item xs={12} sm={8} className='mb-05'>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    {props.bread.map(k => {
                        if (k.active) {
                            return (
                                <Typography key={k.id} color="primary">{k.name}</Typography>
                            )
                        } else {
                            return (
                                <Link color="textPrimary" to={k.ruta} key={k.id}>
                                    {k.name}
                                </Link>
                            )

                        }
                    })}
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={4} align='right' className='mb-05'>
                {props.btnExport && 
                    <Button
                        color="primary"
                        className='mr-1'
                        variant="contained"
                        onClick={props.handleExportExcel}
                        disabled={props.loadingExport}
                    >
                        Exportar
                        {props.loadingExport && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button>
                }
                {props.rutBtn &&
                    <Link to={props.rutBtn}>
                        <Button
                            color="primary"
                            variant="contained"
                        >
                            {props.txtBtn}
                        </Button>
                    </Link>
                }

            </Grid>
        </Grid>
        <Divider/>
    </Paper>
    
)

export default Migas