import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/**Actions */
import { medicoActions } from '../../../../_actions';

/**Components */
import { ListPost } from "../../layout/post/listPosrt";
import MasPublicaciones from "../../layout/post/masPublicaciones";
import InfoMedico from "../../layout/epage/barLeft/infoMedico";
import HomeBarleft from "../../layout/epage/barLeft/homeBarleft";
import {HomePortada} from "../../layout/epage/portada/homePortada";
import ProgressCircular from "../../layout/progress/progressCircular";


/**Styles and Icon */
import '../../layout/epage/epage.scss';
import { styles } from "../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from '@material-ui/core';


class HomeEpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nickname: this.props.match.params.nickname
        }
        this.props.dispatch(medicoActions.getEpage(this.props.match.params.nickname));
    }

    handleMasPost = e => {
        e.preventDefault();
        // const { blog, dispatch } = this.props
        // dispatch(generalPublicActions.getBlog(blog.list.length, 'loading'));
    }

    render() {
        const { epage, paciente } = this.props
        return (
            <section className='container-epage' >
                <HomePortada
                    infoMed={epage}
                />

                <div className='body-epage'>

                    <HomeBarleft
                        infoMed={epage}
                        paciente={paciente}
                        ruta={this.props.match.path.slice(0, 6)}
                    />

                    <div className='cont-page'>
                        {epage.preloader
                            ? <ProgressCircular
                                message='Cargando publiaciones...'
                            />
                            : bodyEpage(epage.viewOnPage, epage)
                        }
                    </div>
                </div>

            </section>
        );
    }
}

const bodyEpage = (viewOn, epage) => {
    switch (viewOn) {
        case 2:
            return 'Aqui iran los seguidores'
            // return <Seguidores />;
        case 3:
            return 'Aqui iran los siguiendos'
            // return <Seguidores />;
        case 4:
            return 'Aqui iran las recomendaciones'
            // return <Recomendaciones />;
        // case 5:
        //     return <PerfilPublico />;
        default:
            return <div>
                <Hidden mdUp> 
                    <InfoMedico
                        infoMed={epage}
                    />
                </Hidden>
                <ListPost
                    posts={epage.post}
                />
                <MasPublicaciones
                   nickname={epage.username}
                />
            </div>
    }
}

HomeEpage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { paciente } = state.authReducer;
    const { epage } = state.medicoReducer;
    return {
        epage,
        paciente,
    };
}

const connectedHomeEpage = connect(mapStateToProps)(withStyles(styles)(HomeEpage));
export { connectedHomeEpage as HomeEpage }; 