export const menu = {
    public:[
        {
            "id": 1,
            "name": "Especialistas",
            "ruta": "/nuestros-especialistas",
        },
        {
            "id": 2,
            "name": "Servicios",
            "ruta": "/servicios",
        },
        {
            "id": 3,
            "name": "Conócenos",
            "ruta": "/conocenos",
        },
        {
            "id": 4,
            "name": "Blog",
            "ruta": "/blog",
        },
        {
            "id": 5,
            "name": "Contáctanos",
            "ruta": "/contactanos",
            "icon": "platos"
        },
        {
            "id": 6,
            "name": "Publicaciones",
            "ruta": "/publicaciones",
        },
        {
            "id": 7,
            "name": "Preguntas frecuentes",
            "ruta": "/preguntas-frecuentes",
        },
    ],
    ingreso:[
        {
            "id": 1,
            "name": "Paciente",
            "ruta": "https://portalpaciente.ecardioline.com/",
            "target": true,
        },
        {
            "id": 2,
            "name": "Especialista",
            "ruta": "https://portalmedico.ecardioline.com/",
            "target": true,
        },
    ],
    registro:[
        {
            "id": 1,
            "name": "Paciente",
            "ruta": "https://portalpaciente.ecardioline.com/registro",
            "target": true,
        },
        {
            "id": 2,
            "name": "Especialista",
            "ruta": "/registro-especialista",
            "target": false,
        },
    ],
};