// import { alertActions } from './';
// import { history } from '../_helpers';
// import { authService } from '../_services';
// import { authConstants } from '../_constants';

export const authActions = {
    logout,     
};

function logout() {
    window.location.href = '/'
    localStorage.removeItem('paciente');
}

