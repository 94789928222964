import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/**Actions */
import { authActions } from '../../../../_actions';

/*Componet */
import FormLogin from "./formLogin";
import FormRecuPass from "./formRecuPass";
import FormCambioPass from "./formCambioPass";

/**Styles And Logo-Icon */
import './login.scss';
import logo from '../../../assets/media/logo_peque.png';
import { Typography, Paper, Grid, Button, CircularProgress } from '@material-ui/core';

class HomeLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path: this.props.match.path,
            tokenLimit: this.props.match.params.token ? this.props.match.params.token : ''
        };

        // if (JSON.parse(localStorage.getItem('user'))) {
        //     window.location.href = `/admin/inicio`
        // }
    }
    componentDidUpdate() {
        if (this.state.path !== this.props.match.path) {
            this.setState({ path: this.props.match.path })
            this.props.dispatch(authActions.clearState());
        }
    }

    handleChangeInputs = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.props.dispatch(authActions.changeInputs(name, value));
    }
    handleSubmit = e => {
        e.preventDefault();
        const path = this.props.match.path;
        const { dispatch, email, password, repassword } = this.props
        if (path === '/login') {
            dispatch(authActions.loginInsert(email, password));
        } else if (path === '/recuperar-pass') {
            dispatch(authActions.sedPassEmail(email));
        } else {
            dispatch(authActions.updatePass(password, repassword, this.state.tokenLimit));
        }
    }

    render() {
        const { email, password, repassword, submittedLogin, loadingLogin, message } = this.props
        const path = this.props.match.path
        return (
            <section className='container'>
                <div className='cont-login'>
                    <Grid
                        container
                        justify="center"
                        direction="row"
                        className='cont-paper'
                    >
                        <Grid item>
                            <Paper className='paper-login'>
                                <div className='header-paper-login'>
                                    <Typography variant='h5' color='primary'>
                                        {(path === '/login') && 'Iniciar sesión'}
                                        {(path === '/recuperar-pass') && 'Recuperar contraseña'}
                                        {(path === '/restablecer-pass/:token') && 'Cambiar contraseña'}

                                    </Typography>
                                </div>
                                {(path === '/login') && <FormLogin
                                    email={email}
                                    password={password}
                                    submittedLogin={submittedLogin}
                                    handleChangeInputs={this.handleChangeInputs}
                                    handleFocusPassword={this.handleFocusPassword}
                                />}

                                {(path === '/recuperar-pass') && <FormRecuPass
                                    email={email}
                                    message={message}
                                    submittedLogin={submittedLogin}
                                    handleChangeInputs={this.handleChangeInputs}
                                />}

                                {(path === '/restablecer-pass/:token') && <FormCambioPass
                                    password={password}
                                    repassword={repassword}
                                    submittedLogin={submittedLogin}
                                    handleChangeInputs={this.handleChangeInputs}
                                />}

                                <div className='footer-paper-login'>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        disabled={loadingLogin}
                                        onClick={this.handleSubmit}
                                    >
                                        {(path === '/login') && 'Entrar'}
                                        {(path === '/recuperar-pass') && 'Recuperar contraseña'}
                                        {(path === '/restablecer-pass/:token') && 'Cambiar contraseña'}
                                        {loadingLogin && <CircularProgress size={24} className="ProgressAbsolut" />}
                                    </Button>
                                    {(path === '/login')
                                        ? <Link to={'/recuperar-pass'} className='mb-1'>
                                            <Typography component='span'>¿Olvidó su contraseña?</Typography>
                                        </Link>
                                        : <Link to={'/login'}>
                                            <Typography component='span'>Inicia sesión</Typography>
                                        </Link>
                                    }

                                </div>
                            </Paper>
                            <article className="logo">
                                <img src={logo} alt="logo" />
                            </article>
                        </Grid>
                    </Grid>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { loggedIn, loadingLogin, user, email, password, repassword, submittedLogin, message } = state.authReducer;
    return {
        alert,
        loggedIn, loadingLogin, user, email, password, repassword, submittedLogin, message
    };
}

const connectedHomeLogin = connect(mapStateToProps)(HomeLogin);
export { connectedHomeLogin as HomeLogin }; 