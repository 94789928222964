import React from "react";

/**Constants */
import { UrlIcon } from "../../../../_constants";

import { Avatar, Grid, Paper, Typography, Button, Link } from '@material-ui/core';

const ComoSolicitarConsulta = () => (
    <Paper className='max-width-container mt-2 como-funciona' elevation={0}>
        <Grid item xs={12} align='center'>
            <Typography variant='h4' color='secondary' component='p' className=''>Pasos para acceder a la consulta</Typography>
        </Grid>
        <Grid container spacing={2} className='cont-item-como-funciona mt-2 mb-2' align='center'>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-num' src={UrlIcon.icon_registrate} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Registro</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Crea tu cuenta como paciente sin costo alguno.</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-num' src={UrlIcon.icon_selecciona_especialidad} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Especialidad</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Elige la especialidad más conveniente para ti.</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-num' src={UrlIcon.icon_selecciona_modalidad} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Modalidad</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Selecciona videollamada o vía texto con respuesta en menos de 24 horas.</Typography>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={2} className='cont-item-como-funciona mt-2 mb-2' align='center'>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-num' src={UrlIcon.icon_motivo_consulta} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Motivo de consulta</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Describe tu situación de salud a resolver y adjunta exámenes, fotos o informes.</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-num' src={UrlIcon.icon_solicita_cita} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Solicitud de cita</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Si es por videollamada selecciona día y hora.</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={4} className='item-funciona'>
                <Avatar alt="Registrate" className='icon-como-num' src={UrlIcon.icon_confirma_solicitud} />
                <div className='cont-body-item-funciona'>
                    <Typography variant='h4' color='secondary' component='p' className='mb-2'>Confirmación</Typography>
                    <Typography color='secondary' component='p' className='mb-2'>Concreta tu solicitud seleccionando tu opción de pago de preferencia y luego haz clic en “Solicitar cita”.</Typography>
                </div>
            </Grid>
        </Grid>
        <Grid item xs={12} align='center'>
            <Link href='https://portalpaciente.ecardioline.com/'>
                <Button className='btn-with-br mb-2' color='primary'>Consulta ahora</Button>
            </Link>
        </Grid>
    </Paper>
)

export default ComoSolicitarConsulta