import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import CountUp from 'react-countup';

/**Constants */
import { EspecialistasLanding } from "../../../../../_constants";

/**Actions */
import { generalPublicActions } from '../../../../../_actions';

/**Components */
import InputForm from "./inputForm";
import InfoLanding from "./infoLanding";
import ListEspecialistas from "../../especialistas/listEspecialistas";

/**Styles and Icon */
import '../../public.scss';
import { styles } from "../../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Grid, Paper, Typography, Button, Link as Enlace } from '@material-ui/core';


class HomeLandingEspecialista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeCount: false,
            captcha: '',
        };
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.handleCapRecapchat = this.handleCapRecapchat.bind(this)

        this.props.dispatch(generalPublicActions.getAnalitica());

        window.addEventListener('scroll', this.handleScrollView);
    }

    handleScrollView = (e) => {
        if (Math.round(document.documentElement.scrollTop) > 50) {
            // console.log('Started! 💨', Math.round(document.documentElement.scrollTop))
            this.setState({ activeCount: true })
        }
    }

    handleCapRecapchat(value){
        // console.log("Captcha value:", value);
        this.setState({ captcha: value})
    }

    handleChangeInput = e =>{
        e.preventDefault();
        const { name , value } = e.target
        console.log(name, value )
        this.props.dispatch(generalPublicActions.getchangeInputRegisMedico({ name, value}));
    }
    
    handleFileSelected = e => {
        const { name } = e.target;
        const value = e.target.files[0]
        console.log(name, e.target.files[0])
        this.props.dispatch(generalPublicActions.getchangeInputRegisMedico({ name, value}));
    }

    handleOnSubmit(){
        const { captcha } = this.state
        const { dispatch, newMedico } = this.props
        
        dispatch(generalPublicActions.registroEspecialista(newMedico, captcha));
    }

    
    render() {
        const { activeCount } = this.state
        const { classes, analitica, newMedico } = this.props
        return (
            <section className='container-public'>
                <Paper className='baners-page baner-landingEspecialista' elevation={0}>
                    <div className='contenido-baner'>
                        <Typography variant='h4' className='slogan'>
                            Sé parte de nuestro equipo de especialistas teleconsultores
                        </Typography>
                        <Typography color='secondary' component='p' className='mb-2'>
                            Disfruta de un consultorio virtual que te brinde la seguridad y confianza que tu servicio necesita.
                        </Typography>
                    </div>
                </Paper>

                <Paper className='max-width-container mt-2 como-funciona' elevation={0}>
                    <Grid className='landing-page mt-2' container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <div className='cont-img-medico'>
                                <img alt='Medico en consulta' src='https://ecardioline.com/assets_media/bg/bg13.jpg' />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant='h4' align='center' color='secondary' component='p' className='mb-2'>Solicitud para unirte</Typography>
                            <InputForm
                                classes={classes}
                                newMedico={newMedico}
                                handleOnSubmit={this.handleOnSubmit}
                                handleChangeInput={this.handleChangeInput}
                                handleFileSelected={this.handleFileSelected}
                                handleCapRecapchat={this.handleCapRecapchat}
                            />
                        </Grid>

                        <InfoLanding/>

                        <Grid item xs={12} className='mt-1'>
                            <Typography variant='h4' align='center' color='secondary' component='p' className='mb-2'>
                                Cada día más especialistas se benefician de nuestros servicios
                            </Typography>
                            <Typography variant='h3' align='center' color='primary' component='p' className='mb-2'>
                                {activeCount &&
                                    <CountUp
                                        start={0} end={parseInt(analitica.medicos)} duration={10.75}
                                    // onStart={this.handleScrollView()}
                                    />
                                }
                            </Typography>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12} className='mt-1 especialistas'>
                            <Typography variant='h4' align='center' color='secondary' component='p' className='mb-2'>
                                También dieron el paso a la innovación en salud digital
                            </Typography>
                            
                            <ListEspecialistas
                                loading={false}
                                list={EspecialistasLanding}
                            />
                        </Grid>


                    </Grid>
                </Paper>
                
                <Paper className='baners-page baner-conocenos mt-0 pt-0' elevation={0}>
                    <div className='txt-fondo-gris'>
                        <div className='txt-algn-center'>
                            <Typography variant='h4' color='secondary' component='p' className='mb-2 txt-algn-center'>¿Listo para formar parte de nuestro equipo?</Typography>
                            <Enlace href='/registro-especialista'>
                                <Button className='btn-with-br bg-primary' color='primary'>Sí, quiero unirme ahora.</Button>
                            </Enlace>
                        </div>
                    </div>
                </Paper>
            </section>
        );
    }
}

HomeLandingEspecialista.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { analitica, newMedico } = state.generalPublicReducer;
    return {
        analitica, newMedico
    };
}

const connectedHomeLandingEspecialista = connect(mapStateToProps)(withStyles(styles)(HomeLandingEspecialista));
export { connectedHomeLandingEspecialista as HomeLandingEspecialista }; 