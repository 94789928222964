import React from "react";
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";

import { Grid, TextField, Button, Typography, CircularProgress } from '@material-ui/core';

const InputForm = (props) => (
    <Grid container spacing={4}>
        <Grid item xs={12}>
            <TextField
                required
                fullWidth
                id="nombre"
                name="nombre"
                label="Nombre"
                variant="outlined"
                value={props.newMedico.nombre}
                onChange={props.handleChangeInput}
                error={props.newMedico.submit && !props.newMedico.nombre}
                helperText={props.newMedico.submit && !props.newMedico.nombre && <span className="lbl-error" >Campo Requerido</span>}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                fullWidth
                id="correo"
                type='email'
                name="correo"
                variant="outlined"
                label="Correo electrónico"
                value={props.newMedico.correo}
                onChange={props.handleChangeInput}
                error={props.newMedico.submit && !props.newMedico.correo}
                helperText={props.newMedico.submit && !props.newMedico.correo && <span className="lbl-error" >Campo Requerido</span>}
            />
        </Grid>
        <Grid item xs={12}>
            <input
                // multiple
                type="file"
                name="file"
                accept="application/pdf"
                id="contained-button-file"
                className={props.classes.inputFile}
                onChange={props.handleFileSelected}
            />
            <label htmlFor="contained-button-file">
                <Button className='btn-with-br transfor_initial mr-05' color="primary" component="span">
                    Adjunta tu currículo*
                </Button>
                Solo formato PDF. Máximo 8 MB.
                {props.newMedico.file.name ? <Typography>{props.newMedico.file.name}</Typography> : ''}
            </label>
        </Grid>
        <Grid item xs={12}>
            <ReCAPTCHA
                sitekey="6LdErmwUAAAAAKAbGuNnHATvGZ0r2onRJ7Zdp1TE"
                onChange={props.handleCapRecapchat}
            />
        </Grid>
        <Grid item xs={12} align='center'>
            <Typography color='secondary' component='p'>
                Al registrarte, aceptas nuestras 
                <Link to='/politicas'><b className={props.classes.bgColorText}> políticas de privacidad</b></Link> y 
                <Link to='/condiciones'><b className={props.classes.bgColorText}> condiciones de uso.</b></Link>
            </Typography>
        </Grid>
        <Grid item xs={12} align='center'>
            <Button 
                color='primary'
                className='btn-with-br mb-1' 
                onClick={props.handleOnSubmit}
                disabled={props.newMedico.loading}
            >
                Confirmar
                {props.newMedico.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
            </Button>
        </Grid>

        {/* <Grid item xs={12} align='center'>
            <Divider/>
        </Grid> */}
        
    </Grid>
)

export default InputForm