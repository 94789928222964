import React from 'react'

/* Components */
// import MenuSeguidorContacto from "./menuSeguidorContacto";

/* MAterial UI */
import { Grid, Paper, Link, Typography } from '@material-ui/core';

const MasPublicaciones = (props) => (
    <Grid item xs={12} align='center' className="cont-list-post">
        <Link href={`https://portalpaciente.ecardioline.com/epage/${props.nickname}`}>
            <Paper className="paper post">
                <Typography color='primary'>Ver más publicaciones</Typography>

            </Paper>
        </Link>
    </Grid>
)


export default MasPublicaciones;